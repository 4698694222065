import { BlockStack, Button, InlineStack, Spinner } from "@shopify/polaris";
import { useEffect, useState } from "react";
import CarouselForm from "./carouselComponents/CarouselForm";
import CarouselClipSelector from "./carouselComponents/CarouselClipSelector";
import CarouselClipModal from "./carouselComponents/CarouselClipModal";
import { analyticsEventStore, popclipsStore, shopStore } from '@/stores';
import CarouselPublishModal from "./carouselComponents/CarouselPublishModal";
import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";
import { popclipsRoutes } from "@/constants/routes.constants";
import { BaseLayout } from "../../v2/pages/Layout/BaseLayout";

export default function CreateCustomCarousels({ editMode }: { editMode?: boolean }) {
    const navigate = useNavigate();
    const {carouselToEdit, clipsForEditCarousel} = useSnapshot(popclipsStore);
    
    const [title, setTitle] = useState('');
    const [tempTitle, setTempTitle] = useState('');
    const [selectedClips, setSelectedClips] = useState<string[]>([]);
    const [clipsObjsArr, setClipsObjsArr] = useState<any[]>([]);
    const [carouselType, setCarouselType] = useState<string>('custom')
    const [collectionID, setCollectionID] = useState<string[]>([])

    const [carouselId, setCarouselId] = useState('');
    const [isPublished, setIsPublished] = useState(false);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openPublishModal, setOpenPublishModal] = useState(false);

    useEffect(() => {
        if (editMode) {
            if ((carouselToEdit && clipsForEditCarousel.length > 0) || (carouselToEdit?.carousel_type == 'collection')) {
                setCarouselId(popclipsStore.carouselToEdit.carousel_id);
                setTitle(popclipsStore.carouselToEdit.carousel_title);
                setTempTitle(popclipsStore.carouselToEdit.carousel_title);
                setSelectedClips(popclipsStore.carouselToEdit.carousel_type_ids);
                setClipsObjsArr(popclipsStore.clipsForEditCarousel);
                setIsPublished(popclipsStore.carouselToEdit.is_published);
                setCarouselType(popclipsStore.carouselToEdit.carousel_type)
                if(carouselToEdit.carousel_type == 'collection'){
                    setCollectionID(popclipsStore.carouselToEdit.carousel_type_ids)
                }
            } else {
                navigate(popclipsRoutes.manageCarousel);
            }
        }
    }, [editMode, carouselToEdit, clipsForEditCarousel]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => e.preventDefault();
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const onHandleSelectClip = (clipsData: any) => {
        setClipsObjsArr(clipsData);
        setSelectedClips(
            clipsData.map(({ metaIdentifier }: any) => metaIdentifier),
        );
    };

    const handleCarouselTypeSelect = (type: string) => {
        setCarouselType(type);
    }

    const handleCollectionSelect = (IDs: []) => {
        setCollectionID(IDs)
    }

    const handleSaveAndPublish = async () => {
        const toastOptions = { duration: 2000, isError: true };

        if (title.trim() === '') {
            shopify.toast.show('Please enter a title for the carousel', toastOptions);
            return;
        }

        if (carouselType == 'custom' && selectedClips.length < 3) {
            shopify.toast.show('Please select at least 3 Popclips to create a carousel', toastOptions);
            return;
        }

        if (carouselType == 'collection' && collectionID.length < 1) {
            shopify.toast.show('Please select collection to create a carousel', toastOptions);
            return;
        }

        try {
            setIsApiLoading(true);
            const carouselName = tempTitle === title ? undefined : title;

            if(editMode){
                const { carousel_id } = await popclipsStore.saveCustomCarousel({
                    carousel_id: editMode ? carouselId : undefined,
                    customer_id: shopStore.shopifyDomain,
                    carousel_title: editMode ? carouselName : title,
                    carousel_type_ids: carouselType == 'custom' ? selectedClips : collectionID,
                    carousel_type: carouselType,
                }, editMode != undefined);
                setCarouselId(carousel_id);
            } else {
                const startTime = new Date().getTime();

                const { carousel_id } = await popclipsStore.saveCustomCarousel({
                    customer_id: shopStore.shopifyDomain,
                    carousel_title: editMode ? carouselName : title,
                    carousel_type_ids: carouselType == 'custom' ? selectedClips : collectionID,
                    carousel_type: carouselType,
                }, editMode !== undefined);

                const endTime = new Date().getTime();
                const timeTaken = endTime - startTime;
                setCarouselId(carousel_id);
                analyticsEventStore.popclipCreateCustomCarouselTriggered({
                    duration: Math.floor(timeTaken / 1000),
                    carouselId: carousel_id,
                });
            }

            if (isPublished) {
                shopify.toast.show('Custom carousel changes saved', {
                    duration: 2000,
                    isError: false,
                });
                navigate(popclipsRoutes.manageCarousel);
            } else {
                setOpenPublishModal(true);
            }
        } catch (error) {
            shopify.toast.show(error.message, {
                duration: 2000,
                isError: true,
            });
            console.error(error);
            return;
        } finally {
            setIsApiLoading(false);
        }
    };

    return (
        <BaseLayout
            title={editMode ? 'Edit Custom Carousel' : 'Create New Custom Carousel'}
            headerAction={
                <InlineStack gap="300" align="center" blockAlign="center">
                    {isApiLoading && <Spinner size="small" />}
                    <Button variant="primary" submit onClick={handleSaveAndPublish} disabled={isApiLoading}>
                        {isPublished ? 'Save' : 'Save & Publish'}
                    </Button>
                </InlineStack>
            }
            backToLink={popclipsRoutes.list}
        >
            <BlockStack gap="400">
                <CarouselForm
                    carouselId={carouselId}
                    title={title}
                    handleTitle={(value: string) => setTitle(value)}
                    isApiLoading={isApiLoading}
                />
                <CarouselClipSelector
                    toggleOpenModal={() => setOpenModal(true)}
                    selectedClipData={clipsObjsArr}
                    isApiLoading={isApiLoading}
                    handleCarouselTypeSelect={handleCarouselTypeSelect}
                    carouselType={carouselType}
                    handleCollectionSelect={handleCollectionSelect}
                    collectionID={collectionID}
                    isEditMode={editMode}
                />
                <CarouselClipModal
                    openModal={openModal}
                    toggleOpenModal={() => setOpenModal(false)}
                    selectedClips={selectedClips}
                    handleSelectClip={onHandleSelectClip}
                    isEditMode={editMode}
                />
            </BlockStack>
            <CarouselPublishModal
                openPublishModal={openPublishModal}
                hidePublishModal={() => setOpenPublishModal(false)}
                carouselId={carouselId}
            />
        </BaseLayout>
    );
}

