import DashboardHome from '@/components/dashboard/DashboardHome';
import { Frame, Loading } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { BaseLayout } from '../v2/pages/Layout/BaseLayout';

const InsightsPage = () => {
    // const [embedDashboardUrl, setEmbedDashboardUrl] = useState<string | null>(
    //     null,
    // );
    // const [isLoading, setIsLoading] = useState(false);
    // const fetchEmbedInformation = async () => {
    //     setIsLoading(true);
    //     try {
    //         const dashboardId = 737;
    //         const subdomain = 'data';
    //         const response = await fetch(
    //             `/api/shopify/shop/cs-reports?dashboardId=${dashboardId}&subdomain=${subdomain}`,
    //             {
    //                 method: 'GET',
    //             },
    //         );

    //         const { url } = await response.json();

    //         if (url) {
    //             setEmbedDashboardUrl(url);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     setIsLoading(false);
    // };

    // useEffect(() => {
    //     fetchEmbedInformation();
    // }, []);

    return (
        <BaseLayout
            title="Analytics"
            backToLink="/"
        >
            <DashboardHome />
            {/* {embedDashboardUrl && !isLoading ? (
                <iframe
                    src={embedDashboardUrl}
                    style={{ width: '100%', height: '98vh' }}
                ></iframe>
            ) : isLoading ? (
                <div style={{ height: '100px' }}>
                    <Frame>
                        <Loading />
                    </Frame>
                </div>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    Data not found
                </div>
            )} */}
        </BaseLayout>
    );
};

export default InsightsPage;
