import { defaultMobileAppConfigStore, mobileAppConfigStore } from '@/stores';
import {
    BlockStack,
    Box,
    Card,
    Checkbox,
    Divider,
    Icon,
    InlineStack,
    SkeletonBodyText,
    Text,
    TextField,
    Tooltip,
} from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';

const InventoryCountDisplayCard = () => {
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    const [isDisplayInventoryCount, setIsDisplayInventoryCount] =
        useState(false);
    const [isError, setIsError] = useState('');
    const { showInventoryLimit } = mobileAppConfigSnap;

    const handleInventoryCountChange = useCallback((newValue: string) => {
        const count = parseInt(newValue);

        if (newValue === '') {
            setIsError('Please enter a valid input');
            mobileAppConfigStore.showInventoryLimit = count;
        } else if (newValue == '0' || count < 0) {
            setIsError('Please enter a valid input');
            mobileAppConfigStore.showInventoryLimit = NaN;
        } else if (count > 0 && count < 100) {
            mobileAppConfigStore.showInventoryLimit = count;
            setIsError('');
        } else {
            setIsError('');
        }
    }, []);

    const handleInventoryCountDisplayToggle = () => {
        if (isDisplayInventoryCount) {
            mobileAppConfigStore.showInventoryLimit = 0;
            setIsError('');
        } else {
            mobileAppConfigStore.showInventoryLimit = 10;
        }
        setIsDisplayInventoryCount(!isDisplayInventoryCount);
    };

    useEffect(() => {
        if (mobileAppConfigStore.showInventoryLimit > 0) {
            setIsDisplayInventoryCount(true);
            setIsError('');
        }
    }, [mobileAppConfigStore.showInventoryLimit]);
    return (
        <Card padding="0">
            {mobileAppConfigSnap.isLoading ? (
                <SkeletonBodyText />
            ) : (
                <Box width="60rem" padding="0">
                    <Box
                        paddingInline="800"
                        paddingBlock="200"
                        background="bg-surface-secondary-active"
                    >
                        <InlineStack align="space-between" blockAlign="center">
                            <Text as="p" variant="bodyMd">
                                {isDisplayInventoryCount
                                    ? 'Manage this setting to limit the display of inventory count on product description page.'
                                    : 'Enable this setting to show limited inventory count on product description page'}
                            </Text>
                            <Checkbox
                                label=""
                                checked={isDisplayInventoryCount}
                                onChange={handleInventoryCountDisplayToggle}
                            />
                        </InlineStack>
                    </Box>
                    <Box paddingInline="800" paddingBlock="400">
                        <BlockStack gap="200">
                            <Text as="h3" variant="headingSm">
                                Inventory Display
                            </Text>
                            <InlineStack
                                align="space-between"
                                blockAlign="center"
                            >
                                <Text as="p" tone="subdued">
                                    Set the maximum limit to display for
                                    inventory count.
                                </Text>
                                <Box paddingInlineEnd="150">
                                    <Tooltip
                                        content={
                                            <InlineStack gap="200">
                                                <Text
                                                    as="span"
                                                    variant="bodyMd"
                                                    tone="subdued"
                                                >
                                                    The actual inventory count
                                                    will be hidden if it exceeds
                                                    the set limit.
                                                </Text>
                                            </InlineStack>
                                        }
                                    >
                                        <Icon source={InfoIcon} tone="info" />
                                    </Tooltip>
                                </Box>
                            </InlineStack>
                            {isDisplayInventoryCount ? <Divider /> : null}
                            {isDisplayInventoryCount ? (
                                <TextField
                                    label="Quantity"
                                    type="number"
                                    value={showInventoryLimit + ''}
                                    onChange={handleInventoryCountChange}
                                    autoComplete="off"
                                    error={isError}
                                />
                            ) : null}
                        </BlockStack>
                    </Box>
                </Box>
            )}
        </Card>
    );
};

export default InventoryCountDisplayCard;
