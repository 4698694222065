import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { buildMobileAppStore, shopStore } from '@/stores';
import { vfsRoutes } from '@/constants/routes.constants';
import AccessDeniedBanner from '../../v2/Components/Banners/AccessDeniedBanner';

type ComponentProps = { children: React.ReactNode };

const RequireNotPublishedMobileApp = ({ children }: ComponentProps) => {
    const { isAppRequestSubmitted, iosLink, androidLink } = useSnapshot(buildMobileAppStore);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const redirectTo = vfsRoutes.landing;

    useEffect(() => {
        if (
            isAppRequestSubmitted && (iosLink || androidLink) &&
            pathname !== redirectTo
        ) {
            navigate(redirectTo);
            return;
        }
    }, [iosLink, androidLink, isAppRequestSubmitted]);

    if (shopStore.enabledFeatures.includes('mobile')) {
        return <>{children}</>;
    } else {
        return <AccessDeniedBanner />;
    }
};

export default RequireNotPublishedMobileApp;
