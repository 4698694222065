import { useEffect, useState } from "react";
import { BlockStack, Box, Divider, Icon, InlineStack, Link, Text } from "@shopify/polaris";
import WizardStep1 from "../Components/OnboardingWizard/WizardStep1";
import WizardStep2 from "../Components/OnboardingWizard/WizardStep2";
import WizardStep3 from "../Components/OnboardingWizard/WizardStep3";
import WizardStep4 from "../Components/OnboardingWizard/WizardStep4";
import { useSnapshot } from "valtio";
import { shopStore } from "@/stores";
import { AlertCircleIcon, ArrowRightIcon } from "@shopify/polaris-icons";
import { useStorefrontGraphQL } from "@/hooks/useStorefrontGraphQL";
import '../../assets/css/GetStartedStepsPage.css';
import OnboardingWizardHeader from "../Components/OnboardingWizard/OnboardingWizardHeader";
import { useNavigate } from "react-router-dom";
import { vfsRoutes } from "@/constants/routes.constants";
import { isDateBeforeToday } from "@/utils/helpers";

export default function GetStartedStepsPage() {
    const navigate = useNavigate();
    const { getStartedStatus, shopifyDomain, subscription } = useSnapshot(shopStore);
    const [stepExpanded, setStepExpanded] = useState(0);
    const [tooltipExpanded, setTooltipExpanded] = useState(0);
    const { makeQuery } = useStorefrontGraphQL();

    useEffect(() => {
        (async () => {
            const data = await makeQuery({
                query: `
                    query facets($query: String!, $first: Int) {
                        search(query: $query, first: $first) {
                            productFilters {
                                id
                                label
                                type
                                values {
                                    id
                                    label
                                    count
                                    input
                                }
                            }
                        }
                    }
                `,
                variables: {
                    first: 1,
                    query: '',
                },
            });

            const productFilters = data?.search?.productFilters;
            const totalCount = !productFilters ? 0 : productFilters.length;

            if (totalCount > 0 && !getStartedStatus.events.STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC) {
                await shopStore.updateGetStartedStatus('STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC');
            }
        })();
    }, [shopifyDomain]);

    useEffect(() => {
        if (!getStartedStatus.events.STEP_ENABLE_VIDEEO_SDK) {
            setStepExpanded(1);
        } else if (!getStartedStatus.events.STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC) {
            setStepExpanded(2);
        } else if (!getStartedStatus.events.STEP_ADD_YOUR_FIRST_POPCLIP) {
            if (isDateBeforeToday(new Date(subscription?.createdAt || ''))) {
                navigate(vfsRoutes.landing);
            } else {
                setStepExpanded(3);
            }
        } else if (!getStartedStatus.events.STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL) {
            navigate(vfsRoutes.landing);
        }
    }, [getStartedStatus]);
    
    return (
        <div className="onboarding-wizard-page-body">
            <div style={{ minHeight: '38px', width: '100%' }}>
                <h1 className="page-heading">👋 Welcome to Videeo!</h1>
                <Box paddingBlockStart="100">
                    <InlineStack gap="200">
                        <Text as="p" variant="bodyMd">
                            Questions?
                        </Text>
                        <div className="schedule-free-onboarding-call">
                            <Link
                                url="https://calendly.com/videeo/initial-videeo-onboarding"
                                removeUnderline
                                target="_blank"
                            >
                                <InlineStack gap="100" blockAlign="center">
                                    <Text
                                        as="span"
                                        fontWeight="semibold"
                                        tone="magic"
                                    >
                                        Schedule a free call
                                    </Text>
                                    <Icon
                                        source={ArrowRightIcon}
                                        tone="magic"
                                    />
                                </InlineStack>
                            </Link>
                        </div>
                    </InlineStack>
                </Box>
            </div>
            <div className="onboarding-wizard-modal">
                <BlockStack gap="400">
                    <OnboardingWizardHeader
                        completionPercentage={
                            getStartedStatus.completionPercentage
                        }
                    />
                    <Divider />
                    <BlockStack gap="600">
                        <div
                            className={`${
                                getStartedStatus.events
                                    .STEP_ENABLE_VIDEEO_SDK &&
                                'onboarding-wizard-modal__step-completed'
                            }`}
                        >
                            <WizardStep1
                                stepExpanded={stepExpanded === 1}
                                isCompleted={
                                    getStartedStatus.events
                                        .STEP_ENABLE_VIDEEO_SDK
                                }
                            />
                        </div>
                        <div
                            className={`
                                ${
                                    getStartedStatus.events
                                        .STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC &&
                                    'onboarding-wizard-modal__step-completed'
                                }
                                ${
                                    stepExpanded < 2 &&
                                    'onboarding-wizard-modal__step-disabled'
                                }
                            `}
                            style={{ position: 'relative' }}
                            onMouseEnter={() => setTooltipExpanded(2)}
                            onMouseLeave={() => setTooltipExpanded(0)}
                        >
                            <WizardStep2
                                stepExpanded={stepExpanded === 2}
                                isCompleted={
                                    getStartedStatus.events
                                        .STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC
                                }
                            />
                            {!getStartedStatus.events.STEP_ENABLE_VIDEEO_SDK &&
                                tooltipExpanded === 2 && (
                                    <div className="onboarding-wizard-modal__tooltip">
                                        <InlineStack
                                            blockAlign="start"
                                            gap="200"
                                        >
                                            <div>
                                                <Icon
                                                    source={AlertCircleIcon}
                                                />
                                            </div>
                                            <BlockStack gap="100">
                                                <p className="onboarding-wizard-modal__tooltip-title">
                                                    Complete previous steps
                                                </p>
                                                <p className="onboarding-wizard-modal__tooltip-text">
                                                    Please complete the previous
                                                    steps to access this one
                                                </p>
                                            </BlockStack>
                                        </InlineStack>
                                    </div>
                                )}
                        </div>
                        <div
                            className={`
                                ${
                                    getStartedStatus.events
                                        .STEP_ADD_YOUR_FIRST_POPCLIP &&
                                    'onboarding-wizard-modal__step-completed'
                                }
                                ${
                                    stepExpanded < 3 &&
                                    'onboarding-wizard-modal__step-disabled'
                                }
                            `}
                            style={{ position: 'relative' }}
                            onMouseEnter={() => setTooltipExpanded(3)}
                            onMouseLeave={() => setTooltipExpanded(0)}
                        >
                            <WizardStep3
                                stepExpanded={stepExpanded === 3}
                                isCompleted={
                                    getStartedStatus.events
                                        .STEP_ADD_YOUR_FIRST_POPCLIP
                                }
                            />
                            {!getStartedStatus.events
                                .STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC &&
                                tooltipExpanded === 3 && (
                                    <div className="onboarding-wizard-modal__tooltip">
                                        <InlineStack
                                            blockAlign="start"
                                            gap="200"
                                        >
                                            <div>
                                                <Icon
                                                    source={AlertCircleIcon}
                                                />
                                            </div>
                                            <BlockStack gap="100">
                                                <p className="onboarding-wizard-modal__tooltip-title">
                                                    Complete previous steps
                                                </p>
                                                <p className="onboarding-wizard-modal__tooltip-text">
                                                    Please complete the previous
                                                    steps to access this one
                                                </p>
                                            </BlockStack>
                                        </InlineStack>
                                    </div>
                                )}
                        </div>
                        <div
                            className={`
                                ${
                                    getStartedStatus.events
                                        .STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL &&
                                    'onboarding-wizard-modal__step-completed'
                                }
                                ${
                                    stepExpanded < 4 &&
                                    'onboarding-wizard-modal__step-disabled'
                                }
                            `}
                            style={{ position: 'relative' }}
                            onMouseEnter={() => setTooltipExpanded(4)}
                            onMouseLeave={() => setTooltipExpanded(0)}
                        >
                            <WizardStep4
                                stepExpanded={stepExpanded === 4}
                                isCompleted={
                                    getStartedStatus.events
                                        .STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL
                                }
                            />
                            {!getStartedStatus.events
                                .STEP_ADD_YOUR_FIRST_POPCLIP &&
                                tooltipExpanded === 4 && (
                                    <div className="onboarding-wizard-modal__tooltip">
                                        <InlineStack
                                            blockAlign="start"
                                            gap="200"
                                        >
                                            <div>
                                                <Icon
                                                    source={AlertCircleIcon}
                                                />
                                            </div>
                                            <BlockStack gap="100">
                                                <p className="onboarding-wizard-modal__tooltip-title">
                                                    Complete previous steps
                                                </p>
                                                <p className="onboarding-wizard-modal__tooltip-text">
                                                    Please complete the previous
                                                    steps to access this one
                                                </p>
                                            </BlockStack>
                                        </InlineStack>
                                    </div>
                                )}
                        </div>
                    </BlockStack>
                </BlockStack>
            </div>
            <p className="vertical-watermark">Videeo</p>
        </div>
    );
}