import { BlockStack, Box, Button, Spinner, Text } from "@shopify/polaris";
import { CheckIcon, PlusIcon } from "@shopify/polaris-icons";
import { MouseEvent, useState } from "react";

type CarouselClipProps = {
    clipData: any;
    selectedClips?: any;
    handleRemoveClip?: (metaIdentifier: string) => void;
    handleSelectClip?: (clipData: any) => void;
    hideSelectBtn?: boolean;
};

export default function CarouselClip({
    clipData,
    selectedClips,
    handleRemoveClip,
    handleSelectClip,
    hideSelectBtn,
}: CarouselClipProps) {
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    
    const handleVideoPlayOnHover = (e: MouseEvent) => {
        const elm = e.target as HTMLVideoElement;
        elm.play();
    };

    const handleVideoResetOnOut = (e: MouseEvent) => {
        const elm = e.target as HTMLVideoElement;
        elm.pause();
        elm.currentTime = 0;
    };

    return (
        <Box
            background="bg-fill"
            borderRadius="200"
            overflowX="hidden"
            overflowY="hidden"
            borderColor="border"
            borderWidth="025"
            shadow="100"
        >
            {clipData.status !== 'processed' ? (
                <VideoLoader text="Processing..." />
            ) : (
                <>
                    {isVideoLoading && <VideoLoader text="Loading..." />}
                    <video
                        width="149"
                        height="242"
                        muted
                        style={{
                            objectFit: 'cover',
                            cursor: 'pointer',
                            width: !hideSelectBtn ? '149px' : '100%',
                            display: isVideoLoading ? 'none' : 'block',
                        }}
                        onMouseOver={handleVideoPlayOnHover}
                        onMouseOut={handleVideoResetOnOut}
                        onLoadedData={() => setIsVideoLoading(false)}
                    >
                        <source
                            src={`${clipData.cdnUrl}${clipData.metaData.processedURL}`}
                            type="video/mp4"
                        />
                        Your browser does not support the video
                    </video>
                </>
            )}
            <Box
                paddingInline="300"
                paddingBlockEnd={!hideSelectBtn ? '300' : '0'}
            >
                <BlockStack align="start" inlineAlign="start" gap="300">
                    <Box width="128px">
                        <Text as="p" truncate>
                            {clipData.metaData.videoTitle}
                        </Text>
                    </Box>
                    {!hideSelectBtn && (
                        <>
                            {selectedClips.includes(clipData.metaIdentifier) ? (
                                <Button
                                    onClick={() =>
                                        handleRemoveClip &&
                                        handleRemoveClip(
                                            clipData.metaIdentifier,
                                        )
                                    }
                                    variant="primary"
                                    tone="success"
                                    icon={CheckIcon}
                                >
                                    Selected
                                </Button>
                            ) : (
                                <Button
                                    onClick={() =>
                                        handleSelectClip && handleSelectClip(clipData)
                                    }
                                    icon={PlusIcon}
                                >
                                    Select
                                </Button>
                            )}
                        </>
                    )}
                </BlockStack>
            </Box>
        </Box>
    );
};

const VideoLoader = ({ text }: { text: string }) => (
    <Box minHeight="242px" minWidth="149px">
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '248px',
                gap: '4px',
                flexDirection: 'column',
            }}
        >
            <Spinner size="small" />
            <Text as="p">{text}</Text>
        </div>
    </Box>
);