import {
    shopCollectionStore,
} from '@/stores';
import { useCallback } from 'react';
import shopStore from '@/stores/shopStore';
const SHOPIFY_API_VERSION = '2024-01';

type CollectionProductResponse = {
    data: {
        collection: {
            title: string;
            handle: string;
            id: string;
            products: {
                edges: {
                    node: {
                        images: {
                            nodes: {
                                url: string;
                            }[];
                        };
                        featuredImage: {
                            url: string;
                        };
                    };
                }[];
                pageInfo: {
                    hasNextPage: boolean;
                };
            };
        };
    };
};

export const useGetCollectionProducts = () => {
    const getCollectionProducts = useCallback(
        async (id: string) => {
            const { shopifyDomain } = shopStore;
            if (!shopifyDomain) {
                console.error('no shopify domain found');
                return;
            }
            const body = {
                query: `
                    query getCollectionById($id: ID!) {
                        collection(id: $id) {
                            title
                            handle
                            id
                            products(first:100) {
                                edges{
                                    __typename
                                    node {
                                        images(first: 1) {
                                            nodes {
                                                url
                                            }
                                        }
                                        featuredImage {
                                                url
                                            }
                                        }
                                    }
                                    pageInfo {
                                        hasNextPage
                                    }
                                }
                            }
                        }
                `,
                variables: {
                    id: id,
                },
            };
            try {
                shopCollectionStore.areCollectionProductsLoading = true;
                const res = await fetch(
                    `https://${shopStore.shopifyDomain}/api/${SHOPIFY_API_VERSION}/graphql.json`,
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'X-Shopify-Storefront-Access-Token':
                            shopStore.storefrontAccessToken,
                        },
                        body: JSON.stringify(body),
                    },
                );
                if (res.ok) {
                    const { data } =
                        (await res.json()) as CollectionProductResponse;
                    const urls = [];
                    const productCount = data.collection.products.edges.length;
                    for (let i = 0; i < Math.min(productCount, 5); i++) {
                        const node = data.collection.products.edges[i].node;
                        if (node.featuredImage) {
                            urls.push(node.featuredImage.url);
                        } else if (node.images.nodes.length > 0) {
                            urls.push(node.images.nodes[0].url);
                        }
                    }
                    const { hasNextPage } = data.collection.products.pageInfo;
                    shopCollectionStore.setProductCollectionUrls(urls);
                    shopCollectionStore.setProductCollectionCount(
                        hasNextPage ? `${productCount}+` : `${productCount}`,
                    );
                    return data;
                }
                throw new Error('Failed to get collections');
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            } finally {
                shopCollectionStore.areCollectionProductsLoading = false;
            }
        },
        [shopStore.shopifyDomain],
    );
    return { getCollectionProducts };
};
