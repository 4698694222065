import { BlockStack, Box, Card, InlineStack, Spinner, Text } from '@shopify/polaris';
import Plot from 'react-plotly.js';
import DashboardHeaderSection from './DashboardHeaderSection';
import DashboardIndicatorTag from './DashboardIndicatorTag';
import { CONFIG, LAYOUT, LINE_SMOOTHING_VALUE } from './chartConstantConfig';
import { useState } from 'react';

interface DashboardTotalOrdersChartPropsTypes {
    title: string;
    value: number;
    chartData: number[];
    labels: string[];
    isLoading: boolean;
    growthInValue: number,
}

const DashboardTotalOrdersChart = ({
    title,
    value,
    chartData,
    labels,
    isLoading,
    growthInValue,
}: DashboardTotalOrdersChartPropsTypes) => {
    const [isExpand, setIsExpand] = useState(false);

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    const DrawTotalOrderChart = () => {
        return (
            <Plot
                data={[
                    // Shadow area
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'none',
                        fill: 'tozeroy', // Fills the area to the x-axis
                        fillcolor: 'rgba(243, 126, 24, 0.1)', // Transparent shadow color
                        hoverinfo: 'skip', // Disables hover for shadow area
                    },
                    // Line
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'lines',
                        line: {
                            color: '#F37E18',
                            width: 3, // Adjust line thickness
                            shape: 'spline', // Makes the line curved
                            smoothing: LINE_SMOOTHING_VALUE, // Controls the smoothness of the curve
                        },
                        name: "Order"
                    },
                ]}
                layout={{
                    ...LAYOUT,
                    width: isExpand ? '' : 400,
                    autosize: isExpand,
                    xaxis: {
                        visible: true,
                        showgrid: false,
                    },
                    yaxis: {
                        visible: false,
                        showgrid: true,
                    },
                }}
                config={CONFIG}
            />
        );
    };

    return (
        <div>
            <Box
                        borderWidth="025"
                        borderColor="border"
                        borderRadius="500"
                        padding="600"
                        background="bg-fill"
                        shadow="200"
                    >
                <DashboardHeaderSection
                    title={title}
                    showResizeButton={true}
                    isExpand={isExpand}
                    handleResizeChart={handleResizeChart}
                />
                <InlineStack blockAlign="end" wrap={false}>
                    <BlockStack gap="400">
                        <Text variant="heading3xl" as="h2">
                            {value}
                        </Text>
                        <DashboardIndicatorTag growthInValue={growthInValue} isMonth={false} />
                    </BlockStack>
                    {isLoading ? <Spinner /> : <DrawTotalOrderChart />}
                </InlineStack>
            </Box>
            {/* Expand view code */}
            {isExpand && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 'auto',
                        right: 'auto',
                        background: 'rgba(0,0,0,0.8)',
                        width: '100%',
                        height: '100%',
                        zIndex: '2147483647',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        borderWidth="025"
                        borderColor="border"
                        borderRadius="500"
                        padding="600"
                        background="bg-fill"
                        shadow="200"
                    >
                        <DashboardHeaderSection
                            title={title}
                            showResizeButton={true}
                            isExpand={isExpand}
                            handleResizeChart={handleResizeChart}
                        />
                        <InlineStack blockAlign="end" wrap={false}>
                            <BlockStack gap="400">
                                <Text variant="heading3xl" as="h2">
                                    {value}
                                </Text>
                                <DashboardIndicatorTag growthInValue={growthInValue} isMonth={false} />
                            </BlockStack>
                        </InlineStack>
                        {isLoading ? (
                            <InlineStack align="center" blockAlign="center">
                                <Spinner />
                            </InlineStack>
                        ) : (
                            <DrawTotalOrderChart />
                        )}
                    </Box>
                </div>
            )}
        </div>
    );
};

export default DashboardTotalOrdersChart;
