import { Box, Icon, InlineStack, Text } from '@shopify/polaris';
import { ArrowDownIcon, ArrowUpIcon } from '@shopify/polaris-icons';

const DashboardIndicatorTag = ({
    growthInValue,
    isMonth,
}: {
    growthInValue: number;
    isMonth: boolean;
}) => {
    const isIncreased = () => {
        return growthInValue > 0;
    };

    return (
        <Box paddingBlock="100">
        <InlineStack blockAlign="center" gap="050" wrap={false}>
            <Box
                padding="100"
                borderRadius="full"
                borderWidth="025"
                shadow="200"
                color={
                    isIncreased()
                        ? 'text-success'
                        : growthInValue == 0
                          ? 'text-brand'
                          : 'text-critical'
                }
                background={
                    isIncreased()
                        ? 'bg-fill-success-secondary'
                        : growthInValue == 0
                          ? 'bg-fill-active'
                          : 'bg-fill-critical-secondary'
                }
            >
                <InlineStack blockAlign='center' align='center' wrap={false}>
                    {growthInValue != 0 && (
                        <Box>
                            {isIncreased() ? (
                                <Icon source={ArrowUpIcon} />
                            ) : (
                                <Icon source={ArrowDownIcon} />
                            )}
                        </Box>
                    )}
                    <div style={{ width: 'max-content' }}>
                        <Text as="p" variant="bodySm">
                            {isIncreased() ? growthInValue : growthInValue * -1}{' '}
                            %
                        </Text>
                    </div>
                </InlineStack>
            </Box>
            <Box paddingInline="200">
                <Text as="p" variant="bodyLg" tone="subdued" breakWord={false}>
                vs last {isMonth ? 'month' : 'week'}
                </Text>
            </Box>
        </InlineStack>
        </Box>
    );
};

export default DashboardIndicatorTag;
