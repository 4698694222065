import { proxy } from 'valtio';
type Collection = {
    label: string;
    value: string;
};
const shopCollectionStore = proxy({
    areCollectionsLoading: false,
    collections: [] as Collection[],
    addCollection: ({ label, value }: Collection) => {
        const collectionPresent = shopCollectionStore.collections.some(
            (collection) => collection.value === value,
        );
        if (!collectionPresent) {
            shopCollectionStore.collections.push({
                label,
                value,
            });
        }
    },
    selectedCollection: null as string | null,
    setSelectedCollection: (collection: string) => {
        shopCollectionStore.selectedCollection = collection;
    },
    areCollectionProductsLoading: false,
    productCollectionCount: '',
    setProductCollectionCount: (count: string) => {
        shopCollectionStore.productCollectionCount = count;
    },
    productCollectionUrls: [] as string[],
    setProductCollectionUrls: (urls: string[]) => {
        shopCollectionStore.productCollectionUrls = urls;
    },
});

export default shopCollectionStore;
