import {
    BlockStack,
    Box,
    Button,
    Card,
    Grid,
    IndexTable,
    InlineStack,
    Pagination,
    SkeletonBodyText,
    Text,
    useIndexResourceState,
} from '@shopify/polaris';
import { Modal, TitleBar } from '@shopify/app-bridge-react';
import DashboardHeaderSection from './DashboardHeaderSection';
import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { shopStore } from '@/stores';
import { CarouselStaticsTypes, NamingObjectTypes } from './dashboardTypes';
import VfsModal from '../../v2/Components/VfsModal';
import {
    formatTimeDateYearInLocalTime,
    formatTimeToDateInLocalTime,
} from '@/utils/formatDate';

const modalContetToDisplay = [
    'carousel_id',
    'total_clicks',
    'total_orders',
    'total_sales',
    'total_add_to_carts',
    'created_at',
    'shop_id',
    'total_views',
];

const spanGap = '100';
const rowSpanStyle = { fontSize: '14px', padding: '8px' };

const DashboardCarouselStatsTable = ({
    namingObject,
}: {
    namingObject: NamingObjectTypes;
}) => {
    const [isModalOpen, setIsOpenModal] = useState(false);
    const [carouselDetailsToDisplay, setCarouselDetailsToDisplay] =
        useState<CarouselStaticsTypes | null>(null);
    const [carouselStatData, setCarouselStatData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isExpand, setIsExpand] = useState(false);
    const [pagination, setPagination] = useState({
        items: [],
        currentCursor: null, // Cursor for the current page
        nextCursor: null, // Cursor for the next page
        hasNextPage: false,
        hasPreviousPage: false,
        limit: 5, // Number of items per page
    });

    const [cursorHistory, setCursorHistory] = useState([]); // Track previous cursors
    const { shopifyDomain, popclipsServiceApi } = useSnapshot(shopStore);

    const handleOpenPopclipDetails = (details: CarouselStaticsTypes) => {
        setCarouselDetailsToDisplay(details);
        setIsOpenModal(true);
    };

    const ClosePoplipDetailModal = () => {
        setIsOpenModal(false);
        setCarouselDetailsToDisplay(null);
    };

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    const handleNextPage = () => {
        if (pagination.hasNextPage) {
            setCursorHistory([...cursorHistory, pagination?.currentCursor]); // Save current cursor
            fetchCarouselStatsData(pagination.nextCursor); // Fetch next page
        }
    };

    const handlePreviousPage = () => {
        if (cursorHistory.length > 0) {
            const previousCursor = cursorHistory[cursorHistory.length - 1];
            setCursorHistory(cursorHistory.slice(0, -1)); // Remove last cursor
            fetchCarouselStatsData(previousCursor); // Fetch previous page
        }
    };

    const fetchCarouselStatsData = async (cursor = null) => {
        try {
            setIsLoading(true);
            const resp = await fetch(
                `${popclipsServiceApi}/dashboard/carousels-stats?shop_id=mainstream-applevalley.myshopify.com${
                    cursor ? '&cursor=' + cursor : ''
                }`,
                {
                    method: 'GET',
                },
            );
            const { data } = await resp.json();
            const { items } = data;
            console.log(data);

            setCarouselStatData(items);

            // Update state with new data and next cursor
            if (data?.cursor) {
                setPagination((prevState) => ({
                    ...prevState,
                    items: data.items, // Update items
                    currentCursor: cursor, // Set current cursor
                    nextCursor: data.cursor, // Set next cursor from response
                    hasNextPage: data.hasNextPage,
                    hasPreviousPage: data.hasPreviousPage,
                }));
            } else {
                setPagination((prevState) => ({
                    ...prevState,
                    items: data.items, // Update items
                    hasNextPage: data.hasNextPage,
                    hasPreviousPage: data.hasPreviousPage,
                }));
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (popclipsServiceApi) {
            fetchCarouselStatsData();
        }
    }, []);

    const resourceName = {
        singular: 'data',
        plural: 'data',
    };

    const { selectedResources } = useIndexResourceState(carouselStatData);

    const rowMarkup =
        carouselStatData &&
        carouselStatData.map((item: CarouselStaticsTypes, index) => (
            <IndexTable.Row
                id={item.carousel_id}
                key={item.carousel_id}
                selected={selectedResources.includes(item.carousel_id)}
                position={index}
            >
                <IndexTable.Cell>
                    <Box padding={spanGap}>
                        <Text variant="bodyMd" fontWeight="bold" as="span">
                            <Button
                                variant="plain"
                                onClick={() => handleOpenPopclipDetails(item)}
                            >
                                {item.carousel_id}
                            </Button>
                        </Text>
                    </Box>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Box padding={spanGap}>{item.total_clicks}</Box>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Box padding={spanGap}>
                        {item.total_sales} {item?.currency || ''}
                    </Box>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Box padding={spanGap}>{item.total_orders}</Box>
                </IndexTable.Cell>
                {/* <IndexTable.Cell>
                    <Box padding={spanGap}>
                        <Button
                            variant="plain"
                            onClick={() => handleOpenPopclipDetails(item)}
                        >
                            View More
                        </Button>
                    </Box>
                </IndexTable.Cell> */}
            </IndexTable.Row>
        ));

    const rowMarkupExpandView =
        carouselStatData &&
        carouselStatData.map((item: CarouselStaticsTypes, index) => (
            <IndexTable.Row
                id={item.carousel_id}
                key={item.carousel_id}
                selected={selectedResources.includes(item.carousel_id)}
                position={index}
            >
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.carousel_id}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {item?.carousel_title || '-'}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.total_clicks}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {item.total_sales} {item?.currency || ''}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.total_orders}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.total_add_to_carts}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.total_views}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {formatTimeDateYearInLocalTime(item.created_at)}
                    </div>
                </IndexTable.Cell>
                {/* <IndexTable.Cell>
                    <Button
                        variant="plain"
                        onClick={() => handleOpenPopclipDetails(item)}
                    >
                        View More
                    </Button>
                </IndexTable.Cell> */}
            </IndexTable.Row>
        ));

    return (
        <div style={{ height: '100%' }}>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'grid',
                }}
            >
                <Card padding="600">
                    <Box paddingBlockEnd="400">
                        <DashboardHeaderSection
                            title="Carousel Stats"
                            showResizeButton={true}
                            isExpand={isExpand}
                            handleResizeChart={handleResizeChart}
                        />
                    </Box>
                    {isLoading && (
                        <Card>
                            <SkeletonBodyText />
                        </Card>
                    )}
                    {carouselStatData && !isLoading && (
                        <Box>
                            <IndexTable
                                resourceName={resourceName}
                                itemCount={carouselStatData?.length || 0}
                                headings={[
                                    { title: 'Popclips Name' },
                                    { title: 'Clicks' },
                                    { title: 'Total Sales' },
                                    { title: 'Total Orders' },
                                ]}
                                selectable={false}
                            >
                                {rowMarkup}
                            </IndexTable>
                            <Box paddingBlockStart="400">
                                <InlineStack align="center">
                                    <Pagination
                                        hasPrevious={
                                            pagination?.hasPreviousPage
                                        }
                                        onPrevious={handlePreviousPage}
                                        hasNext={pagination?.hasNextPage}
                                        onNext={handleNextPage}
                                    />
                                </InlineStack>
                            </Box>
                        </Box>
                    )}
                </Card>
            </div>
            {carouselStatData && isExpand && (
                <VfsModal
                    onCloseModal={handleResizeChart}
                    title="Carousel Stats"
                    modalWidth="90%"
                >
                    <Box width="100%">
                        <IndexTable
                            resourceName={resourceName}
                            itemCount={carouselStatData?.length || 0}
                            headings={[
                                { title: 'Carousel ID' },
                                { title: 'Carousel Title' },
                                { title: 'Clicks' },
                                { title: 'Total Sales' },
                                { title: 'Total Orders' },
                                { title: 'Total Add to Carts' },
                                { title: 'Total Views' },
                                { title: 'Created At' },
                            ]}
                            selectable={false}
                        >
                            {rowMarkupExpandView}
                        </IndexTable>
                        <Box paddingBlockStart="400">
                            <InlineStack align="center">
                                <Pagination
                                    hasPrevious={pagination?.hasPreviousPage}
                                    onPrevious={handlePreviousPage}
                                    hasNext={pagination?.hasNextPage}
                                    onNext={handleNextPage}
                                />
                            </InlineStack>
                        </Box>
                    </Box>
                </VfsModal>
            )}

            {isModalOpen && carouselDetailsToDisplay && (
                <VfsModal
                    onCloseModal={ClosePoplipDetailModal}
                    title={carouselDetailsToDisplay?.carousel_id}
                    modalWidth="30rem"
                >
                    <Box padding="400">
                        <Grid
                            gap={{
                                xs: '30px',
                                sm: '30px',
                                md: '20px',
                                lg: '30px',
                                xl: '30px',
                            }}
                        >
                            {modalContetToDisplay.map((key) => (
                                <Grid.Cell
                                    key={key}
                                    columnSpan={{
                                        xs: 6,
                                        sm: 3,
                                        md: 3,
                                        lg: 6,
                                        xl: 6,
                                    }}
                                >
                                    <BlockStack gap="200">
                                        <Text
                                            variant="bodyLg"
                                            fontWeight="semibold"
                                            as="p"
                                        >
                                            {namingObject[key] || key}
                                        </Text>
                                        {key == 'total_sales' ? (
                                            <Text variant="bodyLg" as="p">
                                                {carouselDetailsToDisplay?.total_sales ||
                                                    0}{' '}
                                                {carouselDetailsToDisplay?.currency ||
                                                    ''}
                                            </Text>
                                        ) : (
                                            <Text variant="bodyLg" as="p">
                                                {carouselDetailsToDisplay[key]}
                                            </Text>
                                        )}
                                    </BlockStack>
                                </Grid.Cell>
                            ))}
                        </Grid>
                    </Box>
                </VfsModal>
            )}
        </div>
    );
};

export default DashboardCarouselStatsTable;
