import { mobileAppConfigStore } from "@/stores";
import { BlockStack, Box, Icon, InlineStack, Text } from "@shopify/polaris";
import { AdjustIcon, CameraIcon, CaretDownIcon, CartIcon, ChartHistogramGrowthIcon, ChartStackedIcon, ClockIcon, HeartIcon, HomeFilledIcon, PackageIcon, PersonIcon, PlayIcon, SearchIcon } from "@shopify/polaris-icons";
import { useSnapshot } from "valtio";
import { dummyLogo, dummyProd1, dummyProd2, dummyProd3, dummyProd4, dummyProd5 } from "../../../assets/images";

type MobileHomePageProps = {
    isMarketingBanner?: boolean;
    isLiveBanner?: boolean;
    liveBannerColor?: string;
    marketingBannerColor?: string;
    uiColor?: string;
    fontColor?: string;
};

export default function MobileHomePage({
    isMarketingBanner,
    isLiveBanner,
    liveBannerColor,
    marketingBannerColor,
    uiColor,
    fontColor = 'white',
}: MobileHomePageProps) {
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore);

    return (
        <Box width="340px" borderWidth="025" borderRadius="100" padding="100">
            <BlockStack>
                <TopSystemBar />

                <Box paddingBlock="200">
                    <LogoBar />
                </Box>

                {isMarketingBanner && (
                    <div
                        style={{
                            background:
                                marketingBannerColor ||
                                mobileAppConfigSnap.bannerColor,
                            color: fontColor,
                            paddingBlock: '5px',
                        }}
                    >
                        <BlockStack align="center" inlineAlign="center">
                            <Text as="span">
                                Join us every Tuesday to shop live!
                            </Text>
                            <Text as="span">Marketing Test Banner</Text>
                        </BlockStack>
                    </div>
                )}

                {isLiveBanner && (
                    <div
                        style={{
                            background:
                                liveBannerColor ||
                                mobileAppConfigSnap.liveBannerColor,
                            color: fontColor,
                            paddingBlock: '5px',
                        }}
                    >
                        <BlockStack align="center" inlineAlign="center">
                            <Text as="span">Join us live now!</Text>
                            <Text as="span">Live Test Banner</Text>
                        </BlockStack>
                    </div>
                )}

                <Box paddingBlockStart="400" paddingBlockEnd="200">
                    <Box shadow="200">
                        <InlineStack gap="300" align="start" blockAlign="start">
                            <BlockStack align="center" inlineAlign="center">
                                <Text as="span" fontWeight="semibold">
                                    My Feed
                                </Text>
                                <div
                                    style={{
                                        width: '80px',
                                        height: '2px',
                                        marginTop: '10px',
                                        background:
                                            uiColor ||
                                            mobileAppConfigSnap.primaryColor,
                                    }}
                                />
                            </BlockStack>
                            <Text as="span" fontWeight="semibold">
                                Clothing
                            </Text>
                            <Text as="span" fontWeight="semibold">
                                Accessories
                            </Text>
                            <Text as="span" fontWeight="semibold">
                                Jwellery
                            </Text>
                        </InlineStack>
                    </Box>
                </Box>

                <BodyContent />

                <Box paddingBlockStart="100" zIndex="99">
                    <ButtomNav
                        color={uiColor || mobileAppConfigSnap.primaryColor}
                    />
                </Box>
            </BlockStack>
        </Box>
    );
}

const LogoBar = () => {
    return (
        <InlineStack align="space-between" blockAlign="center">
            <InlineStack gap="100">
                <Text as="span" variant="bodyXs">
                    US ($)
                </Text>
                <Icon source={CaretDownIcon} />
            </InlineStack>
            <img src={dummyLogo} alt="logo" style={{ height: '25px' }} />
            <InlineStack gap="100">
                <Icon source={SearchIcon} />
                <Icon source={CartIcon} />
            </InlineStack>
        </InlineStack>
    );
}

const TopSystemBar = () => {
    return (
        <InlineStack align="space-between" blockAlign="center">
            <InlineStack gap="100">
                <Icon source={ChartHistogramGrowthIcon} />
                <Text as="span">LTE</Text>
                <Icon source={CameraIcon} />
            </InlineStack>
            <Text as="span" fontWeight="semibold">
                10:32 PM
            </Text>
            <InlineStack gap="100">
                <Icon source={ClockIcon} />
                <Text as="span" variant="bodyXs">
                    22%
                </Text>
                <Icon source={ChartStackedIcon} />
            </InlineStack>
        </InlineStack>
    );
}

const BodyContent = () => {
    return (
        <Box overflowX="hidden">
            <BlockStack gap="400">
                <InlineStack gap="100" align="start" wrap={false}>
                    <img src={dummyProd1} alt="dummyProd1" style={{ width: '150px', height: '220px'}} />
                    <img src={dummyProd2} alt="dummyProd2" style={{ width: '150px', height: '220px'}} />
                    <img src={dummyProd3} alt="dummyProd3" style={{ width: '150px', height: '220px'}} />
                </InlineStack>
                <InlineStack align="space-between">
                    <Text as="span" variant="bodyLg" fontWeight="semibold">Recommended</Text>
                    <InlineStack gap="100">
                        <Text as="span" variant="bodyLg" fontWeight="semibold">Filter</Text>
                        <Icon source={AdjustIcon} />
                    </InlineStack>
                </InlineStack>
                <InlineStack gap="100" align="start" wrap={false}>
                    <img src={dummyProd4} alt="dummyProd4" style={{ width: '150px', height: '220px'}} />
                    <img src={dummyProd5} alt="dummyProd5" style={{ width: '150px', height: '220px'}} />
                    <img src={dummyProd1} alt="dummyProd1" style={{ width: '150px', height: '220px'}} />
                </InlineStack>
            </BlockStack>
        </Box>
    );
}

const ButtomNav = ({color}: {color: string}) => {
    return (
        <InlineStack align="space-between">
            <BlockStack align="center" inlineAlign="center">
                <div style={{color}}>
                    <Icon source={HomeFilledIcon} />
                    <Text as="span">Home</Text>
                </div>
            </BlockStack>
            <BlockStack align="center" inlineAlign="center">
                <Icon source={PackageIcon} />
                <Text as="span">Shop</Text>
            </BlockStack>
            <BlockStack align="center" inlineAlign="center">
                <Icon source={PlayIcon} />
                <Text as="span">POPCLIPS</Text>
            </BlockStack>
            <BlockStack align="center" inlineAlign="center">
                <Icon source={HeartIcon} />
                <Text as="span">Favorites</Text>
            </BlockStack>
            <BlockStack align="center" inlineAlign="center">
                <Icon source={PersonIcon} />
                <Text as="span">Account</Text>
            </BlockStack>
        </InlineStack>
    );
}