import { PropsWithChildren } from 'react';
import { AlertModal } from './AlertModal';
import { BlockStack, Box, Icon, InlineStack, Text } from '@shopify/polaris';
import { CheckIcon } from '@shopify/polaris-icons';
import upgradePlan from '../../assets/images/upgradePlan.svg';
import { useNavigate } from 'react-router-dom';
import { vfsRoutes } from '@/constants/routes.constants';

type PlanUpgradeAlertModalProps = {
    showPlanUpgradeModal?: boolean;
    handleCloseModal: () => void;
    type?: string;
};

const featureList = [
    "Remove 'powered by video.live' branding",
    'Create stunning, collection-based custom video carousels to showcase tailored content',
    "Enable visitors to 'like' Popclips",
    "Display 'likes count' directly on videos to drive engagement and social proof",
    'Control like counter & custom carousels',
    'AI-driven auto placement of videos on feed',
];

const basicPlanModalList = [
    'Pick and choose videos to create custom video carousels',
    'Publish custom video carousels anywhere on your website',
    'Manage your custom video carousels',
    'Import your videos directly from Instagram',
    "Display video 'view count' directly on video",
    'Control view counter & carousel settings',
];

export const PlanUpgradeAlertModal = ({
    showPlanUpgradeModal = false,
    handleCloseModal,
    type = '',
}: PropsWithChildren<PlanUpgradeAlertModalProps>) => {
    const navigate = useNavigate();

    const handleUpgradeNow = () => {
        navigate(vfsRoutes.managePlans);
    };

    return (
        <AlertModal
            title="Upgrade To Access Feature"
            positiveBtnLabel="Upgrade Now"
            negativeBtnLabel="Cancel"
            positiveAction={handleUpgradeNow}
            negativeAction={() => handleCloseModal()}
            shouldShowModal={showPlanUpgradeModal}
            closeModal={() => handleCloseModal()}
            size="small"
        >
            <Box>
                <Box padding="200">
                    <BlockStack inlineAlign="center" gap="100">
                        <img
                            alt=""
                            width="90px"
                            height="120px"
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                            src={upgradePlan}
                        />
                        <Text variant="headingLg" as="h5" alignment="center">
                            Uh-oh! You currently don’t have access.Unlock More
                            Power with our plans!
                        </Text>
                        <Text as="p" alignment="center">
                            To access Select by Collection in Creating Custom
                            Carousel and other powerful tools designed to boost
                            your reach and impact, you’ll need to upgrade to a
                            higher plan.
                        </Text>
                    </BlockStack>
                </Box>
                <Box
                    borderRadius="200"
                    background="bg-fill-info-secondary"
                    padding="200"
                >
                    {' '}
                    {type === 'POPCLIPS_BASIC_PLAN' && (
                        <Box paddingBlockEnd="200">
                            <Text as="h6" fontWeight="bold">
                                Everything in Free, plus:
                            </Text>
                        </Box>
                    )}
                    {type === 'POPCLIPS_BASIC_PLAN'
                        ? basicPlanModalList.map((item) => (
                              <InlineStack key={item} gap="100" wrap={false}>
                                  <Box>
                                      <Icon source={CheckIcon} tone="info" />
                                  </Box>
                                  <Text as="p" key={item}>
                                      {item}
                                  </Text>
                              </InlineStack>
                          ))
                        : featureList.map((item) => (
                              <InlineStack key={item} gap="100" wrap={false}>
                                  <Box>
                                      <Icon source={CheckIcon} tone="info" />
                                  </Box>
                                  <Text as="p" key={item}>
                                      {item}
                                  </Text>
                              </InlineStack>
                          ))}
                </Box>
            </Box>
        </AlertModal>
    );
};
