import { PropsWithChildren, ReactNode } from 'react';
import { Card, BlockStack, Box, Divider } from '@shopify/polaris';

type PopClipsCardProps = {
    cardHeader?: ReactNode;
    cardFooter?: ReactNode;
}

const PopClipsCard = ({
    cardHeader,
    children,
    cardFooter,
}: PropsWithChildren<PopClipsCardProps>) => {
    return (
        <Box>
            <Card padding="0">
                <BlockStack>
                    {cardHeader && cardHeader}
                    {cardHeader && <Box paddingInline="300"><Divider /></Box>}
                    {children && <Box padding="300">{children}</Box>}
                    {cardFooter && cardFooter}
                </BlockStack>
            </Card>
        </Box>
    );
};

export default PopClipsCard;
