import { useState, MouseEvent, useEffect, Suspense, lazy } from 'react';
import {
    InlineGrid,
    Button,
    InlineStack,
    BlockStack,
    Box,
    Text,
    Spinner,
    Popover,
    ActionList,
    Icon,
} from '@shopify/polaris';
import {
    SortIcon,
    EditIcon,
    DeleteIcon,
    CodeIcon,
    ViewIcon,
    HeartIcon,
} from '@shopify/polaris-icons';
import { AutocompleteInput } from '@/components/common';
import { AlertModal } from '@/components/common/AlertModal';
import { useMediaGalleryAuthFetch } from '@/hooks/useAuthenticatedFetch';
import { popclipsRoutes } from '@/constants/routes.constants';
import { useNavigate } from 'react-router-dom';
import type { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';
import shopStore from '@/stores/shopStore';
import { useSnapshot } from 'valtio';
import { videoLiveSellingStore } from '@/stores';

const LightboxComponent = lazy(
    () => import('@/components/common/LightboxComponent'),
);

type ClipListingCardProps = {
    clipDataArray: ListMediaByIdResponse[];
    refreshData: (goToPrevPage: boolean) => void;
};

type lightboxSlide = import('yet-another-react-lightbox').Slide;

const ClipListingCard = ({
    clipDataArray,
    refreshData,
}: ClipListingCardProps) => {
    const navigate = useNavigate();
    const [popoverForId, setPopoverForId] = useState<string | number | null>();
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [lighboxSlideArray, setLighboxSlideArray] = useState<lightboxSlide[]>(
        [],
    );
    const [showLightBox, setShowLightBox] = useState<string | null>(null);
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    const { shopifyDomain } = useSnapshot(shopStore);

    useEffect(() => {
        let tempArray: lightboxSlide[] = [];
        let clipsUrlArray: lightboxSlide[] = [];
        let startPositionFound = false;

        clipDataArray.forEach((clipData) => {
            if (clipData.status !== 'processed') return;

            if (
                !startPositionFound &&
                clipData.metaIdentifier == showLightBox
            ) {
                startPositionFound = true;
            }

            if (startPositionFound) {
                clipsUrlArray.push({
                    type: 'video',
                    sources: [
                        {
                            src: `${clipData.cdnUrl}${clipData.metaData.processedURL}`,
                            type: 'video/mp4',
                        },
                    ],
                });
            } else {
                tempArray.push({
                    type: 'video',
                    sources: [
                        {
                            src: `${clipData.cdnUrl}${clipData.metaData.processedURL}`,
                            type: 'video/mp4',
                        },
                    ],
                });
            }
        });

        clipsUrlArray = [...clipsUrlArray, ...tempArray];
        setLighboxSlideArray(clipsUrlArray);
    }, [showLightBox, clipDataArray]);

    const fetch = useMediaGalleryAuthFetch();

    const handleVideoPlayOnHover = (e: MouseEvent) => {
        const elm = e.target as HTMLVideoElement;
        elm.play();
    };

    const handleVideoResetOnOut = (e: MouseEvent) => {
        const elm = e.target as HTMLVideoElement;
        elm.pause();
        elm.currentTime = 0;
    };

    const onChangeAutocompleteValue = (value: string) => {
        console.log(value);
        return []; // don't want suggestions
    };

    const toggleActive = (id: number | string | null) => {
        setPopoverForId(id == popoverForId ? null : id);
    };

    const handleEmbedCodeClick = async () => {
        const clipFound = clipDataArray.find(({ metaIdentifier }) => metaIdentifier == popoverForId);
        if (!clipFound) return;

        const urlString = `https://${import.meta.env.MODE == 'qa' ? 'dev.' : ''}popshop.live/shopifyClips/${shopStore.popshopliveSellerId}/${clipFound.metaIdentifier}`;
        const dataLakeURL = `${shopStore.config?.csDataLakeConfig?.apiUrl}/ingest/analytics`;
        const iframeStr = generateIframeString(
            urlString,
            dataLakeURL,
            clipFound.metaData.videoTitle,
            shopifyDomain,
            clipFound,
        );

        try {
            await copyToClipboard(iframeStr);
            shopify.toast.show('Embed Code copied to your clipboard', { isError: false, duration: 5000 });
        } catch (error) {
            console.error('Error while copying iframe: ', error);
        } finally {
            toggleActive(null);
        }
    };

    const handleEditClick = async () => {
        toggleActive(null);
        if (popoverForId) {
            videoLiveSellingStore.resetCreatePopClip();
            navigate(popclipsRoutes.edit.replace(':id', popoverForId.toString()));
        } 
    };

    const handleDeleteClip = async () => {
        setShowDeleteAlert(false);
        const clipFound = clipDataArray.find(({ metaIdentifier }) => metaIdentifier == popoverForId);
        if (!clipFound) return;

        try {
            await fetch('/csDeleteMedia', {
                method: 'POST',
                body: JSON.stringify({
                    id: clipFound.id,
                    metaIdentifier: clipFound.metaIdentifier,
                }),
            });

            refreshData(clipDataArray.length == 1);
            shopify.toast.show('Clip successfully deleted.', {
                isError: false,
                duration: 5000,
            });
        } catch (error) {
            console.error(error);
            shopify.toast.show(
                'Something went wrong while trying to delete the clip.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
        } finally {
            toggleActive(null);
        }
    };

    const countAbbreviation = (count) => {
        if (!(typeof count === 'number')) {
            return count;
        }
        const COUNT_ABBRS = ['', 'K', 'M', 'B', 'T'];
        if (count < 1000) {
            return count;
        }
        const i = Math.floor(Math.log(count) / Math.log(1000));
        let result = count / 1000 ** i;
        const digits = Math.floor(Math.log10(result)) + 1;
        const decimals = Math.max(0, 2 - digits);
        result = result.toFixed(decimals);
        result = result + COUNT_ABBRS[i];
        return result;
    };

    return (
        <BlockStack gap="400">
            <div style={{ display: 'none' }}>
                <Box paddingInlineEnd="100">
                    <InlineGrid gap="300" columns={2}>
                        <AutocompleteInput
                            placeholderStr="Search by Clip Title"
                            onChangeValue={onChangeAutocompleteValue}
                            shouldDisabled={false}
                            debounceTimeout={500}
                            hideEmptyState={true}
                        />
                        <InlineStack align="end">
                            <Button icon={SortIcon} size="medium">
                                Sort
                            </Button>
                        </InlineStack>
                    </InlineGrid>
                </Box>
            </div>
            <InlineStack
                wrap={true}
                align="start"
                blockAlign="center"
                gap="500"
            >
                {clipDataArray.map((clip) => (
                    <Box
                        key={clip.metaIdentifier}
                        background="bg-fill"
                        borderRadius="200"
                        overflowX="hidden"
                        overflowY="hidden"
                        borderColor="border"
                        borderWidth="025"
                        shadow="100"
                    >
                        {clip.status !== 'processed' ? (
                            <VideoLoader text="Processing..." />
                        ) : (
                            <>
                                {isVideoLoading && (
                                    <VideoLoader text="Loading..." />
                                )}
                                <div
                                    style={{
                                        position: 'relative',
                                        display: isVideoLoading
                                            ? 'none'
                                            : 'block',
                                    }}
                                >
                                    <video
                                        width="149"
                                        height="242"
                                        muted
                                        style={{
                                            objectFit: 'cover',
                                            cursor: 'pointer',
                                        }}
                                        onMouseOver={handleVideoPlayOnHover}
                                        onMouseOut={handleVideoResetOnOut}
                                        onLoadedData={() =>
                                            setIsVideoLoading(false)
                                        }
                                        onClick={() =>
                                            setShowLightBox(clip.metaIdentifier)
                                        }
                                    >
                                        <source
                                            src={`${clip.cdnUrl}${clip.metaData.processedURL}`}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                    </video>
                                    {(clip?.showViews || false) && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                backgroundColor:
                                                    'rgba(0, 0, 0, 0.5)',
                                                width: '100%',
                                                color: 'white',
                                            }}
                                        >
                                            <Box padding="200">
                                                <InlineStack
                                                    align="space-between"
                                                    blockAlign="center"
                                                >
                                                    {clip?.showViews && (
                                                        <InlineStack
                                                            gap="200"
                                                            blockAlign="center"
                                                        >
                                                            <Icon
                                                                source={
                                                                    ViewIcon
                                                                }
                                                            />{' '}
                                                            <Text
                                                                as="p"
                                                                fontWeight="bold"
                                                            >
                                                                {countAbbreviation(
                                                                    clip?.clipViews ||
                                                                        0,
                                                                )}
                                                            </Text>
                                                        </InlineStack>
                                                    )}
                                                    {clip?.showLikes && (
                                                        <InlineStack
                                                            gap="200"
                                                            blockAlign="center"
                                                        >
                                                            <Icon
                                                                source={
                                                                    HeartIcon
                                                                }
                                                            />{' '}
                                                            <Text
                                                                as="p"
                                                                fontWeight="bold"
                                                            >
                                                                {countAbbreviation(
                                                                    clip?.clipLikes,
                                                                )}
                                                            </Text>
                                                        </InlineStack>
                                                    )}
                                                </InlineStack>
                                            </Box>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        <InlineStack align="space-between">
                            <Box width="100px" paddingInlineStart="200">
                                <Text as="p" truncate>
                                    {clip.metaData.videoTitle}
                                </Text>
                            </Box>
                            <Popover
                                active={clip.metaIdentifier == popoverForId}
                                preferredAlignment="right"
                                activator={
                                    <Button
                                        variant="tertiary"
                                        onClick={() =>
                                            toggleActive(clip.metaIdentifier)
                                        }
                                    >
                                        ...
                                    </Button>
                                }
                                onClose={() =>
                                    toggleActive(clip.metaIdentifier)
                                }
                            >
                                <ActionList
                                    actionRole="menuitem"
                                    items={[
                                        {
                                            content: 'Embed Code',
                                            icon: CodeIcon,
                                            onAction: handleEmbedCodeClick,
                                        },
                                        {
                                            content: 'Edit',
                                            icon: EditIcon,
                                            onAction: handleEditClick,
                                        },
                                        {
                                            content: 'Delete',
                                            icon: DeleteIcon,
                                            onAction: () =>
                                                setShowDeleteAlert(true),
                                        },
                                    ]}
                                />
                            </Popover>
                        </InlineStack>
                    </Box>
                ))}
            </InlineStack>
            {showLightBox !== null && (
                <Suspense>
                    <LightboxComponent
                        slideArray={lighboxSlideArray}
                        handleCloseClick={() => setShowLightBox(null)}
                    />
                </Suspense>
            )}
            <AlertModal
                title="Delete Popclip"
                positiveBtnLabel="Delete"
                negativeBtnLabel="Cancel"
                positiveAction={handleDeleteClip}
                negativeAction={() => setShowDeleteAlert(!showDeleteAlert)}
                shouldShowModal={showDeleteAlert}
                closeModal={() => setShowDeleteAlert(!showDeleteAlert)}
                hasDeletebutton
                size="small"
            >
                <Text as="p">
                    Are you sure ? The clip will be permanently deleted.
                </Text>
            </AlertModal>
        </BlockStack>
    );
};

const VideoLoader = ({ text }: { text: string }) => (
    <Box minHeight="242px" minWidth="149px">
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '248px',
                gap: '4px',
                flexDirection: 'column',
            }}
        >
            <Spinner size="small" />
            <Text as="p">{text}</Text>
        </div>
    </Box>
);

const generateIframeString = (
    url: string,
    dataLakeURL: string,
    title: string,
    shopifyDomain: string,
    clipMetadata: ListMediaByIdResponse,
) => {
    return `
        <iframe id="popshop-iframe-embed-${title}" allowFullScreen></iframe>
        <script type="text/javascript">
            (async () => {
                var iframe = document.getElementById('popshop-iframe-embed-${title}');
                iframe.style.width = '400px';
                iframe.style.height = '700px';
                iframe.style.border = '0';
                iframe.title = '${title}';
                iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
                iframe.src = '${url}';
                
                iframe.onload = async () => {
                    const body = {
                        element_action: "popclip_view_embedded",
                        element_category: "popclip",
                        event_timestamp:   Date.now(),
                        element_keyword: "${title}",
                        element_primary_id: "${clipMetadata.metaIdentifier}",
                        shop_id: "${shopifyDomain}",
                        entry_url: window.location.href,
                        event_context: {
                            gclid: null,
                            dclid: null,
                            gbraid: null,
                            wbraid: null,
                            fbclid: null,
                            twclid: null,
                            clid_src: null,
                            msclkid: null,
                            ttclid: null,
                            device_model:navigator.userAgentData?.brands[1]?.brand || "Unknown",
                            device_manufacturer: "Unknown",
                            os_version: navigator.platform.split(" ")?.[1] || "Unknown",
                            operating_system: navigator?.userAgentData?.platform || navigator?.platform || "Unknown",
                            notifications_enabled: false,
                            app_tracking_enabled: false,
                            language: navigator.language.split('-')[0],
                            locale: navigator.language,
                            location: document.location.href,
                            viewed_on: window.location.href,
                            duration: 0,
                        },
                        signed_in: false,
                        event_order_currency: null,
                        event_order_id: null,
                        event_order_total: 0,
                        event_platform: "vfs_web",
                        event_platform_version: "1.03",
                        platform_user_id: "guest",
                        utm_campaign: "${clipMetadata.id}_${clipMetadata.metaIdentifier}",
                        utm_content: "popclip_view_embedded",
                        utm_medium: "popclip",
                        utm_source: "shopify",
                        utm_term: "popclip_view_embedded",
                    };
                    fetch('${dataLakeURL}', {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(body),
                        redirect: "follow",
                    });
                };
            })();
        </script>
    `;
};

const copyToClipboard = (str: string) => {
    return navigator.clipboard.writeText(str);
};

export default ClipListingCard;
