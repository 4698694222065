import { BlockStack, Box, Button, Divider, InlineStack, Text } from "@shopify/polaris";

type LivePlanAddonCardProps = {
    addonData: any;
    addonValue: number;
    isSelected: boolean;
    isDisabled: boolean;
    onSelectPlan: () => void;
};

export default function LivePlanAddonCard({
    addonData,
    addonValue,
    isSelected,
    onSelectPlan,
    isDisabled,
}: LivePlanAddonCardProps) {
    const formatedAddonValue =
        addonValue > 999999 ? 'Unlimited Live Events' : `${addonValue.toString()} Live Event`;

    return (
        <Box
            borderRadius="400"
            borderWidth="025"
            padding="400"
            borderColor="border-disabled"
            shadow="200"
        >
            <BlockStack gap="400">
                <InlineStack align="space-between" wrap={false}>
                    <BlockStack gap="100">
                        <Text as="p" fontWeight="bold" variant="headingLg">
                            {addonData.name}
                        </Text>
                        <BlockStack gap="100">
                            <Text as="p" variant="bodySm" fontWeight="semibold">
                                {formatedAddonValue}
                            </Text>
                            <Text as="p" variant="bodySm">
                                5% of Live Sale Revenue Charged as Extra Usage/Success Fees
                            </Text>
                        </BlockStack>
                    </BlockStack>
                    <Box width="10rem" paddingInlineStart="500">
                        <InlineStack align="end" blockAlign="center" wrap={false} gap="200">
                            {Math.round(parseFloat(addonData.price[0].amount)) === 0 ? (
                                <Text as="p" fontWeight="bold" variant="heading2xl">
                                    Free
                                </Text>
                            ) : (
                                <>
                                    <Text as="p" fontWeight="bold" variant="heading2xl">
                                        {addonData.price[0].amount}
                                    </Text>
                                    <BlockStack>
                                        <Text as="p" variant="bodyXs">
                                            {addonData.price[0].currencyCode}
                                        </Text>
                                        <Text as="p" variant="bodyXs">
                                            /mo
                                        </Text>
                                    </BlockStack>
                                </>
                            )}
                        </InlineStack>
                    </Box>
                </InlineStack>
                <Divider />
                <InlineStack align="end" gap="200">
                    {isSelected ? (
                        <Button
                            variant="primary"
                            tone="success"
                            onClick={onSelectPlan}
                            disabled={isDisabled}
                        >
                            Selected
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={onSelectPlan}
                            disabled={isDisabled}
                        >
                            Select Plan
                        </Button>
                    )}
                </InlineStack>
            </BlockStack>
        </Box>
    );
}