import { proxy } from 'valtio';
import { hexColorRegex } from '@/utils/validation';

const mobileAppConfigStore = proxy({
    isLoading: true,
    featuredShopifyCollectionGid: '',
    headerLogoUrl: '',
    headerLogoUrlErr: '',
    customFont: '',
    get isCustomFontValid() {
        return this.customFont !== '';
    },
    primaryColor: '#000000',
    get isPrimaryColorHexValid() {
        return (
            this.primaryColor.length > 0 &&
            hexColorRegex.test(this.primaryColor)
        );
    },
    secondaryColor: '#000000',
    get isSeconadaryColorHexValid() {
        return (
            this.secondaryColor.length > 0 &&
            hexColorRegex.test(this.secondaryColor)
        );
    },
    bannerTitle: '',
    get isBannerTitleValid() {
        return this.bannerTitle.length > 0;
    },
    bannerText: '',
    get isBannerTextValid() {
        return this.bannerText.length > 0;
    },
    bannerColor: '#000000',
    get isBannerColorValid() {
        return (
            this.bannerColor.length > 0 &&
            hexColorRegex.test(this.bannerColor)
        );
    },
    liveBannerTitle: '',
    get isLiveBannerTitleValid() {
        return this.liveBannerTitle.length > 0;
    },
    liveBannerText: '',
    get isLiveBannerTextValid() {
        return this.liveBannerText.length > 0;
    },
    liveBannerColor: '#000000',
    get isLiveBannerColor() {
        return (
            this.liveBannerColor.length > 0 &&
            hexColorRegex.test(this.liveBannerColor)
        );
    },
    notificationsTitle: '',
    get isNotificationsTitleValid() {
        return this.notificationsTitle.length > 0;
    },
    notificationsBody: '',
    get isNotificationsBodyValid() {
        return this.notificationsBody.length > 0;
    },

    showFormErrorLabel: false,
    showNotificationErrorLabel: false,
    shopifyCollectionGid: '',
    menuHandle: 'main-menu',
    shopMenuHandle: 'main-menu',
    customLinksHandle: 'main-menu',
    showInventoryLimit: 10,
});
export default mobileAppConfigStore;
