import { proxy } from 'valtio';
import type { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';
import { getSanitizedPhoneNumber } from '@/utils/sanitize';
const utf8mb4Regex = /[\u{10000}-\u{10FFFF}]/u;

export type ShopifyProductsFormated = {
    id: string;
    title: string;
    description: string;
    handle: string;
    amount: string;
    currencyCode: string;
    imgSrc: string;
    imgAlt: string;
    createdAt?: string;
};

type ShopifyProductPagination = {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    nextCursor: string;
    prevCursor: string;
};

type VIDEO_LIVE_SELLING_STORE = {
    userAuth: import('firebase/auth').ConfirmationResult | null;
    countryCode: string;
    phoneNumber: string;
    isPhoneNumberInvalid: boolean;
    validatePhoneNumber: () => boolean;
    email: string;
    firstName: string;
    lastName: string;
    confirmationCode: string;
    confirmationCodeErr: string;
    clipTitle: string;
    clipInstaUrl: string;
    clipInstaUrlErr: string;
    clipTitleErr: string;
    videoUrl: string;
    videoUrlErr: string;
    setConfiramtionCode: (value: string) => void;
    hasConfirmationCodeErr: () => void;
    setClipTitle: (value: string) => void;
    hasClipTitleErr: () => void;
    hasInstaUrlErr: () => void;
    setVideoUrl: (value: string) => void;
    hasVideoUrlErr: () => void;
    userAuthDetails?: import('firebase/auth').UserCredential;
    sellerID?: string;
    isClipExcluded: boolean;
    areProductsLoading: boolean;
    shopifySelectedProducts: ShopifyProductsFormated[];
    uploadedPopClipData: {
        clipId: string;
        clipMetaIdentifier: string;
        clipMetaData: object;
        uploadVideoUrl: string;
    };
    dropzoneFile: FormData | null;
    resetCreatePopClip: () => void;
    savingPopclip: boolean;
    editClipData: ListMediaByIdResponse | null;
    shopifyProductPagination: ShopifyProductPagination;
    gridCurrentPage: number;
    gridLastEvalKey: {
        [key: string]:
            | {
                  id: string;
                  fileTypeAndIdKey: string;
                  metaIdentifier: string;
              }
            | undefined;
    };
};

const videoLiveSellingStore: VIDEO_LIVE_SELLING_STORE =
    proxy<VIDEO_LIVE_SELLING_STORE>({
        countryCode: '+1',
        phoneNumber: '',
        isPhoneNumberInvalid: false,
        validatePhoneNumber: () => {
            const countryCodePattern = /^(\+?\d{1,3}|\d{1,4})$/;
            const phonePattern = /^(?:[0-9\-\\(\\)\\\/.]\s?){4,15}[0-9]{1}$/;
            const eDot164Pattern = /^\+[1-9]\d{1,14}$/;

            let isValid = countryCodePattern.test(
                videoLiveSellingStore.countryCode,
            );
            if (!isValid) {
                videoLiveSellingStore.isPhoneNumberInvalid = true;
                return false;
            }

            isValid = phonePattern.test(videoLiveSellingStore.phoneNumber);
            if (!isValid) {
                videoLiveSellingStore.isPhoneNumberInvalid = true;
                return false;
            }

            const phoneNumber = `${videoLiveSellingStore.countryCode}${videoLiveSellingStore.phoneNumber}`;
            const sanitizedPhoneNumber = getSanitizedPhoneNumber(phoneNumber);
            isValid = eDot164Pattern.test(sanitizedPhoneNumber);
            if (!isValid) {
                videoLiveSellingStore.isPhoneNumberInvalid = true;
                return false;
            }

            videoLiveSellingStore.isPhoneNumberInvalid = false;
            return true;
        },
        confirmationCode: '',
        setConfiramtionCode: (code: string) => {
            videoLiveSellingStore.confirmationCode = code;
        },
        confirmationCodeErr: '',
        hasConfirmationCodeErr: () => {
            const isValid =
                videoLiveSellingStore.confirmationCode.length > 0 &&
                videoLiveSellingStore.confirmationCode.length === 6 &&
                (videoLiveSellingStore.confirmationCode.match(/\d/g)?.length ??
                    0) >= 6 &&
                !utf8mb4Regex.test(videoLiveSellingStore.confirmationCode);
            videoLiveSellingStore.confirmationCodeErr = isValid
                ? ''
                : 'Please enter a valid code';
        },
        clipTitle: '',
        setClipTitle: (title: string) => {
            videoLiveSellingStore.clipTitle = title;
        },
        clipTitleErr: '',
        hasClipTitleErr: () => {
            videoLiveSellingStore.clipTitleErr =
                videoLiveSellingStore.clipTitle.trim() === ''
                    ? 'This is a required field'
                    : '';
        },
        clipInstaUrl: '',
        clipInstaUrlErr: '',
        hasInstaUrlErr: () => {
            const instaReelUrl = videoLiveSellingStore.clipInstaUrl.trim();
            videoLiveSellingStore.clipInstaUrlErr = '';

            if (instaReelUrl === '') {
                videoLiveSellingStore.clipInstaUrlErr =
                    'This is a required field';
            } else if (
                !instaReelUrl.startsWith('https://www.instagram.com/reel/')
            ) {
                videoLiveSellingStore.clipInstaUrlErr =
                    'The link must start with https://www.instagram.com/reel/';
            }
        },
        videoUrl: '',
        setVideoUrl: (url: string) => {
            videoLiveSellingStore.videoUrl = url;
            videoLiveSellingStore.clipInstaUrl = '';
        },
        videoUrlErr: '',
        hasVideoUrlErr: () => {
            videoLiveSellingStore.videoUrlErr =
                videoLiveSellingStore.videoUrl.trim() === ''
                    ? 'This is a required field'
                    : '';
        },
        userAuth: null,
        userAuthDetails: undefined,
        isClipExcluded: false,
        areProductsLoading: false,
        shopifySelectedProducts: [] as ShopifyProductsFormated[],
        email: '',
        firstName: '',
        lastName: '',
        uploadedPopClipData: {
            clipId: '',
            clipMetaIdentifier: '',
            clipMetaData: {},
            uploadVideoUrl: '',
        },
        dropzoneFile: null,
        resetCreatePopClip: () => {
            videoLiveSellingStore.shopifySelectedProducts = [];
            videoLiveSellingStore.clipTitle = '';
            videoLiveSellingStore.videoUrl = '';
            videoLiveSellingStore.clipInstaUrl = '';
            videoLiveSellingStore.dropzoneFile = null;
            videoLiveSellingStore.editClipData = null;
            videoLiveSellingStore.clipTitleErr = '';
            videoLiveSellingStore.videoUrlErr = '';
            videoLiveSellingStore.isClipExcluded = false;
        },
        editClipData: null,
        savingPopclip: false,
        shopifyProductPagination: {} as ShopifyProductPagination,
        gridCurrentPage: 1,
        gridLastEvalKey: {},
    });

export default videoLiveSellingStore;
