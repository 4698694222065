import PopClipsCard from '@/components/common/PopClipsCard';
import {
    Bleed,
    BlockStack,
    Box,
    Button,
    Card,
    Grid,
    Icon,
    InlineStack,
    Link,
    RadioButton,
    Spinner,
    Text,
    Thumbnail,
} from '@shopify/polaris';
import {
    ImageIcon,
    LockFilledIcon,
    PlusIcon,
    ReplaceIcon,
} from '@shopify/polaris-icons';
import { createCarousel } from '../../../assets/images';
import CarouselClip from './CarouselClip';
import { useEffect, useState } from 'react';
import { shopStore } from '@/stores';
import { useGetShopifyProducts } from '@/hooks/useGetShopifyProducts';
import { PlanUpgradeAlertModal } from '@/components/common/PlanUpgradeAlertModal';

type CarouselClipSelectorProps = {
    toggleOpenModal: () => void;
    selectedClipData: any[];
    isApiLoading: boolean;
    handleCarouselTypeSelect: (type: string) => void;
    carouselType: string;
    handleCollectionSelect: (type: []) => void;
    collectionID: any[];
    isEditMode?: boolean;
};

export default function CarouselClipSelector({
    toggleOpenModal,
    selectedClipData,
    isApiLoading,
    handleCarouselTypeSelect,
    carouselType,
    handleCollectionSelect,
    collectionID,
    isEditMode,
}: CarouselClipSelectorProps) {
    const [showCollectionButton, setShowCollectionButton] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState([]);
    const [collectionVideoPopclips, setCollectionVideoPopclips] = useState<
        [] | null
    >(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);

    const { getProductsByCollectionId } = useGetShopifyProducts();

    const handleTypeSelect = (type: string) => {
        handleCarouselTypeSelect(type);
    };

    const handlePlanCheck = () => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            'popclips',
            true,
        );
        const maxPrice = Math.max(...addonPrices);
        setShowPlanUpgradeModal(maxPrice < 149.99);
        if (maxPrice >= 149.99) {
            handleTypeSelect('collection');
        }
    };

    const handleCollectionPicker = async () => {
        const selected = await shopify.resourcePicker({
            type: 'collection',
            multiple: false,
            action: 'select',
            filters: {
                hidden: false,
                variants: false,
                draft: false,
                archived: false,
            },
            selectionIds: selectedCollection.map(({ id }) => ({ id })),
        });
        if (selected?.length > 0) {
            setSelectedCollection(selected);
            const result = selected.map(({ id }) => ({
                id,
            }));
            handleCollectionSelect([result[0]?.id]);
        }
    };

    const fetchProductsIdsByCollection = async (
        featuredShopifyCollectionGid: string,
    ) => {
        try {
            const response = await getProductsByCollectionId(
                featuredShopifyCollectionGid,
            );
            const productArr = response?.products?.edges.map(({ node }) => ({
                id: node?.id,
                updatedAt: node?.createdAt,
            }));
            if (isEditMode && response) {
                setSelectedCollection([response]);
            }

            createPopclipByCollection(productArr);
        } catch (error) {
            console.log(error);
        }
    };

    const createPopclipByCollection = async (productArr: [] = []) => {
        try {
            if (productArr?.length < 1 || !showCollectionButton) {
                setCollectionVideoPopclips(null);
                return;
            }
            setIsLoading(true);
            const URL = import.meta.env.MODE == 'qa'
                ? 'https://qa.popclips-service.qa.cs-api.com/api/popclips/list'
                : 'https://prod.popclips-service.cs-api.com/api/popclips/list';
            const response = await fetch(URL,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        sellerId: shopStore.popshopliveSellerId,
                        products: productArr,
                    }),
                },
            );
            const { data } = await response.json();
            setCollectionVideoPopclips(data?.items || []);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (collectionID?.[0]) {
            fetchProductsIdsByCollection(collectionID[0]);
        }
    }, [collectionID]);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            'popclips',
            true,
        );
        const maxPrice = Math.max(...addonPrices);
        setShowCollectionButton(maxPrice >= 149.99);
    }, []);

    const radioButtonsSection = (
        <Box>
            <InlineStack gap="400" wrap={false}>
                {(!isEditMode || (isEditMode && carouselType === 'custom')) && (
                    <Card>
                        <RadioButton
                            label="Select By Popclip"
                            checked={carouselType === 'custom'}
                            helpText="Pick your favorite Popclips and showcase them in a carousel on any page of your website."
                            id="popclip"
                            name="carousel_type"
                            onChange={() => handleTypeSelect('custom')}
                        />
                    </Card>
                )}
                {(!isEditMode ||
                    (isEditMode && carouselType === 'collection')) && (
                    <Card
                        background={
                            !showCollectionButton
                                ? 'bg-fill-disabled'
                                : 'bg-surface'
                        }
                    >
                        {!showCollectionButton && (
                            <Bleed marginBlockEnd="600">
                                <Icon source={LockFilledIcon} tone="base" />
                            </Bleed>
                        )}
                        <div onClick={handlePlanCheck}>
                            <RadioButton
                                disabled={!showCollectionButton}
                                label="Select By Collection"
                                checked={carouselType === 'collection'}
                                helpText="Choose a collection to create a carousel of Popclips featuring products from that collection on your website."
                                id="collection"
                                name="carousel_type"
                            />
                        </div>
                    </Card>
                )}
            </InlineStack>
        </Box>
    );

    const selectedCollectionSection =
        selectedCollection?.length > 0 ? (
            <InlineStack gap="400">
                {selectedCollection &&
                    selectedCollection.map((collection) => (
                        <Card padding="200" key={collection.id}>
                            <InlineStack gap="200">
                                <Thumbnail
                                    source={
                                        collection?.image?.originalSrc
                                            ? collection?.image?.originalSrc
                                            : ImageIcon
                                    }
                                    alt={collection?.title}
                                    size="small"
                                />
                                <BlockStack>
                                    <Text as="p">{collection?.title}</Text>
                                    {collection?.productsCount ||
                                    collection.productsCount == 0 ? (
                                        <Text as="p">
                                            {collection?.productsCount ||
                                            collection.productsCount == 0
                                                ? collection?.productsCount == 0
                                                    ? ' 0 product'
                                                    : collection?.productsCount +
                                                            collection?.productsCount >
                                                        1
                                                      ? collection.productsCount +
                                                        ' products'
                                                      : ' product'
                                                : collection.productsCount +
                                                  'products'}
                                        </Text>
                                    ) : (
                                        <Text as="p">
                                            {collection?.products?.edges?.length
                                                ? collection.products.edges
                                                      .length == 250
                                                    ? '250+ products'
                                                    : collection.products.edges
                                                          .length + ' products'
                                                : '0 product'}
                                        </Text>
                                    )}
                                </BlockStack>
                            </InlineStack>
                        </Card>
                    ))}
            </InlineStack>
        ) : (
            <Box paddingBlockEnd="200">
                <InlineStack gap="100">
                    <Text as="p">
                        You currently have no collection selected. Please ensure
                        the collection you want to add is published to the
                        Videeo and Online Sales Channels.
                    </Text>
                    <Link url="https://help.videeo.live/hc/en-us/articles/23185463459092-Publish-Products-and-Collections-to-Videeo-Sales-Channel#h_01HN3JSYHK2JTRGDTP7TQ17361">
                        Learn More
                    </Link>
                </InlineStack>
            </Box>
        );
    return (
        <PopClipsCard
            cardHeader={
                <Box
                    padding="400"
                    paddingBlockEnd="300"
                    background="bg-fill-active"
                >
                    {carouselType == 'collection' ? (
                        <BlockStack gap="100" inlineAlign="baseline">
                            <Text as="h1" variant="headingMd" fontWeight="bold">
                                Select Collection
                            </Text>
                            <Text as="h1" variant="bodyMd">
                                Please select a collection to create a custom
                                carousel
                            </Text>
                        </BlockStack>
                    ) : (
                        <BlockStack gap="100" inlineAlign="baseline">
                            <Text as="h1" variant="headingMd" fontWeight="bold">
                                Selected Popclips ({selectedClipData.length})
                            </Text>
                            <Text as="h1" variant="bodyMd">
                                Please add Popclips (a minimum of 3) to this
                                custom carousel
                            </Text>
                        </BlockStack>
                    )}
                </Box>
            }
        >
            <PlanUpgradeAlertModal
                showPlanUpgradeModal={showPlanUpgradeModal}
                handleCloseModal={() => setShowPlanUpgradeModal(false)}
            />
            {radioButtonsSection}
            {carouselType == 'collection' ? (
                <Box paddingBlock="400">
                    <Text as="p" fontWeight="bold">
                        Selected Collection:
                    </Text>
                    <Box
                        paddingBlock={
                            selectedCollection?.length > 0 ? '400' : '100'
                        }
                    >
                        {selectedCollectionSection}
                    </Box>
                    {collectionID?.length < 1 ? (
                        <Button
                            icon={PlusIcon}
                            variant="primary"
                            onClick={handleCollectionPicker}
                        >
                            Select Collection
                        </Button>
                    ) : (
                        <Box>
                            {showCollectionButton && (
                                <Button
                                    variant="primary"
                                    icon={ReplaceIcon}
                                    onClick={handleCollectionPicker}
                                >
                                    Replace Collection
                                </Button>
                            )}
                            <Box padding="400">
                                {isLoading ? (
                                    <InlineStack align="center">
                                        <Spinner />
                                    </InlineStack>
                                ) : (
                                    <Grid
                                        columns={{
                                            xs: 3,
                                            sm: 4,
                                            md: 6,
                                            lg: 6,
                                            xl: 6,
                                        }}
                                    >
                                        {collectionVideoPopclips &&
                                            collectionVideoPopclips.map(
                                                (clip: any) => (
                                                    <Grid.Cell
                                                        key={
                                                            clip.metaIdentifier
                                                        }
                                                    >
                                                        <CarouselClip
                                                            clipData={clip}
                                                            hideSelectBtn
                                                        />
                                                    </Grid.Cell>
                                                ),
                                            )}
                                    </Grid>
                                )}
                            </Box>
                        </Box>
                    )}
                    {!isLoading &&
                        (collectionVideoPopclips == null ||
                            collectionVideoPopclips?.length < 1) &&
                        collectionID?.length > 0 && (
                            <Text variant="bodyLg" as="p" alignment="center">
                                No Popclip found. Please try again with a
                                different collection.
                            </Text>
                        )}
                </Box>
            ) : (
                <Box padding="400">
                    <Grid columns={{ xs: 3, sm: 4, md: 6, lg: 6, xl: 6 }}>
                        <Grid.Cell>
                            <Box
                                paddingInline="400"
                                paddingBlock="600"
                                minHeight="15rem"
                                borderStyle="dashed"
                                borderWidth="050"
                                borderColor="border-tertiary"
                                borderRadius="150"
                            >
                                <BlockStack
                                    gap="300"
                                    align="center"
                                    inlineAlign="center"
                                >
                                    <img
                                        src={createCarousel}
                                        width="125"
                                        alt="Create Carousel Placeholder"
                                    />
                                    <Text as="p" alignment="center">
                                        Add Popclips to your custom carousel
                                    </Text>
                                    <Button
                                        variant="primary"
                                        icon={PlusIcon}
                                        onClick={toggleOpenModal}
                                        disabled={isApiLoading}
                                    >
                                        Add Popclips
                                    </Button>
                                </BlockStack>
                            </Box>
                        </Grid.Cell>
                        {selectedClipData.map((clip: any) => (
                            <Grid.Cell key={clip.metaIdentifier}>
                                <CarouselClip clipData={clip} hideSelectBtn />
                            </Grid.Cell>
                        ))}
                    </Grid>
                </Box>
            )}
        </PopClipsCard>
    );
}
