import {
    Box,
    Text,
    Divider,
    BlockStack,
    SkeletonBodyText,
    InlineStack,
    Button,
    InlineGrid,
    InlineError,
} from '@shopify/polaris';
import { useSnapshot } from 'valtio';

import { buildMobileAppStore } from '@/stores';

import ImageColorPicker from './ImageColorPicker';

import {
    AppColorPicker,
    CollapsibleCardWithTitleSection,
} from '@/components/common';
import { Modal, TitleBar } from '@shopify/app-bridge-react';
import MobileHomePage from '../mobile-app-configuration/mobile-preview-screens/MobileHomePage';
import MobilePDPPage from '../mobile-app-configuration/mobile-preview-screens/MobilePDPPage';
import { useState } from 'react';

const AppIconLaunchScreen = () => {
    const [openModal, setOpenModal] = useState(false);
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });
    const { isAppRequestSubmitted } = buildMobileAppSnapshot;
    const handleNext = () => {
        if (
            buildMobileAppStore.appLogoUrl &&
            buildMobileAppStore.launchScreenImageUrl &&
            buildMobileAppStore.appLogoBgColor &&
            buildMobileAppStore.launchScreenBgColor
        ) {
            buildMobileAppStore.appLogoUrlErr = '';
            buildMobileAppStore.launchScreenImageUrlErr = '';
            buildMobileAppStore.appLogoBgColorErr = '';
            buildMobileAppStore.launchScreenBgColorErr = '';
            buildMobileAppStore.setIsFeaturedCollectionCardOpen(true);
            return;
        } else {
            buildMobileAppStore.hasAppLogoUrlErr();
            buildMobileAppStore.hasLaunchScreenImageUrlErr();
            buildMobileAppStore.hasAppLogoBgColorErr();
            buildMobileAppStore.hasLaunchScreenBgColorErr();
            return;
        }
    };
    const hasValidationError =
        buildMobileAppSnapshot.appLogoUrlErr ||
        buildMobileAppSnapshot.launchScreenImageUrlErr;

    return (
        <CollapsibleCardWithTitleSection
            title="App Icon & Launch Screen"
            subtitle="Upload images to use as your app icon and app launch screen."
            showCheck={false}
            open={buildMobileAppSnapshot.isAppIconLaunchScreenCardOpen}
            onToggleOpen={buildMobileAppStore.setIsAppIconLaunchScreenCardOpen}
            hideSubtitleWhenClosed={true}
        >
            <Box>
                {buildMobileAppSnapshot.isAppRequestLoading ||
                buildMobileAppSnapshot.isAppConfigLoading ? (
                    <SkeletonBodyText />
                ) : (
                    <BlockStack gap="800">
                        <Divider />
                        <ImageColorPicker
                            mode="image"
                            readonly={
                                buildMobileAppSnapshot.isAppRequestSubmitted
                            }
                            labelStr="Upload App Logo"
                            imageDimensions={{
                                width: 1536,
                                height: 1536,
                            }}
                            imageUrl={buildMobileAppSnapshot.appLogoUrl}
                            setImageInStore={buildMobileAppStore.setAppLogoUrl}
                            removeImageInStore={
                                buildMobileAppStore.removeAppLogo
                            }
                            isAppRequestSubmitted={isAppRequestSubmitted}
                            hintText="Accepts .gif, .jpg, and .png - Image must be 1536 x 1536 pixels"
                        />

                        <ImageColorPicker
                            mode="image"
                            readonly={
                                buildMobileAppSnapshot.isAppRequestSubmitted
                            }
                            labelStr="Upload Launch Screen Background Image"
                            imageDimensions={{
                                width: 1000,
                                height: 1600,
                            }}
                            imageUrl={
                                buildMobileAppSnapshot.launchScreenImageUrl
                            }
                            setImageInStore={
                                buildMobileAppSnapshot.setLaunchScreenImgUrl
                            }
                            removeImageInStore={
                                buildMobileAppStore.removeLaunchScreenImg
                            }
                            isAppRequestSubmitted={isAppRequestSubmitted}
                            hintText="Accepts .gif, .jpg, and .png - Image must be 1000 x 1600 pixels"
                        />
                        {!isAppRequestSubmitted &&
                        !(
                            buildMobileAppStore.iosLink ||
                            buildMobileAppStore.androidLink
                        ) ? (
                            <Divider />
                        ) : null}
                        {!isAppRequestSubmitted &&
                        !(
                            buildMobileAppStore.iosLink ||
                            buildMobileAppStore.androidLink
                        ) ? (
                            <BlockStack gap="200">
                                <InlineStack align="space-between">
                                    <Text as="p">
                                        Set the brand colors you would like to
                                        use for your app
                                    </Text>
                                    <Button
                                        variant="primary"
                                        onClick={() => setOpenModal(true)}
                                    >
                                        Preview
                                    </Button>
                                </InlineStack>
                                <InlineGrid columns={2} gap="400">
                                    <Box>
                                        <AppColorPicker
                                            required={true}
                                            label="Primary Color"
                                            hexColor={
                                                buildMobileAppSnapshot.appLogoBgColor
                                            }
                                            onHexColorChange={(val: string) => {
                                                buildMobileAppStore.setAppLogoBgColor(
                                                    val,
                                                );
                                                buildMobileAppStore.hasAppLogoBgColorErr();
                                            }}
                                            validationError={
                                                buildMobileAppSnapshot.appLogoBgColorErr
                                            }
                                        />
                                    </Box>
                                    <Box>
                                        <AppColorPicker
                                            required={true}
                                            label="Secondary Color"
                                            hexColor={
                                                buildMobileAppSnapshot.launchScreenBgColor
                                            }
                                            onHexColorChange={(val: string) => {
                                                buildMobileAppStore.setLaunchScreenBgColor(
                                                    val,
                                                );
                                                buildMobileAppStore.hasLaunchScreenBgColorErr();
                                            }}
                                            validationError={
                                                buildMobileAppSnapshot.launchScreenBgColorErr
                                            }
                                        />
                                    </Box>
                                </InlineGrid>
                            </BlockStack>
                        ) : null}
                        {!isAppRequestSubmitted &&
                        !(
                            buildMobileAppStore.iosLink ||
                            buildMobileAppStore.androidLink
                        ) ? (
                            <InlineStack
                                align="end"
                                gap="400"
                                blockAlign="center"
                            >
                                {hasValidationError && (
                                    <InlineError
                                        message="Please add images"
                                        fieldID={''}
                                    />
                                )}
                                <Button variant="primary" onClick={handleNext}>
                                    Next
                                </Button>
                            </InlineStack>
                        ) : null}
                    </BlockStack>
                )}
                <Modal
                    open={openModal}
                    variant="large"
                    onHide={() => setOpenModal(false)}
                >
                    <TitleBar title="Mobile App Color Preview">
                        <button
                            variant="primary"
                            onClick={() => setOpenModal(false)}
                        >
                            Close
                        </button>
                    </TitleBar>
                    <Box padding="400">
                        <InlineStack
                            align="center"
                            blockAlign="center"
                            gap="600"
                        >
                            <MobileHomePage
                                isLiveBanner
                                uiColor={buildMobileAppSnapshot.appLogoBgColor}
                            />
                            <MobilePDPPage
                                primaryColor={
                                    buildMobileAppSnapshot.appLogoBgColor
                                }
                                secondaryColor={
                                    buildMobileAppSnapshot.launchScreenBgColor
                                }
                            />
                        </InlineStack>
                    </Box>
                </Modal>
            </Box>
        </CollapsibleCardWithTitleSection>
    );
};

export default AppIconLaunchScreen;
