import {
    BlockStack,
    DataTable,
    SkeletonBodyText,
    TextField,
    Icon,
} from '@shopify/polaris';
import { CardWithTitleSection } from '../common';
import { useSnapshot } from 'valtio';
import { mobileAppConfigStore } from '@/stores';
import { useState, useEffect } from 'react';
import { useShopifyStorefront } from '@/hooks/useShopifyStorefront';
import { CheckCircleIcon } from '@shopify/polaris-icons';

export interface MenuItem {
    id: string;
    title: string;
    url: string;
    type: 'FRONTPAGE' | 'CATALOG' | 'PAGE' | 'COLLECTION';
    resourceId: string | null;
    resource: Resource | null;
}

export interface Resource {
    id: string;
    handle: string;
}

type NavigationMenuCardProps = {
    title: string;
    subtitle: string;
    type: 'MAIN_NAVIGATION' | 'SHOP_NAVIGATION' | 'CUSTOM_LINKS';
    hideStatusColumn?: boolean;
};

const NavigationMenuCard = ({
    title,
    subtitle,
    type,
    hideStatusColumn,
}: NavigationMenuCardProps) => {
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    const { getMenusByHandle } = useShopifyStorefront();
    const [menuHandle, setMenuHandle] = useState('');
    const [tableRows, setTableRows] = useState<string[][]>([]);
    const onMenuHandleChange = async (value: string) => {
        setMenuHandle(value);
    };

    const fetchMenuItems = async () => {
        const data: MenuItem[] = await getMenusByHandle(menuHandle);
        const tbData: string[][] =
            data &&
            data.map((item) => {
                return hideStatusColumn
                    ? [item.title]
                    : [
                          item.title,
                          item.type && item.type.toLowerCase() === 'collection'
                              ? 'Visible'
                              : 'Not Visible',
                      ]
            });
        setTableRows(tbData);

        if (type == 'MAIN_NAVIGATION') {
            mobileAppConfigStore.menuHandle = menuHandle;
        } else if (type == 'SHOP_NAVIGATION') {
            mobileAppConfigStore.shopMenuHandle = menuHandle;
        } else if (type == 'CUSTOM_LINKS') {
            mobileAppConfigStore.customLinksHandle = menuHandle;
        }
    };

    useEffect(() => {
        const search = setTimeout(() => {
            fetchMenuItems();
        }, 1000);
        return () => clearTimeout(search);
    }, [menuHandle]);

    useEffect(() => {
        if (type == 'MAIN_NAVIGATION') {
            setMenuHandle(mobileAppConfigStore.menuHandle);
        } else if (type == 'SHOP_NAVIGATION') {
            setMenuHandle(mobileAppConfigStore.shopMenuHandle);
        } else if (type == 'CUSTOM_LINKS') {
            setMenuHandle(mobileAppConfigStore.customLinksHandle);
        }
    }, [
        mobileAppConfigSnap.menuHandle,
        mobileAppConfigSnap.shopMenuHandle,
        mobileAppConfigSnap.customLinksHandle,
    ]);

    useEffect(() => {
        if (type == 'MAIN_NAVIGATION') {
            setMenuHandle(mobileAppConfigStore.menuHandle);
        } else if (type == 'SHOP_NAVIGATION') {
            setMenuHandle(mobileAppConfigStore.shopMenuHandle);
        } else if (type == 'CUSTOM_LINKS') {
            setMenuHandle(mobileAppConfigStore.customLinksHandle);
        }
    }, []);

    return (
        <CardWithTitleSection title={title} subtitle={subtitle}>
            {mobileAppConfigSnap.isLoading ? (
                <SkeletonBodyText />
            ) : (
                <BlockStack gap="800">
                    <TextField
                        value={menuHandle}
                        autoComplete="off"
                        label="Paste Navigation Handle"
                        placeholder="menu-handle"
                        maxLength={50}
                        onChange={onMenuHandleChange}
                        prefix={
                            tableRows && tableRows.length > 0 ? (
                                <Icon source={CheckCircleIcon} />
                            ) : null
                        }
                    />
                    {tableRows && tableRows.length > 0 && (
                        <DataTable
                            columnContentTypes={hideStatusColumn ? ['text'] : ['text', 'numeric']}
                            headings={hideStatusColumn ? ['Menu items'] : ['Menu items', 'Status']}
                            firstColumnMinWidth={hideStatusColumn ? "100%" : "80%"}
                            hoverable={false}
                            hasZebraStripingOnData={true}
                            rows={tableRows}
                            totals={[]}
                        />
                    )}
                </BlockStack>
            )}
        </CardWithTitleSection>
    );
};

export default NavigationMenuCard;