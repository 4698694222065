import { billingStore } from "@/stores";
import { shopifyCurrencyFormatter } from "@/utils/MoneyFormatter";
import { BlockStack, Divider, InlineStack } from "@shopify/polaris";
import { useEffect, useState } from "react";

type DataToRender = {
    planName: string;
    combinedPlanNames: string;
    planPrice: string;
    includedAddons: any[];
    extraAddons: any[];
    subTotalAmt: string;
    discountAmt: string;
    discountPercentage: string;
    totalAmt: string;
};

const featureNameMap: Record<string, string> = {
    popclips: 'Popclips',
    mobile: 'Mobile App',
    live: 'Live Selling',
};

export default function CurrentPlanCard({ subscriptionData }: any) {
    const [dataToRender, setDataToRender] = useState<DataToRender>();

    useEffect(() => {
        getDataToRender();
    }, [subscriptionData]);
    
    const getDataToRender = () => {
        const subData: any = subscriptionData;
        if (!subData && subData.lineItems) return;

        const plan = subData.lineItems.filter(
            (item: any) => item.type === 'plan',
        )[0].item;
        const includedAddons = plan.addons && plan.addons.length > 0 ? billingStore.getFormattedAddonsForCart(plan.addons) : [];
        const extraAddons = subData.lineItems
            .filter((item: any) => item.type === 'addon')
            .map((row: any) => row.item);
        const formattedExtraAddons =
            extraAddons.length > 0
                ? billingStore.getFormattedAddonsForCart(extraAddons)
                : [];

        let combinedPlanNames = [...includedAddons, ...formattedExtraAddons]
            .map((addon: any) => featureNameMap[addon.type])
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(' + ');

        let name = plan.name;
        let planPrice = shopifyCurrencyFormatter(
            plan.price[0].amount,
            plan.price[0].currencyCode,
        );
        const subtotalAmt = shopifyCurrencyFormatter(
            subData.subscriptionSubtotal.amount,
            subData.subscriptionSubtotal.currencyCode,
        );
        const totalAmt = shopifyCurrencyFormatter(
            subData.subscriptionTotal.amount,
            subData.subscriptionTotal.currencyCode,
        );

        const discountAmt = subData.subscriptionDiscount
            ? shopifyCurrencyFormatter(
                  subData.subscriptionDiscount.amount,
                  subData.subscriptionDiscount.currencyCode,
              )
            : 'N/A';

        setDataToRender({
            planName: name,
            combinedPlanNames,
            planPrice: `${planPrice}`,
            includedAddons,
            extraAddons: formattedExtraAddons,
            subTotalAmt: `${subtotalAmt}`,
            discountAmt: discountAmt,
            discountPercentage: subData.subscriptionDiscountPercentage ? (subData.subscriptionDiscountPercentage * 100).toString() : "",
            totalAmt: `${totalAmt}`,
        });
    };
    
    return (
        <div className="subscription-page__current-plan-card">
            <BlockStack gap="600">
                <p className="badge">Current Plan Details</p>
                <InlineStack align="space-between" blockAlign="center">
                    <BlockStack>
                        <p className="plan-name">{dataToRender?.planName}</p>
                        <p className="plans-combined-name">
                            {dataToRender?.combinedPlanNames}
                        </p>
                    </BlockStack>
                    <p className="plan-total-price">{dataToRender?.totalAmt}</p>
                </InlineStack>
                <Divider />
                <BlockStack gap="300">
                    <InlineStack align="space-between">
                        <p className="plan-breakup-label">Subtotal</p>
                        <p className="plan-breakup-amount">{dataToRender?.subTotalAmt}</p>
                    </InlineStack>
                    <InlineStack align="space-between">
                        <p className="plan-breakup-label">Discount {dataToRender?.discountPercentage && `( ${dataToRender?.discountPercentage}% )`} </p>
                        <p className="plan-breakup-amount">{dataToRender?.discountAmt}</p>
                    </InlineStack>
                </BlockStack>
                <Divider />
                <InlineStack align="space-between">
                    <p className="plan-breakup-label">Total</p>
                    <p className="plan-breakup-amount">{dataToRender?.totalAmt}</p>
                </InlineStack>
            </BlockStack>
        </div>
    );
}