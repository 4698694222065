import { useEffect, useState } from 'react';
import {
    BlockStack,
    Button,
    Icon,
    InlineStack,
    Link,
    Spinner,
    Text,
} from '@shopify/polaris';
import { ArrowRightIcon } from '@shopify/polaris-icons';
import { billingStore, shopStore } from '@/stores';
import { ATTRIBUTE_IDS } from '@/constants/planFeatureCard.constants';
import { BaseLayout } from './Layout/BaseLayout';
import { useNavigate } from 'react-router-dom';
import { vfsRoutes } from '@/constants/routes.constants';
import { useSnapshot } from 'valtio';
import AddonStyledComponent from '../Components/PlanSelection/AddonStyledComponent';
import PlanDiscountInput from '../Components/PlanSelection/PlanDiscountInput';
import '../../assets/css/SelectPopclipsAddonPage.css';
import PlanConfirmationModal from '../Components/SubscriptionPage/PlanConfirmationModal';

const featureType = 'popclips';

export default function SelectPopclipsAddonPage() {
    const navigate = useNavigate();
    const [selectedPlan, setSelectedPlan] = useState('');
    const [shouldShowDiscountInput, setShouldShowDiscountInput] = useState(false);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { shouldShowFeatureCards } = useSnapshot(billingStore);

    const addons = billingStore.getAddonsByModule(featureType, ATTRIBUTE_IDS[featureType]);
    const addonsPlansValues =
        addons && addons.length > 0
            ? billingStore.getAddonButtonGroupValues(addons, ATTRIBUTE_IDS[featureType])
            : [];

    useEffect(() => {
        billingStore.selectedAddonsIds = [];
        billingStore.removeAppliedDiscountCode();
    }, []);

    const handleAddonSelection = (addon: any) => {
        billingStore.selectedAddonsIds = !billingStore.isAddonAlreadyInBasePlan(addon.skuCode) ? [addon.id] : [];
        setSelectedPlan(addon.name.toLowerCase());
    }

    const onCheckOutClicked = async () => {
        try {
            setIsApiLoading(true);
            const response = await billingStore.getPlanSubscription();
            setIsApiLoading(false);
            response && setShowConfirmationModal(true);
        } catch (error) {
            setIsApiLoading(false);
        }
    };

    const onConfirmCheckout = async () => {
        setIsApiLoading(true);
        const response = await billingStore.getPlanPurchaseUrl();

        if (response) {
            setIsApiLoading(false);
            if (response.confirmationUrl) {
                open(decodeURIComponent(response.confirmationUrl), '_top');
            } else if (response.subscription.isStubSubscription) {
                await shopStore.getShop();
                navigate(vfsRoutes.landing);
            }
        } else {
            setIsApiLoading(false);
            shopify.toast.show('Failed to checkout. Please contact support.', {
                isError: true,
                duration: 5000,
            });
        }
    }

    return (
        <BaseLayout
            title="Select Plan"
            subtitle={
                <InlineStack gap="200">
                    <Text as="p" variant="bodyMd">
                        Questions?
                    </Text>
                    <div className="schedule-free-onboarding-call">
                        <Link
                            url="https://calendly.com/videeo/initial-videeo-onboarding"
                            removeUnderline
                            target="_blank"
                        >
                            <InlineStack gap="100" blockAlign="center">
                                <Text
                                    as="span"
                                    fontWeight="semibold"
                                    tone="magic"
                                >
                                    Schedule a free call
                                </Text>
                                <Icon source={ArrowRightIcon} tone="magic" />
                            </InlineStack>
                        </Link>
                    </div>
                </InlineStack>
            }
            headerAction={
                <div>
                    <InlineStack blockAlign="center" gap="600">
                        <PlanDiscountInput
                            shouldShowDiscountInput={shouldShowDiscountInput}
                            toggleDiscountInput={(value) =>
                                setShouldShowDiscountInput(value)
                            }
                            isDisabled={isApiLoading}
                        />
                        <InlineStack blockAlign="end" gap="200">
                            {isApiLoading && <Spinner size="small" />}
                            <Button
                                variant="primary"
                                size="large"
                                onClick={onCheckOutClicked}
                                disabled={
                                    !shouldShowFeatureCards ||
                                    !selectedPlan ||
                                    isApiLoading
                                }
                            >
                                Continue
                            </Button>
                        </InlineStack>
                    </InlineStack>
                </div>
            }
            bodyIsLoading={!shouldShowFeatureCards}
        >
            <BlockStack gap="800">
                <div className="content card-highlight-container">
                    <div
                        style={{ display: 'flex', gap: '2rem', width: '90%' }}
                    >
                        {addons.map((addon, i) => (
                            <AddonStyledComponent
                                key={addon.id}
                                addonData={addon}
                                isDisabled={isApiLoading}
                                addonValue={addonsPlansValues[i].value}
                                type={addon.name.toLowerCase()}
                                selectedPlan={selectedPlan}
                                onSelectPlan={() => handleAddonSelection(addon)}
                            />
                        ))}
                    </div>
                </div>
            </BlockStack>
            {showConfirmationModal && (
                <PlanConfirmationModal
                    onConfirmCheckout={onConfirmCheckout}
                    hideModal={() => setShowConfirmationModal(false)}
                />
            )}
        </BaseLayout>
    );
}