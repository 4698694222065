import { installEmbedStore, shopStore } from "@/stores";
import { openEmbedWindow } from "@/utils/helpers";
import { BlockStack, Box, Icon, InlineStack, Link, Text } from "@shopify/polaris";
import { ArrowDiagonalIcon, CaretDownIcon, CaretUpIcon, CheckCircleIcon, ClockIcon } from "@shopify/polaris-icons";
import { useRef } from "react";
import { useSnapshot } from "valtio";

type WizardStepProps = {
    stepExpanded: boolean;
    isCompleted: boolean;
};

export default function WizardStep1({
    stepExpanded,
    isCompleted,
}: WizardStepProps) {
    const { embedEnabled, getStartedStatus } = useSnapshot(shopStore);
    const { isInstallingEmbed, isCreatingLiveShoppingPage } = useSnapshot(installEmbedStore);

    const themeWindowRef = useRef<Window | null>();
    const verifyTriesRef = useRef(0);

    const enableVideeoSDK = async () => {
        installEmbedStore.isCreatingLiveShoppingPage = true;
        await shopStore.createLiveShoppingPage();
        installEmbedStore.isCreatingLiveShoppingPage = false;

        handleOpenEmbedWindow();
    };
    
    const handleOpenEmbedWindow = () => {
        installEmbedStore.isInstallingEmbed = true;
        openEmbedWindow(themeWindowRef, shopStore.appEmbedEnableUrl);
        verifyInstall();
    };

    const verifyInstall = async () => {
        verifyTriesRef.current += 1;
        const isEmbedEnabled = await shopStore.isEmbedEnabled();

        if (isEmbedEnabled) {
            installEmbedStore.isInstallingEmbed = false;
            installEmbedStore.isLivePlayerInstallCardOpen = true;

            if (!getStartedStatus.events.STEP_ENABLE_VIDEEO_SDK) {
                await shopStore.updateGetStartedStatus(
                    'STEP_ENABLE_VIDEEO_SDK',
                );
            }
            return;
        } else {
            if (
                verifyTriesRef.current <= 12 &&
                !themeWindowRef.current?.closed
            ) {
                setTimeout(verifyInstall, 5000);
                return;
            }
        }

        shopify.toast.show('Failed to verify installation. Please contact support.', {
            duration: 5000,
            isError: true,
        });

        installEmbedStore.isInstallingEmbed = false;
    };
    
    return (
        <Box
            background={!isCompleted && stepExpanded ? 'bg-fill-active' : 'bg-fill'}
            padding="300"
            borderRadius="200"
        >
            <InlineStack align="space-between">
                <InlineStack gap="300">
                    <Icon source={CheckCircleIcon} />
                    <Text as="p" variant="bodyLg">
                        Enable Videeo SDK for Popclips
                    </Text>
                </InlineStack>
                {!isCompleted && (
                    <InlineStack gap="500">
                        <InlineStack gap="100">
                            <Icon source={ClockIcon} />
                            <p>1 Mins</p>
                        </InlineStack>
                        <Icon
                            source={stepExpanded ? CaretUpIcon : CaretDownIcon}
                        />
                    </InlineStack>
                )}
            </InlineStack>
            {!isCompleted && stepExpanded && (
                <Box paddingBlockStart="400">
                    <BlockStack gap="400">
                        <iframe
                            style={{ width: '100%', height: '320px' }}
                            src="https://www.youtube.com/embed/-UlZV8h3peM?si=YldGQTIXKbFSBeU_"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        ></iframe>
                        <Text as="p" tone="subdued">
                            To display Popclips on your website, you must first
                            activate the Videeo SDK Embed.
                        </Text>
                        <Text as="p" tone="subdued">
                            In the new window, click on the 'Save' button at the
                            top right corner to activate the Popclip miniplayer.
                        </Text>
                        <Box
                            background="bg-fill"
                            padding="200"
                            borderRadius="200"
                        >
                            <Text as="p">
                                'Review and Save' button should open a new
                                window. If it does not open a new window, please{' '}
                                <Link
                                    url="https://help.videeo.live/hc/en-us/articles/23965040821140"
                                    target="_blank"
                                >
                                    <Text
                                        as="span"
                                        fontWeight="bold"
                                        tone="magic"
                                    >
                                        manually activate
                                    </Text>
                                </Link>
                            </Text>
                        </Box>
                        <InlineStack align="start">
                            <button
                                onClick={enableVideeoSDK}
                                disabled={
                                    isCreatingLiveShoppingPage ||
                                    isInstallingEmbed ||
                                    embedEnabled
                                }
                                className="onboarding-wizard-modal__step-cta"
                            >
                                Review and Save{' '}
                                <Icon source={ArrowDiagonalIcon} />
                            </button>
                        </InlineStack>
                    </BlockStack>
                </Box>
            )}
        </Box>
    );
}