import {
    BlockStack,
    Box,
    Button,
    Card,
    InlineStack,
    Text,
    Link,
    Divider,
    Icon,
    InlineGrid,
} from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useSnapshot } from 'valtio';
import shopStore from '@/stores/shopStore';
import { vfsRoutes } from '@/constants/routes.constants';
import {
    ChatIcon,
    ImagesIcon,
    PlayIcon,
    StarIcon,
    StatusActiveIcon,
} from '@shopify/polaris-icons';
import { liveStreamSelling } from '../../assets/images';

const LaunchVideeoDashboardCard = () => {
    const LEARN_MORE_LINK = 'https://help.videeo.live/hc/en-us/categories/17326415181076';
    const navigate = useNavigate();
    const { dashboardUrl, enabledFeatures } = useSnapshot(shopStore);

    const handleClickGetStarted = useCallback(async () => {
        if (!shopStore.enabledFeatures.includes('live')) return;
        open(`${shopStore.dashboardUrl}`, '_blank');
    }, [dashboardUrl]);

    return (
        <Card padding="800" roundedAbove="sm">
            <Box width="50rem">
                <InlineGrid columns={['twoThirds', 'oneThird']}>
                    <Box paddingBlockStart="300">
                        <BlockStack gap="500" align="start">
                            <Text variant="headingXl" as="h4">
                                Start Live Selling
                            </Text>
                            <BlockStack gap="400">
                                <InlineStack gap="100" wrap={false}>
                                    <Box>
                                        <Icon
                                            source={StatusActiveIcon}
                                            tone="base"
                                        />
                                    </Box>
                                    <Text as="p">
                                        Access America's #1 live selling
                                        platform & support team
                                    </Text>
                                </InlineStack>
                                <InlineStack gap="100" wrap={false}>
                                    <Box>
                                        <Icon source={PlayIcon} tone="base" />
                                    </Box>
                                    <Text as="p">
                                        Stream live sales and events on your
                                        site, app and social media
                                    </Text>
                                </InlineStack>
                                <InlineStack gap="100" wrap={false}>
                                    <Box>
                                        <Icon source={ChatIcon} tone="base" />
                                    </Box>
                                    <Text as="p">
                                        Invite guests on-camera to your live
                                        events
                                    </Text>
                                </InlineStack>
                                <InlineStack gap="100" wrap={false}>
                                    <Box>
                                        <Icon source={ImagesIcon} tone="base" />
                                    </Box>
                                    <Text as="p">
                                        Picture-in-Picture video player across
                                        your site when you go live
                                    </Text>
                                </InlineStack>
                                <InlineStack gap="100" wrap={false}>
                                    <Box>
                                        <Icon source={StarIcon} tone="base" />
                                    </Box>
                                    <Text as="p">
                                        Automatically feature recorded replays
                                        of your live shows on your site and app
                                    </Text>
                                </InlineStack>
                            </BlockStack>
                        </BlockStack>
                    </Box>
                    <BlockStack align="center" inlineAlign="center">
                        <Box>
                            <img
                                style={{
                                    width: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                }}
                                src={liveStreamSelling}
                                alt="Live Dashboard Thumbnail"
                            />
                        </Box>
                    </BlockStack>
                </InlineGrid>
                <Box paddingBlock="400">
                    <Divider />
                </Box>
                <InlineStack align="end" gap="400" blockAlign="center">
                    <Link url={LEARN_MORE_LINK}>Learn More</Link>
                    {enabledFeatures.includes('live') ? (
                        <Button
                            variant="primary"
                            accessibilityLabel="Get Started"
                            onClick={handleClickGetStarted}
                        >
                            Go Live
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() => navigate(vfsRoutes.managePlans)}
                        >
                            Get Started
                        </Button>
                    )}
                </InlineStack>
            </Box>
        </Card>
    );
};

export default LaunchVideeoDashboardCard;
