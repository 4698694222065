import { BlockStack, Box, Button, Link, Text } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import UploadVideo from '/assets/images/uploadVideo.png';
import { popclipsRoutes } from '@/constants/routes.constants';
import { videoLiveSellingStore } from '@/stores';

const PopClipsGridEmptyState = () => {
    const navigate = useNavigate();

    return (
        <Box paddingBlock="800">
            <BlockStack inlineAlign="center" gap="400">
                <Box>
                    <img
                        alt=""
                        width="180px"
                        height="180px"
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                            borderRadius: '128px',
                            backgroundColor: '#CCCCCC80',
                        }}
                        src={UploadVideo}
                    />
                </Box>
                <Text as="h1" variant="headingMd" fontWeight="bold">
                    Ready to make more sales?
                </Text>
                <Text as="h1" variant="bodyMd">
                    Get started by creating your first Shoppable Video now.
                </Text>
                <Box paddingBlockEnd="100">
                    <Button
                        variant="primary"
                        onClick={() => {
                            videoLiveSellingStore.resetCreatePopClip();
                            navigate(popclipsRoutes.create);
                        }}
                    >
                        Create
                    </Button>
                </Box>
                <Link url="https://help.videeo.live/hc/en-us/articles/24540975607700-Create-a-Popclip-Shoppable-Video">
                    How to create a Popclip
                </Link>
            </BlockStack>
        </Box>
    );
};

export default PopClipsGridEmptyState;
