import PopClipsCard from '@/components/common/PopClipsCard';
import { popclipsRoutes } from '@/constants/routes.constants';
import { shopStore } from '@/stores';
import {
    BlockStack,
    Box,
    Checkbox,
    Divider,
    InlineStack,
    Spinner,
    Text,
} from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { BaseLayout } from '../../v2/pages/Layout/BaseLayout';

type PopclipsSettings = {
    hidePopClipsOnPDP: boolean;
    showViewsOnPopClips: boolean;
    showLikesOnPopClips: boolean;
    enableShopFeaturesBuyBtn: boolean;
};

let isFirstLoad = true;
let previousSettingsCache = {} as PopclipsSettings;

const PopClipSettingsPage = () => {
    const [apiLoading, setApiLoading] = useState(false);
    const [shouldShowViewToggle, setShouldShowViewToggle] = useState(false);
    const [shouldShowLikeToggle, setShouldShowLikeToggle] = useState(false);
    const [settings, setSettings] = useState({
        hidePopClipsOnPDP: false,
        showViewsOnPopClips: false,
        showLikesOnPopClips: false,
        enableShopFeaturesBuyBtn: false,
    });

    const saveSettings = async () => {
        shopify.saveBar.hide('shopify-save-bar');
        setApiLoading(true);

        await fetch('/api/shopify/shop/popclips/settings', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(settings),
        });

        previousSettingsCache = settings;

        shopify.toast.show('Settings saved successfully.', {
            isError: false,
            duration: 5000,
        });

        setApiLoading(false);
    };

    useEffect(() => {
        (async () => {
            setApiLoading(true);
            const res = await fetch('/api/shopify/shop/popclips/settings');
            const result = await res.json();
            setApiLoading(false);

            if (result && result.data) {
                const { shopifyDomain, ...rest } = result.data;
                previousSettingsCache = rest;
                setSettings(rest);

                setTimeout(() => (isFirstLoad = false), 300);
            }
        })();
    }, []);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            'popclips',
            true,
        );
        const maxPrice = Math.max(...addonPrices);

        setShouldShowViewToggle(maxPrice >= 29.99);
        setShouldShowLikeToggle(maxPrice >= 149.99);
    }, [shopStore.subscription]);

    const discardUnsavedChanges = () => {
        setSettings(previousSettingsCache);
        shopify.saveBar.hide('shopify-save-bar');
    };

    useEffect(() => {
        if (isFirstLoad) return;
        let valueIsChanged = 0;

        if (
            settings.hidePopClipsOnPDP !==
            previousSettingsCache.hidePopClipsOnPDP
        )
            valueIsChanged++;
        if (
            settings.showViewsOnPopClips !==
            previousSettingsCache.showViewsOnPopClips
        )
            valueIsChanged++;
        if (
            settings.showLikesOnPopClips !==
            previousSettingsCache.showLikesOnPopClips
        )
            valueIsChanged++;
        if (
            settings.enableShopFeaturesBuyBtn !==
            previousSettingsCache.enableShopFeaturesBuyBtn
        )
            valueIsChanged++;

        if (valueIsChanged > 0) {
            shopify.saveBar.show('shopify-save-bar');
        } else {
            shopify.saveBar.hide('shopify-save-bar');
        }
    }, [settings]);

    return (
        <BaseLayout
            title="Popclips Settings & Configurations"
            backToLink={
                !shopStore.getStartedStatus.events.STEP_ADD_YOUR_FIRST_POPCLIP
                    ? popclipsRoutes.create
                    : popclipsRoutes.list
            }
            saveBarPrimaryAction={saveSettings}
            saveBarSecondaryAction={discardUnsavedChanges}
        >
            <PopClipsCard>
                <Box padding="400">
                    <BlockStack gap="400">
                        <Box paddingBlockEnd="400">
                            <BlockStack gap="200">
                                <Text as="p" variant="headingMd">
                                    Mange how your Popclips will be displayed to
                                    your customers.
                                </Text>
                                <Text as="p" variant="bodyMd">
                                    Configure how your Popclips will appear to
                                    your customers.
                                </Text>
                            </BlockStack>
                        </Box>
                        <BlockStack gap="300">
                            <InlineStack align="space-between">
                                <Text as="p" variant="bodyMd">
                                    Hide Popclips miniplayer on all product
                                    Detail Pages
                                </Text>
                                <div>
                                    {apiLoading ? (
                                        <Spinner size="small" />
                                    ) : (
                                        <Checkbox
                                            label=""
                                            disabled={apiLoading}
                                            checked={settings.hidePopClipsOnPDP}
                                            onChange={(checked) => {
                                                setSettings({
                                                    ...settings,
                                                    ...{
                                                        hidePopClipsOnPDP:
                                                            checked,
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                            </InlineStack>
                            <Divider />
                        </BlockStack>
                        {shouldShowViewToggle && (
                            <BlockStack gap="300">
                                <InlineStack align="space-between">
                                    <Text as="p" variant="bodyMd">
                                        Show number of views on your Popclips
                                    </Text>
                                    <div>
                                        {apiLoading ? (
                                            <Spinner size="small" />
                                        ) : (
                                            <Checkbox
                                                label=""
                                                disabled={apiLoading}
                                                checked={
                                                    settings.showViewsOnPopClips
                                                }
                                                onChange={(checked) => {
                                                    setSettings({
                                                        ...settings,
                                                        ...{
                                                            showViewsOnPopClips:
                                                                checked,
                                                        },
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </InlineStack>
                                <Divider />
                            </BlockStack>
                        )}
                        {shouldShowLikeToggle && (
                            <BlockStack gap="300">
                                <InlineStack align="space-between">
                                    <Text as="p" variant="bodyMd">
                                        Show number of likes on your Popclips
                                    </Text>
                                    <div>
                                        {apiLoading ? (
                                            <Spinner size="small" />
                                        ) : (
                                            <Checkbox
                                                label=""
                                                disabled={apiLoading}
                                                checked={
                                                    settings.showLikesOnPopClips
                                                }
                                                onChange={(checked) => {
                                                    setSettings({
                                                        ...settings,
                                                        ...{
                                                            showLikesOnPopClips:
                                                                checked,
                                                        },
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </InlineStack>
                                <Divider />
                            </BlockStack>
                        )}
                        <Box>
                            <InlineStack align="space-between">
                                <Text as="p" variant="bodyMd">
                                    Enable shop feature on your Popclips feed on
                                    your website (Buy button with checkout
                                    experience directly from the website
                                    Popclips feed)
                                </Text>
                                <div>
                                    {apiLoading ? (
                                        <Spinner size="small" />
                                    ) : (
                                        <Checkbox
                                            label=""
                                            disabled={apiLoading}
                                            checked={
                                                settings.enableShopFeaturesBuyBtn
                                            }
                                            onChange={(checked) => {
                                                setSettings({
                                                    ...settings,
                                                    ...{
                                                        enableShopFeaturesBuyBtn:
                                                            checked,
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                            </InlineStack>
                        </Box>
                    </BlockStack>
                </Box>
            </PopClipsCard>
        </BaseLayout>
    );
};

export default PopClipSettingsPage;
