const LAYOUT={
    xaxis: {
        tickangle: 0, // Rotates x-axis labels
        automargin: true, // Adjusts margins for long labels
    },
    yaxis: {
        visible: false, // Hides the y-axis
        showgrid: false, // Hides gridlines
    },
    showlegend: false,
    autosize: true,
    height: 120,
    margin: { t: 10, l: 10, r: 10, b: 20 }, // Minimal margins
    plot_bgcolor: 'rgba(0,0,0,0)', // Transparent background for gradient
    dragMode: false,
}

const CONFIG={
    responsive: true, // Makes the chart responsive
    displayModeBar: true, // Hides the mode bar for a cleaner UI
    "displaylogo": false,
    staticPlot: true,
}

const LINE_SMOOTHING_VALUE = 0.4

export {
    LAYOUT,
    CONFIG,
    LINE_SMOOTHING_VALUE,
}