import { PropsWithChildren, ReactNode } from 'react';
import { Card, InlineStack, Text, BlockStack, Box, Button } from '@shopify/polaris';

export interface CardWithTitleSectionProps {
    title: string;
    subtitle: string | ReactNode;
    showBtn?: boolean;
    btnLabel?: string;
    btnAction?: () => void;
}

const CardWithTitleSection = ({
    title,
    subtitle,
    showBtn,
    btnLabel,
    btnAction,
    children
}: PropsWithChildren<CardWithTitleSectionProps>) => {
    return (
        <Card padding="800">
            <Box width="56rem">
                <BlockStack gap="600">
                    <InlineStack align='space-between' blockAlign='center'>
                        <BlockStack gap="200">
                            <Text as="h3" variant="headingSm">
                                {title}
                            </Text>
                            <Text as="h4" variant="bodySm">
                                {subtitle}
                            </Text>
                        </BlockStack>
                        {showBtn && <Button variant='primary' onClick={btnAction}>{btnLabel}</Button>}
                    </InlineStack>
                    {children}
                </BlockStack>
            </Box>
        </Card>
    );
};

export default CardWithTitleSection;
