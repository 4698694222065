import { Autocomplete, BlockStack, Icon, Text } from '@shopify/polaris';
import type { AutocompleteProps } from '@shopify/polaris/build/ts/src/components/Autocomplete/Autocomplete';
import { SearchIcon } from '@shopify/polaris-icons';
import { useState, useCallback, useMemo, useRef } from 'react';

type AutocompleteInputProps = {
    placeholderStr?: string;
    onChangeValue: (
        value: string,
    ) =>
        | PromiseLike<AutocompleteProps['options']>
        | AutocompleteProps['options'];
    defaultOptions?: AutocompleteProps['options'];
    onSelectOption?: (value: string) => void;
    debounceTimeout?: number;
    hideEmptyState?: boolean;
    shouldDisabled: boolean;
    valueWithSections?: boolean;
};

const AutocompleteInput = ({
    placeholderStr,
    onChangeValue,
    defaultOptions,
    onSelectOption,
    debounceTimeout,
    hideEmptyState,
    shouldDisabled,
    valueWithSections = false,
}: AutocompleteInputProps) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const deselectedOptions = useMemo(() => defaultOptions || [], []);
    const [options, setOptions] = useState(deselectedOptions);

    const timeoutRef = useRef(0);
    const updateText = useCallback(
        (value: string) => {
            setInputValue(value);

            if (!loading) {
                setLoading(true);
            }

            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = window.setTimeout(async () => {
                if (value === '' && deselectedOptions.length) {
                    setOptions(deselectedOptions);
                    setLoading(false);
                    return;
                }

                const resultOptions = await new Promise<
                    AutocompleteProps['options']
                >((resolve) => {
                    resolve(onChangeValue(value));
                });

                setOptions(resultOptions);
                setLoading(false);
            }, debounceTimeout || 1500);
        },
        [deselectedOptions, loading],
    );

    const updateSelection = useCallback(
        (selected: string[]) => {
            const selectedText = selected.map((selectedItem) => {
                const matchedOption = options.find((option) => {
                    return option.value.match(selectedItem);
                });
                return matchedOption && matchedOption.label;
            });
            setSelectedOptions(selected);
            if (onSelectOption) onSelectOption(selected[0]);
            setInputValue(selectedText[0] || '');
        },
        [options],
    );

    const updateSelectionWithSection = useCallback(
        (selected: string[]) => {
            let selectedValue: string | undefined;

            options.forEach(({ options }) => {
                if (selectedValue) return;

                const matchedOption = options.find((option) =>
                    option.value.match(selected[0]),
                );

                if (matchedOption) selectedValue = matchedOption.label;
            });

            setSelectedOptions(selected);
            setInputValue(selectedValue ? selectedValue : '');
            if (onSelectOption) onSelectOption(selected[0]);
        },
        [options],
    );

    const textField = (
        <Autocomplete.TextField
            onChange={updateText}
            label="Search"
            value={inputValue}
            prefix={<Icon source={SearchIcon} tone="base" />}
            placeholder={placeholderStr ? placeholderStr : 'Search'}
            autoComplete="off"
            labelHidden
            disabled={shouldDisabled}
        />
    );

    const emptyState = (
        <BlockStack align="center" inlineAlign="center">
            <Icon source={SearchIcon} />
            <Text as="p">Could not find any results</Text>
        </BlockStack>
    );

    return (
        <Autocomplete
            options={options}
            selected={selectedOptions}
            onSelect={valueWithSections ? updateSelectionWithSection : updateSelection}
            emptyState={hideEmptyState ? undefined : emptyState}
            loading={loading}
            textField={textField}
        />
    );
};

export default AutocompleteInput;
