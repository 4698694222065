const featureAttributes = {
    mobile: [
        'Look & Feel Customization',
        'Wishlist your favorite products',
        'Seamless checkout with ShopPay along with guest checkout',
        'Shoppable Video Clips & Live Streams on App',
        'TikTok-like Shoppable Feed',
        'Dedicated Support / Regular Updates with Security Patches',
    ],
    popclips: [
        'Automatic video placements on product page',
        'Social Media-like feed on your Shopify website and app',
        'Embed individual clips anywhere',
        'Display products on feed to drive more customer engagement',
        'Shoppable Video Carousel Widget',
        'Fast video uploads / hosting with unlimited storage for clips',
    ],
    live: [
        'Go live on your Shopify website, app and social media',
        'Invite Guests On-Camera Seamlessly',
        'Live event notifications on app',
        'Live scheduler & live calendar on website',
    ],
};

export const CARD_STRINGS = {
    mobile: {
        title: 'Shopify Mobile App',
        sliderLabel: '# Max App Downloads',
    },
    popclips: {
        title: 'Popclips: Shoppable Video Clips on Website & Mobile App',
        sliderLabel: '# of Video Views Included/mo. on Website and App',
    },
    live: {
        title: 'Web & Mobile App Live Selling at Zero SaaS',
        sliderLabel: '# Live Event(s)',
    },
};

export const MOBILE_ID_STR = 'mobile';
export const POPCLIPS_ID_STR = 'popclips';
export const LIVE_ID_STR = 'live';

export const PLAN_SLIDER_INFINITY = 9999999;

export const FEATURE_NAME: Record<string, string> = {
    mobile: 'Mobile App',
    popclips: 'Popclips',
    live: 'Live',
};

export const ATTRIBUTE_IDS: Record<string, string> = {
    mobile: 'mobile.appDownloads.max',
    popclips: 'popclips.monthlyViews.max',
    live: 'live.monthlyLiveEvents.max',
};

export const getFeatureAttributes = (
    type: 'mobile' | 'popclips' | 'live',
    append?: string,
    amt?: string,
) => {
    const attr = [...featureAttributes[type]];

    if (type == 'mobile' && amt == '79.99') {
        attr.splice(2, 0, 'iOS App');
    } else  if (type == 'mobile' && amt != '79.99') {
        attr.splice(2, 0, 'iOS & Android App');
    }

    if (type == 'live' && amt == '0.00') {
        attr.splice(
            2,
            0,
            'Recorded replays of your live shows (available for 2 months)',
        );
    } else if (type == 'live') {
        attr.splice(
            2,
            0,
            'Recorded replays of your live shows (available for 3 months)',
        );
    }

    if (append) attr.unshift(append);

    return attr;
};
