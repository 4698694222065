import { useCallback } from 'react';
import { videoLiveSellingStore } from '@/stores';
import type { ShopifyProductsFormated } from '@/stores/videoLiveSellingStore';
import { shopifyIdFromGid } from '@/utils/GidHelper';
import shopStore from '@/stores/shopStore';
const SHOPIFY_API_VERSION = '2024-01';

type ShopifyProductNode = {
    id: string;
    title: string;
    description: string;
    handle: string;
    createdAt?: string;
    priceRange: {
        minVariantPrice: {
            amount: string;
            currencyCode: string;
        };
    };
    images: {
        edges: {
            node: {
                originalSrc: string;
                src: string;
                altText: string;
            };
        }[];
    };
};

type SearchResponseType = {
    data: {
        search: {
            pageInfo: {
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
            edges: {
                cursor: string;
                node: ShopifyProductNode;
            }[];
        };
    };
};

type SearchSuggestionResponse = {
    data: {
        predictiveSearch: {
            queries: {
                text: string;
            }[];
        };
    };
};

type SearchProductCollectionResponse = {
    data: {
        search: {
            edges: {
                node: {
                    id: string;
                    title: string;
                    handle: string;
                    images: {
                        edges: {
                            node: {
                                src: string;
                            };
                        }[];
                    };
                };
            }[];
        };
        collections: {
            edges: {
                node: {
                    id: string;
                    title: string;
                    handle: string;
                };
            }[];
        };
    };
};

export type SearchProductCollection = {
    id: string;
    title: string;
    handle: string;
    imgSrc?: string;
};

type ResponseTypeByIds = {
    data: {
        nodes: ShopifyProductNode[];
    };
};

type ProductsByCollectionIdResponse = {
    data: {
        collection: {
            products: {
                edges: {
                    node: {
                        images: {
                            edges: {
                                node: {
                                    src: string;
                                };
                            }[];
                        };
                    };
                }[];
            };
        };
    };
};

const SHOPIFY_PRODUCTS_PER_PAGE = 10;

export const useGetShopifyProducts = () => {
    const getProducts = useCallback(
        async (
            searchParam?: string,
            nextCursor?: string,
            prevCursor?: string,
        ): Promise<ShopifyProductsFormated[] | []> => {
            const { shopHandle } = shopStore;
            if (!shopHandle) return [];

            try {
                const shopifyURL = `https://${shopStore.shopifyDomain}/api/${SHOPIFY_API_VERSION}/graphql.json`;
                const fetchAPIOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Shopify-Storefront-Access-Token':
                            shopStore.storefrontAccessToken,
                    },
                    body: JSON.stringify(
                        prepareSearchQuery(searchParam, nextCursor, prevCursor),
                    ),
                };

                const res = await fetch(shopifyURL, fetchAPIOptions);

                if (res.ok) {
                    let shopifyProducts: ShopifyProductsFormated[] = [];
                    const { data } = (await res.json()) as SearchResponseType;

                    if (data.search) {
                        const edges = data.search.edges.filter(
                            (edge) => edge.node !== null,
                        );

                        videoLiveSellingStore.shopifyProductPagination = {
                            ...data.search.pageInfo,
                            nextCursor: edges[edges.length - 1]?.cursor,
                            prevCursor: edges[0]?.cursor,
                        };

                        shopifyProducts = edges.map<ShopifyProductsFormated>(
                            ({ node }) => ({
                                id: shopifyIdFromGid(node.id) || '',
                                title: node.title,
                                description: node.description,
                                handle: node.handle,
                                createdAt: node.createdAt,
                                amount:
                                    node.priceRange.minVariantPrice.amount ||
                                    '0',
                                currencyCode:
                                    node.priceRange.minVariantPrice
                                        .currencyCode || '',
                                imgSrc: node.images.edges[0]
                                    ? `${node.images.edges[0]?.node.src}&height=78`
                                    : 'https://placehold.co/78x78',
                                imgAlt: node.images.edges[0]
                                    ? node.images.edges[0]?.node.altText
                                    : 'placeholder',
                            }),
                        );

                        shopifyProducts.sort((a, b) => {
                            const dateA = new Date(a.createdAt);
                            const dateB = new Date(b.createdAt);

                            return dateB - dateA;
                        });
                    }

                    return Promise.resolve(shopifyProducts);
                }

                throw new Error('Failed to get products');
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            }
        },
        [shopStore.shopHandle],
    );

    const getProductByIds = useCallback(
        async (ids: string[] = []): Promise<ShopifyProductsFormated[] | []> => {
            const { shopHandle } = shopStore;
            if (!shopHandle) return [];
            if (ids.length == 0) return [];

            try {
                const shopifyURL = `https://${shopStore.shopifyDomain}/api/${SHOPIFY_API_VERSION}/graphql.json`;
                const fetchAPIOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Shopify-Storefront-Access-Token':
                            shopStore.storefrontAccessToken,
                    },
                    body: JSON.stringify(prepareGetByIdQuery(ids)),
                };

                const res = await fetch(shopifyURL, fetchAPIOptions);

                if (res.ok) {
                    let shopifyProducts: ShopifyProductsFormated[] = [];
                    const { data } = (await res.json()) as ResponseTypeByIds;

                    if (data.nodes) {
                        const nodes = data.nodes.filter(
                            (node) => node !== null,
                        );
                        shopifyProducts = nodes.map<ShopifyProductsFormated>(
                            (prod) => ({
                                id: shopifyIdFromGid(prod.id) || '',
                                title: prod.title,
                                description: prod.description,
                                handle: prod.handle,
                                amount:
                                    prod.priceRange.minVariantPrice.amount ||
                                    '0',
                                currencyCode:
                                    prod.priceRange.minVariantPrice
                                        .currencyCode || '',
                                imgSrc: prod.images.edges[0]
                                    ? `${prod.images.edges[0]?.node.src}&height=78`
                                    : 'https://placehold.co/78x78',
                                imgAlt: prod.images.edges[0]
                                    ? prod.images.edges[0]?.node.altText
                                    : 'placeholder',
                            }),
                        );
                    }

                    return Promise.resolve(shopifyProducts);
                }

                throw new Error('Failed to get products');
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            }
        },
        [shopStore.shopHandle],
    );

    const getQuerySuggestions = useCallback(
        async (searchParam?: string): Promise<{ text: string }[] | []> => {
            const { shopHandle } = shopStore;
            if (!shopHandle) return [];

            try {
                const shopifyURL = `https://${shopStore.shopifyDomain}/api/${SHOPIFY_API_VERSION}/graphql.json`;
                const fetchAPIOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Shopify-Storefront-Access-Token':
                            shopStore.storefrontAccessToken,
                    },
                    body: JSON.stringify(prepareSuggestionQuery(searchParam)),
                };

                const res = await fetch(shopifyURL, fetchAPIOptions);

                if (res.ok) {
                    const { data } =
                        (await res.json()) as SearchSuggestionResponse;
                    return Promise.resolve(data.predictiveSearch.queries);
                }

                throw new Error('Failed to get products');
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            }
        },
        [shopStore.shopHandle],
    );

    const getProductsOrCollections = useCallback(
        async (
            searchParam?: string,
        ): Promise<{
            collections: SearchProductCollection[];
            products: SearchProductCollection[];
        }> => {
            const { shopifyDomain } = shopStore;
            if (!shopifyDomain)
                return {
                    collections: [],
                    products: [],
                };

            try {
                const shopifyURL = `https://${shopStore.shopifyDomain}/api/${SHOPIFY_API_VERSION}/graphql.json`;
                const fetchAPIOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Shopify-Storefront-Access-Token':
                            shopStore.storefrontAccessToken,
                    },
                    body: JSON.stringify(
                        prepareSearchProductOrCollection(searchParam),
                    ),
                };

                const res = await fetch(shopifyURL, fetchAPIOptions);

                if (res.ok) {
                    const { data } =
                        (await res.json()) as SearchProductCollectionResponse;

                    return Promise.resolve({
                        collections: data.collections.edges.map(({ node }) => ({
                            id: node.id,
                            title: node.title,
                            handle: node.handle,
                        })),
                        products: data.search.edges.map(({ node }) => ({
                            id: node.id,
                            title: node.title,
                            handle: node.handle,
                            imgSrc: node.images.edges[0]?.node.src,
                        })),
                    });
                }

                throw new Error('Failed to get products');
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            }
        },
        [shopStore.shopifyDomain],
    );

    const getProductsImagesByCollectionId = useCallback(
        async (collectionId: string): Promise<{ imgSrc: string }[]> => {
            const { shopifyDomain } = shopStore;
            if (!shopifyDomain) return [{ imgSrc: '' }];

            try {
                const shopifyURL = `https://${shopStore.shopifyDomain}/api/${SHOPIFY_API_VERSION}/graphql.json`;
                const fetchAPIOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Shopify-Storefront-Access-Token':
                            shopStore.storefrontAccessToken,
                    },
                    body: JSON.stringify(
                        prepareGetProductsImagesByCollectionId(collectionId),
                    ),
                };

                const res = await fetch(shopifyURL, fetchAPIOptions);

                if (res.ok) {
                    const { data } =
                        (await res.json()) as ProductsByCollectionIdResponse;

                    const productArr = data.collection.products.edges.map(
                        ({ node }) => ({
                            imgSrc: node.images.edges[0]?.node.src,
                        }),
                    );

                    return Promise.resolve(productArr);
                }

                throw new Error('Failed to get products');
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            }
        },
        [shopStore.shopifyDomain],
    );

    const getProductsByCollectionId = useCallback(
        async (collectionId: string): Promise<{ imgSrc: string }[]> => {
            const { shopifyDomain } = shopStore;
            if (!shopifyDomain) return [{ imgSrc: '' }];

            try {
                const shopifyURL = `https://${shopStore.shopifyDomain}/api/${SHOPIFY_API_VERSION}/graphql.json`;
                const fetchAPIOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Shopify-Storefront-Access-Token':
                            shopStore.storefrontAccessToken,
                    },
                    body: JSON.stringify(
                        prepareGetProductsByCollectionId(collectionId),
                    ),
                };

                const res = await fetch(shopifyURL, fetchAPIOptions);

                if (res.ok) {
                    const { data } =
                        (await res.json()) as ProductsByCollectionIdResponse;
                    
                    return Promise.resolve(data.collection);
                }

                throw new Error('Failed to get products');
            } catch (e) {
                console.error(e);
                return Promise.reject(e);
            }
        },
        [shopStore.shopifyDomain],
    );

    return {
        getProducts,
        getProductByIds,
        getQuerySuggestions,
        getProductsOrCollections,
        getProductsImagesByCollectionId,
        getProductsByCollectionId,
    };
};

const prepareSuggestionQuery = (searchParam?: string) => {
    const body: {
        query: string;
        variables?: { query: string };
    } = {
        query: `
            query searchProducts($query: String!) {
                predictiveSearch(query: $query, types: QUERY, limitScope: EACH) {
                    ... on PredictiveSearchResult {
                        queries {
                            text
                        }
                    }
                }
            }
        `,
    };

    body.variables = {
        query: searchParam ? `${searchParam}` : '',
    };

    return body;
};

const prepareSearchQuery = (
    searchParam?: string,
    nextCursor?: string,
    prevCursor?: string,
) => {
    const body: {
        query: string;
        variables?:
            | {
                  query: string;
              }
            | {
                  last: number;
                  before: string;
              }
            | {
                  first: number;
                  after: string;
              };
    } = {
        query: `
            query searchProducts($first: Int, $query: String!, $after: String, $before: String, $last: Int) {
                search(first: $first, query: $query, after: $after, before: $before, last: $last, types: PRODUCT, prefix: LAST) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                    }
                    edges {
                        cursor
                        node {
                            ... on Product {
                                id
                                handle
                                title
                                description
                                createdAt
                                tags
                                productType
                                images(first: 1) {
                                    edges {
                                        node {
                                            originalSrc
                                            src
                                            altText
                                        }
                                    }
                                }
                                priceRange {
                                    minVariantPrice {
                                        amount
                                        currencyCode
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
    };

    body.variables = {
        query: searchParam ? `${searchParam}` : '',
    };

    if (nextCursor) {
        body.variables = {
            ...body.variables,
            ...{
                first: SHOPIFY_PRODUCTS_PER_PAGE,
                after: nextCursor,
            },
        };
    }

    if (prevCursor) {
        body.variables = {
            ...body.variables,
            ...{
                last: SHOPIFY_PRODUCTS_PER_PAGE,
                before: prevCursor,
            },
        };
    }

    if (!prevCursor && !nextCursor) {
        body.variables = {
            ...body.variables,
            ...{
                first: SHOPIFY_PRODUCTS_PER_PAGE,
            },
        };
    }

    return body;
};

const prepareGetByIdQuery = (productIds: string[]) => {
    const body: {
        query: string;
        variables?: {
            ids: string[];
        };
    } = {
        query: `
            query getProductsByIds($ids: [ID!]!) {
                nodes(ids: $ids) {
                    ... on Product {
                        id
                        handle
                        title
                        description
                        tags
                        productType
                        images(first: 1) {
                            edges {
                                node {
                                    originalSrc
                                    src
                                    altText
                                }
                            }
                        }
                        priceRange {
                            minVariantPrice {
                                amount
                                currencyCode
                            }
                        }
                    }
                }
            }
        `,
    };

    body.variables = {
        ids: productIds,
    };

    return body;
};

const prepareSearchProductOrCollection = (searchParam?: string) => {
    const body: {
        query: string;
        variables?: {
            query: string;
        };
    } = {
        query: `
            query searchProducts($query: String!) {
                search(query: $query, types: PRODUCT, first: 10) {
                    edges {
                        node {
                            ... on Product {
                                id
                                title
                                handle
                                images(first: 1) {
                                    edges {
                                        node {
                                            src
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                collections(query: $query, first: 10) {
                    edges {
                        node {
                            id
                            title
                            handle
                        }
                    }
                }
            }
        `,
    };

    body.variables = {
        query: searchParam ? `${searchParam}` : '',
    };

    return body;
};

const prepareGetProductsImagesByCollectionId = (collectionId: string) => {
    const body: {
        query: string;
        variables?: {
            id: string;
        };
    } = {
        query: `
            query getCollectionById($id: ID!) {
                collection(id: $id) {
                    id
                    products(first:5) {
                        edges{
                            node {
                                images(first: 1) {
                                    edges {
                                        node {
                                            src
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`,
    };

    body.variables = {
        id: collectionId,
    };

    return body;
};

const prepareGetProductsByCollectionId = (collectionId: string) => {
    const body: {
        query: string;
        variables?: {
            id: string;
        };
    } = {
        query: `
            query getCollectionById($id: ID!) {
                collection(id: $id) {
                    id
                    title
                    image {
                        id
                        originalSrc
                    }
                    products(first:250) {
                        edges{
                            node {
                                id
                                createdAt
                                images(first: 1) {
                                    edges {
                                        node {
                                            src
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }`,
    };

    body.variables = {
        id: collectionId,
    };

    return body;
};
