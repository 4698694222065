import { shopStore } from "@/stores";
import AlertBannerComponent from "../AlertBannerComponent";
import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";
import { vfsRoutes } from "@/constants/routes.constants";

export default function AccessDeniedBanner() {
    const { activePlan } = useSnapshot(shopStore);
    const navigate = useNavigate();

    return (
        <AlertBannerComponent
            type="warning"
            title="Access Denied"
            message={`You don't have permission to view this page, reach out to support with your plan code -{' '} ${
                activePlan?.skuCode ?? 'bundle-legacy-unknown'
            }`}
            size="large"
            buttonText="Go Back"
            primaryAction={() => navigate(vfsRoutes.landing)}
        />
    );
}