import { Banner, BlockStack, Box, Text } from '@shopify/polaris';
import { NavigationMenuCard } from '@/components/mobile-app-configuration';
import { buildMobileAppStore, mobileAppConfigStore } from '@/stores';
import { useEffect, useState } from 'react';
import { useGetAppConfig } from '@/hooks/useGetAppConfig';
import AppBuilderBaseLayout from '@/components/layout/AppBuilderBaseLayout';
import { useSnapshot } from 'valtio';
import { useLocation } from 'react-router-dom';
import { mobileRoutes } from '@/constants/routes.constants';

const MobileAppCustomMenuPage = () => {
    const { getAppConfig } = useGetAppConfig();
    const { pathname } = useLocation();
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });
    const { isAppRequestSubmitted } = buildMobileAppSnapshot;
    const [selected, setSelected] = useState<number>(0);
    const [isAppApprovePending, setIsAppApprovePending] = useState(false);

    useEffect(() => {
        mobileAppConfigStore.showNotificationErrorLabel = false;
        mobileAppConfigStore.showFormErrorLabel = false;
        mobileAppConfigStore.isLoading = true;
        getAppConfig();
        if (pathname.includes(mobileRoutes.custom_menu)) {
            setSelected(2);
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setIsAppApprovePending(!isAppRequestSubmitted);
    },[isAppRequestSubmitted])

    return (
        <AppBuilderBaseLayout
            title="Videeo Mobile App Management"
            buttonLabel=""
            buttonDisabled={mobileAppConfigSnap.isLoading}
            selectedTabId={selected}
        >
            <BlockStack gap="1000">
                <BlockStack inlineAlign="center" gap="1000">
                    {!mobileAppConfigSnap.isLoading && isAppApprovePending && (
                        <Box width="60rem">
                            <Banner title="Setup Not Completed" tone="info">
                                <Box paddingInline="500">
                                    <Text as="p">
                                        Please complete mobile app setup from
                                        setup tab to save the changes.
                                    </Text>
                                </Box>
                            </Banner>
                        </Box>
                    )}
                    <NavigationMenuCard
                        title="Navigation Menu"
                        subtitle="Create custom collection navigation by editing the main menu section on your Shopify account."
                        type="MAIN_NAVIGATION"
                    />
                    <NavigationMenuCard
                        title="Shop Tab Menu"
                        subtitle="Create custom collection menu inside your mobile app shop tab from your Shopify Navigation"
                        type="SHOP_NAVIGATION"
                    />
                    <NavigationMenuCard
                        title="Add Extra Links"
                        subtitle="Add links from your Shopify navigation to your mobile app store."
                        type="CUSTOM_LINKS"
                        hideStatusColumn
                    />
                </BlockStack>
            </BlockStack>
        </AppBuilderBaseLayout>
    );
};
export default MobileAppCustomMenuPage;
