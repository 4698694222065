import { useCallback } from 'react';
import { useVFSAuthFetch } from './useAuthenticatedFetch';
import { buildMobileAppStore } from '@/stores';

export const useSendAppRequest = () => {
    const fetch = useVFSAuthFetch();

    const sendAppRequest = useCallback(async () => {
        const {
            appleDevTeamId,
            desiredAppName,
            desiredAppNameSecond,
            desiredAppNameThird,
            appLogoUrl,
            launchScreenImageUrl,
            supportURL,
        } = buildMobileAppStore;
        await fetch('/app-requests', {
            method: 'POST',
            body: JSON.stringify({
                appleDevTeamId: appleDevTeamId,
                desiredAppName: desiredAppName,
                altAppNameOne: desiredAppNameSecond,
                altAppNameTwo: desiredAppNameThird,
                iconS3Url: appLogoUrl,
                launchS3Url: launchScreenImageUrl,
                supportUrl: supportURL,
            }),
        });
    }, [fetch]);
    return { sendAppRequest };
};
