import { shopStore } from "@/stores";
import { openEmbedWindow } from "@/utils/helpers";
import { BlockStack, Box, Icon, InlineStack, Link, Text } from "@shopify/polaris";
import { ArrowDiagonalIcon, CaretDownIcon, CaretUpIcon, CheckCircleIcon, ClockIcon } from "@shopify/polaris-icons";
import { useRef } from "react";
import { useSnapshot } from "valtio";

type WizardStepProps = {
    stepExpanded: boolean;
    isCompleted: boolean;
    hideTime?: boolean;
};

export default function WizardStep4({ stepExpanded, isCompleted, hideTime }: WizardStepProps) {
    const themeWindowRef = useRef<Window | null>();
    const { popclipCarouselEmbedEnableUrl, getStartedStatus } = useSnapshot(shopStore);

    const handleOpenEmbedWindow = async () => {
        openEmbedWindow(themeWindowRef, popclipCarouselEmbedEnableUrl);
        if (!getStartedStatus.events.STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL) {
            await shopStore.updateGetStartedStatus(
                'STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL',
            );
        }
    }

    return (
        <Box
            background={!isCompleted && stepExpanded ? 'bg-fill-active' : 'bg-fill'}
            padding="300"
            borderRadius="200"
        >
            <InlineStack align="space-between">
                <InlineStack gap="300">
                    <Icon source={CheckCircleIcon} />
                    <Text as="p" variant="bodyLg">
                        Publish your first Popclip Carousel
                    </Text>
                </InlineStack>
                {!isCompleted && (
                    <InlineStack gap="500">
                        {!hideTime && (
                            <InlineStack gap="100">
                                <Icon source={ClockIcon} />
                                <p>1 Mins</p>
                            </InlineStack>
                        )}
                        <Icon
                            source={stepExpanded ? CaretUpIcon : CaretDownIcon}
                        />
                    </InlineStack>
                )}
            </InlineStack>
            {!isCompleted && stepExpanded && (
                <Box paddingBlockStart="400">
                    <BlockStack gap="400">
                        <iframe
                            style={{
                                width: '100%',
                                height: hideTime ? '240px' : '320px',
                            }}
                            src="https://www.youtube.com/embed/VlMVTEtkkgU?si=PzDRElYdsv6XCFc9"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        ></iframe>
                        <Text as="p" tone="subdued">
                            Display all your Popclips in a scrollable carousel
                            anywhere on your site{' '}
                            <Link
                                url="https://help.videeo.live/hc/en-us/articles/27915145521940-Add-a-Popclips-Carousel-to-Your-Shopify-Online-Store"
                                target="_blank"
                            >
                                <Text as="span" fontWeight="bold" tone="magic">
                                    Learn more
                                </Text>
                            </Link>
                        </Text>
                        <InlineStack align="start">
                            <button
                                onClick={handleOpenEmbedWindow}
                                className="onboarding-wizard-modal__step-cta"
                            >
                                Publish Now <Icon source={ArrowDiagonalIcon} />
                            </button>
                        </InlineStack>
                    </BlockStack>
                </Box>
            )}
        </Box>
    );
}