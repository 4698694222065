import { proxy } from 'valtio';
import shopStore from './shopStore';
import { sha256 } from 'js-sha256';
import { getDeviceInfo, getFingerprint } from '@/utils/helpers';
import { UAParser } from 'ua-parser-js';

const APP_NAME_BY_ENV =
    import.meta.env.MODE == 'qa' ? 'vfs-november' : 'videeo-by-commentsold';

const analyticsEventStore = proxy({
    popclipsUploadedAppEvent: (responseData: any) => {
        if (!responseData) return;

        try {
            const appEventsApiUrl = `${shopStore.config?.csDataLakeConfig?.apiUrl}/ingest/appevents`;
            fetch(appEventsApiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    occurred_at: Date.now(),
                    event_type: 'POPCLIP_UPLOADED',
                    customer_name: shopStore.shopName,
                    customer_id: shopStore.shopifyDomain,
                    event_details: responseData,
                }),
                redirect: 'follow',
            });
        } catch (error) {
            console.log('Failed to fetch data lake API: ', error);
        }
    },
    popclipUploadTriggered: async (
        isEditMode: boolean,
        { duration, popclipsID, title, source, instaReelUrl }: any,
    ) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}/`;
        const productEditLink = `${shopifyAdminURL}popclips/edit/${popclipsID}`;
        const currentUrl = isEditMode
            ? productEditLink
            : `${shopifyAdminURL}popclips/create`;

        const payloadData = await dataLakePayloadToTrack(
            'popclip_upload_triggered',
            'task_completed',
            {
                duration,
                location: currentUrl,
                source,
                title,
                editLink: productEditLink,
                instaReelUrl,
            },
            popclipsID,
            'popclips/list',
            {
                type: 'button',
                title: 'Save',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[1]/div/div/div/div[2]/button/span/span',
            },
        );

        try {
            fetch(
                `${shopStore.config?.csDataLakeConfig?.apiUrl}/ingest/productanalytics`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payloadData),
                    redirect: 'follow',
                },
            );
        } catch (error) {
            console.log('Failed to fetch data lake API: ', error);
        }
    },
    popclipCreateCustomCarouselTriggered: async ({
        duration,
        carouselId,
    }: any) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}/`;
        const currentUrl = `${shopifyAdminURL}popclips/create-carousel`;

        const payloadData = await dataLakePayloadToTrack(
            'popclip_create_customcarousel',
            'task_completed',
            {
                duration,
                location: currentUrl,
            },
            carouselId,
            'popclips/list',
            {
                type: 'button',
                title: 'Save $ Publish',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div/div[1]/div/div[1]/div[2]/div[2]/button',
            },
        );

        try {
            fetch(
                `${shopStore.config?.csDataLakeConfig?.apiUrl}/ingest/productanalytics`,
                {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payloadData),
                    redirect: 'follow',
                },
            );
        } catch (error) {
            console.log('Failed to fetch data lake API: ', error);
        }
    },
});

const dataLakePayloadToTrack = async (
    eventAction: string,
    elementActionStage: string,
    options = {},
    elementID: string,
    entryURL: string,
    elementCTA: { type: string; title: string; xPath: string },
) => {
    const parser = new UAParser();
    const sessionId = await getFingerprint();
    const hashSessionId = sha256(sessionId);
    const { deviceModel, deviceManufacturer } = getDeviceInfo(parser);

    return {
        element_action: eventAction,
        element_category: 'popclips',
        event_timestamp: Date.now(),
        element_action_stage: elementActionStage,
        element_cta_type: elementCTA.type,
        element_cta_title: elementCTA.title,
        element_cta_objectid: elementCTA.xPath,
        entry_url: entryURL,
        element_identifier: elementID,
        shop_id: shopStore.shopifyDomain,
        session_id: `${shopStore.shopifyDomain}_${hashSessionId}`,
        shop_user_id: shopStore.ownerEmail,
        fingerprint_id: hashSessionId,
        event_platform: 'vfs_admin_web',
        event_platform_version: '1.0.0',
        context: {
            device_model: deviceModel,
            device_manufacturer: deviceManufacturer,
            operating_system: parser?.getOS()?.name || 'Unknown',
            os_version: parser?.getOS()?.version || 'Unknown',
            language: navigator.language.split('-')[0],
            locale: navigator.language,
            experiment_details: '',
            notifications_enabled: false,
            gclid: null,
            dclid: null,
            gbraid: null,
            wbraid: null,
            fbclid: null,
            twclid: null,
            clid_src: null,
            msclkid: null,
            ttclid: null,
            ...options,
        },
    };
};

export default analyticsEventStore;
