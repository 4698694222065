import { popclipsRoutes } from "@/constants/routes.constants";
import { shopStore } from "@/stores";
import { copyTextToClipboard, openEmbedWindow } from "@/utils/helpers";
import { Modal, TitleBar } from "@shopify/app-bridge-react";
import { BlockStack, Box, Button, Divider, InlineStack, Link, Text } from "@shopify/polaris";
import { ArrowDiagonalIcon, ClipboardIcon } from "@shopify/polaris-icons";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customCarouselPublish } from "../../../assets/images";
import { useSnapshot } from "valtio";

type CarouselPublishModalProps = {
    openPublishModal: boolean;
    hidePublishModal: () => void;
    carouselId: string;
};

export default function CarouselPublishModal({
    openPublishModal,
    hidePublishModal,
    carouselId,
}: CarouselPublishModalProps) {
    const navigate = useNavigate();
    const { getStartedStatus, popclipCarouselEmbedEnableUrl } = useSnapshot(shopStore);

    const themeWindowRef = useRef<Window | null>();
    const [isCopiedCarouselId, setIsCopiedCarouselId] = useState(false);
    
    const handleCopyButtonClicked = (carouselId: string) => {
        copyTextToClipboard(carouselId);
        setIsCopiedCarouselId(true)
    }

    const handleOpenEmbedWindow = async () => {
        const shopifyThemeEditorUrl = popclipCarouselEmbedEnableUrl.replace(
            'popclips-carousel',
            'custom-popclips-carousel',
        );
        openEmbedWindow(themeWindowRef, shopifyThemeEditorUrl);
        if (!getStartedStatus.events.STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL) {
            await shopStore.updateGetStartedStatus(
                'STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL',
            );
        }
    };

    return (
        <Modal
            open={openPublishModal}
            variant="small"
            onHide={() => navigate(popclipsRoutes.manageCarousel)}
        >
            <TitleBar title="Carousel is saved">
                <button variant="primary" onClick={hidePublishModal}>
                    Done
                </button>
            </TitleBar>
            <Box padding="400">
                <BlockStack gap="500">
                    <BlockStack gap="300" align="start">
                        <Text as="p" variant="headingLg">
                            Publish Carousel
                        </Text>
                        <Box>
                            <Text as="p" variant="headingSm" tone="subdued">
                                Follow the steps below to use Custom Carousels
                            </Text>
                            <Link
                                url="https://www.youtube.com/watch?v=1eS_8RCY6pU"
                                removeUnderline
                                target="_blank"
                            >
                                <Text
                                    as="p"
                                    variant="bodyLg"
                                    fontWeight="semibold"
                                >
                                    Watch this video for more
                                </Text>
                            </Link>
                        </Box>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap="300">
                        <InlineStack gap="300" blockAlign="center">
                            <Box
                                borderRadius="full"
                                background="bg-fill-disabled"
                                width="2rem"
                                padding="100"
                                paddingInlineStart="300"
                            >
                                <Text as="p" tone="subdued" fontWeight="bold">
                                    1
                                </Text>
                            </Box>
                            <Text as="p" variant="headingSm">
                                Copy Carousel ID
                            </Text>
                        </InlineStack>
                        <InlineStack gap="200">
                            <Box
                                borderWidth="050"
                                borderRadius="100"
                                borderColor="border-magic"
                                background="bg-fill-magic-secondary"
                                padding="100"
                                paddingInline="200"
                                minWidth="16rem"
                                color="text-link"
                            >
                                <Text
                                    as="p"
                                    variant="bodyMd"
                                    fontWeight="semibold"
                                >
                                    {carouselId}
                                </Text>
                            </Box>
                            <Button
                                size="micro"
                                icon={ClipboardIcon}
                                onClick={() =>
                                    handleCopyButtonClicked(carouselId)
                                }
                            >
                                {isCopiedCarouselId ? 'Copied' : 'Copy'}
                            </Button>
                        </InlineStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap="300">
                        <InlineStack gap="300" blockAlign="center">
                            <Box
                                borderRadius="full"
                                background="bg-fill-disabled"
                                width="2rem"
                                padding="100"
                                paddingInlineStart="300"
                            >
                                <Text as="p" tone="subdued" fontWeight="bold">
                                    2
                                </Text>
                            </Box>
                            <Text as="p" variant="headingSm">
                                Add carousel to any page
                            </Text>
                        </InlineStack>
                        <InlineStack align="start">
                            <Button
                                variant="primary"
                                size="micro"
                                icon={ArrowDiagonalIcon}
                                onClick={handleOpenEmbedWindow}
                            >
                                Go To Theme Editor
                            </Button>
                        </InlineStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap="300">
                        <InlineStack gap="300" blockAlign="center">
                            <Box
                                borderRadius="full"
                                background="bg-fill-disabled"
                                width="2rem"
                                padding="100"
                                paddingInlineStart="300"
                            >
                                <Text as="p" tone="subdued" fontWeight="bold">
                                    3
                                </Text>
                            </Box>
                            <Box width="19rem">
                                <Text as="p" variant="headingSm">
                                    Select the new block in your Shopify theme,
                                    paste the Carousel ID, and click save.
                                </Text>
                            </Box>
                        </InlineStack>
                        <img
                            src={customCarouselPublish}
                            alt="customCarouselPublish"
                        />
                    </BlockStack>

                    <Text as="p" variant="bodyMd" tone="subdued">
                        Voilà! The carousel is now added to your selected page.
                    </Text>
                </BlockStack>
            </Box>
        </Modal>
    );
}