import { proxy } from 'valtio';

const defaultMobileAppConfigStore = proxy({
    featuredShopifyCollectionGid: '',
    shopifyCollectionGid: '',
    headerLogoUrl: '',
    customFont: '',
    primaryColor: '#000000',
    secondaryColor: '#000000',
    bannerTitle: '',
    bannerText: '',
    bannerColor: '#000000',
    liveBannerTitle: '',
    liveBannerText: '',
    liveBannerColor: '#000000',
    notificationsTitle: '',
    notificationsBody: '',
    menuHandle: 'main-menu',
    shopMenuHandle: 'main-menu',
    customLinksHandle: 'main-menu',
    showInventoryLimit: 0,
});
export default defaultMobileAppConfigStore;