import { useCallback } from 'react';
import { useVFSAuthFetch } from '@/hooks/useAuthenticatedFetch';
import { buildMobileAppStore } from '@/stores';

export type AppRequestsResponse = {
    androidLink: string;
    androidLiveVersion: string;
    appleBlocked: boolean;
    appleIssue: string;
    applePendingValidation: true;
    blocked: boolean;
    deliveryState: number;
    iconIssue: string;
    iosLink: string;
    iosLiveVersion: string;
    launchIssue: string;
    nameIssue: string;
    pendingValidation: true;
    pipelineShopName: string;
    productsIssue: string;
    supportUrlIssue: string;
    appleDevTeamId: string;
    desiredAppName: string;
    altAppNameOne: string;
    altAppNameTwo: string;
    iconS3Url: string;
    launchS3Url: string;
    supportUrl: string;
};
export const useGetAppRequest = () => {
    const fetch = useVFSAuthFetch();
    const getAppRequests = useCallback(async () => {
        try {
            const response = await fetch<AppRequestsResponse>('/app-requests');
            if (response.data) {
                if (response.data.pipelineShopName) {
                    buildMobileAppStore.isAppRequestSubmitted = true;
                }
                buildMobileAppStore.setAppleDevTeamId(
                    response.data.appleDevTeamId,
                );
                buildMobileAppStore.setDesiredAppName(
                    response.data.desiredAppName,
                );
                buildMobileAppStore.setDesiredAppNameSecond(
                    response.data.altAppNameOne,
                );
                buildMobileAppStore.setDesiredAppNameThird(
                    response.data.altAppNameTwo,
                );
                buildMobileAppStore.appLogoUrl = response.data.iconS3Url;
                buildMobileAppStore.launchScreenImageUrl =
                    response.data.launchS3Url;
                buildMobileAppStore.supportURL = response.data.supportUrl;
                buildMobileAppStore.iosLink = response.data.iosLink;
                buildMobileAppStore.androidLink = response.data.androidLink;
            }
        } catch (e) {
            console.error(e);
        } finally {
            buildMobileAppStore.isAppRequestLoading = false;
        }
    }, [fetch]);

    return { getAppRequests };
};
