import { popclipsRoutes } from '@/constants/routes.constants';
import videoLiveSellingStore from '@/stores/videoLiveSellingStore';
import {
    BlockStack,
    Box,
    Checkbox,
    Icon,
    InlineStack,
    Link,
    Text,
    Tooltip,
} from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { useSnapshot } from 'valtio';

const ClipExcludeCheckBox = () => {
    const videoLiveSellingSnapshot = useSnapshot(videoLiveSellingStore, {
        sync: true,
    });

    const excludeClipToolTip = (
        <Box paddingBlockStart="100">
            {videoLiveSellingSnapshot.isClipExcluded ? (
                <Tooltip
                    width="wide"
                    active
                    content="This clip will not be included on any of the associated product detail pages."
                >
                    <Icon source={InfoIcon} tone="base" />
                </Tooltip>
            ) : null}
        </Box>
    );

    return (
        <BlockStack inlineAlign="center" gap="200">
            <InlineStack>
                <Checkbox
                    label="Exclude clip from product detail page"
                    checked={videoLiveSellingSnapshot.isClipExcluded}
                    onChange={(value) => {
                        videoLiveSellingStore.isClipExcluded = value;
                    }}
                    disabled={videoLiveSellingSnapshot.savingPopclip}
                />
                {excludeClipToolTip}
            </InlineStack>
            <Text as="p">
                To exclude Popclip from all product detail pages, click{' '}
                <Link url={popclipsRoutes.settings}>
                    here.
                </Link>
            </Text>
        </BlockStack>
    );
};

export default ClipExcludeCheckBox;
