import {
    BlockStack,
    Box,
    Button,
    Card,
    InlineStack,
    Text,
} from '@shopify/polaris';

type HelpCenterResourceCardProps = {
    cardTitle: string;
    cardDescription: string;
    btnLabel: string;
    externalLink: string;
};

const HelpCenterResourceCard = ({
    cardTitle,
    cardDescription,
    btnLabel,
    externalLink,
}: HelpCenterResourceCardProps) => (
    <Card padding="400" background="bg-fill">
        <Box>
            <BlockStack gap="600" align="space-between">
                <Box minHeight='5rem'>
                    <BlockStack gap="300">
                        <Text as="h3" variant="headingSm">
                            {cardTitle}
                        </Text>
                        <Text as="h4" variant="bodySm">
                            {cardDescription}
                        </Text>
                    </BlockStack>
                </Box>
                <InlineStack align="start">
                    <Button onClick={() => window.open(externalLink, '_blank')}>
                        {btnLabel}
                    </Button>
                </InlineStack>
            </BlockStack>
        </Box>
    </Card>
);

export default HelpCenterResourceCard;
