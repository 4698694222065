import { mobileAppConfigStore } from "@/stores";
import { BlockStack, Box, Icon, InlineStack, Text } from "@shopify/polaris";
import { CaretDownIcon, PlusIcon } from "@shopify/polaris-icons";
import { useSnapshot } from "valtio";
import { dummyProdCover } from "../../../assets/images";

type MobilePDPPageProps = {
    primaryColor?: string;
    secondaryColor?: string;
    fontColor?: string;
};

export default function MobilePDPPage({
    primaryColor,
    secondaryColor,
    fontColor = 'white',
}: MobilePDPPageProps) {
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore);
    
    return (
        <Box width="340px" borderWidth="025" borderRadius="100" padding="100">
            <BlockStack>
                <img src={dummyProdCover} alt="dummyProdCover" />
                <Box background="bg-fill" padding="200" paddingBlockStart="500">
                    <BlockStack gap="400" align="start" inlineAlign="start">
                        <Text as="h1" variant="headingLg" fontWeight="regular">
                            PRODUCT NAME PRODUCT NAME PRODUCT NAME PRODUCT NAME
                        </Text>
                        <InlineStack gap="200">
                            <div
                                style={{
                                    color:
                                        primaryColor ||
                                        mobileAppConfigSnap.primaryColor,
                                }}
                            >
                                <Text as="span" variant="bodyLg">
                                    $5.00
                                </Text>
                            </div>
                            <Text
                                as="span"
                                variant="bodyMd"
                                textDecorationLine="line-through"
                            >
                                $10.50
                            </Text>
                        </InlineStack>

                        <BlockStack gap="200">
                            <Text as="span" variant="bodyMd" tone="subdued">
                                15 Sizes Available
                            </Text>
                            <InlineStack gap="300" align="start">
                                <Box
                                    borderWidth="025"
                                    borderRadius="100"
                                    minHeight="50px"
                                    padding="100"
                                    paddingInline="200"
                                >
                                    <BlockStack gap="025">
                                        <Text as="span">1</Text>
                                        <Text as="span">In Stock</Text>
                                    </BlockStack>
                                </Box>
                                <div
                                    style={{
                                        background:
                                            primaryColor ||
                                            mobileAppConfigSnap.primaryColor,
                                        color: fontColor,
                                        borderRadius: '0.35rem',
                                    }}
                                >
                                    <Box
                                        borderWidth="025"
                                        borderRadius="100"
                                        minHeight="50px"
                                        padding="100"
                                        paddingInline="200"
                                    >
                                        <BlockStack gap="025">
                                            <Text as="span">1</Text>
                                            <Text as="span">In Stock</Text>
                                        </BlockStack>
                                    </Box>
                                </div>
                                <Box
                                    borderWidth="025"
                                    borderRadius="100"
                                    minHeight="50px"
                                    padding="100"
                                    paddingInline="200"
                                >
                                    <BlockStack gap="025">
                                        <Text as="span">1</Text>
                                        <Text as="span">In Stock</Text>
                                    </BlockStack>
                                </Box>
                                <Box
                                    borderWidth="025"
                                    borderRadius="100"
                                    minHeight="50px"
                                    padding="100"
                                    paddingInline="200"
                                >
                                    <Text as="span">View All</Text>
                                </Box>
                            </InlineStack>
                        </BlockStack>

                        <BlockStack gap="200">
                            <Text as="span" variant="bodyMd" tone="subdued">
                                Color
                            </Text>
                            <InlineStack gap="300" align="start">
                                <Box
                                    borderWidth="025"
                                    borderRadius="100"
                                    minHeight="50px"
                                    padding="100"
                                    paddingInline="200"
                                >
                                    <BlockStack gap="025">
                                        <Text as="span">White</Text>
                                        <Text as="span">In Stock</Text>
                                    </BlockStack>
                                </Box>
                                <Box
                                    borderWidth="025"
                                    borderRadius="100"
                                    minHeight="50px"
                                    padding="100"
                                    paddingInline="200"
                                >
                                    <BlockStack gap="025">
                                        <Text as="span">Orange</Text>
                                        <Text as="span">In Stock</Text>
                                    </BlockStack>
                                </Box>
                                <Box
                                    borderWidth="025"
                                    borderRadius="100"
                                    minHeight="50px"
                                    padding="100"
                                    paddingInline="200"
                                    opacity=".5"
                                >
                                    <BlockStack gap="025">
                                        <Text as="span">Pale</Text>
                                        <Text as="span">0 Stock</Text>
                                    </BlockStack>
                                </Box>
                                <Box
                                    borderWidth="025"
                                    borderRadius="100"
                                    minHeight="50px"
                                    padding="100"
                                    paddingInline="200"
                                >
                                    <BlockStack gap="025">
                                        <Text as="span">Cherry</Text>
                                        <Text as="span">In Stock</Text>
                                    </BlockStack>
                                </Box>
                            </InlineStack>
                        </BlockStack>

                        <BlockStack gap="200">
                            <Text as="span" variant="bodyMd" tone="subdued">
                                Qty
                            </Text>
                            <Box
                                borderWidth="025"
                                borderRadius="100"
                                width="305px"
                                minHeight="20px"
                                padding="100"
                                paddingInlineStart="300"
                            >
                                <InlineStack align="space-between">
                                    <Text as="span">1</Text>
                                    <div>
                                        <Icon source={CaretDownIcon} />
                                    </div>
                                </InlineStack>
                            </Box>
                        </BlockStack>

                        <div
                            style={{
                                background:
                                    primaryColor ||
                                    mobileAppConfigSnap.primaryColor,
                                color: fontColor,
                                borderRadius: '0.25rem',
                            }}
                        >
                            <Box
                                borderWidth="025"
                                borderRadius="100"
                                width="305px"
                                minHeight="20px"
                                padding="200"
                            >
                                <Text
                                    as="span"
                                    alignment="center"
                                    fontWeight="semibold"
                                >
                                    ADD TO CART
                                </Text>
                            </Box>
                        </div>

                        <Box
                            borderBlockStartWidth="025"
                            borderColor="border-secondary"
                            width="305px"
                            paddingBlockStart="300"
                        >
                            <InlineStack
                                align="space-between"
                                blockAlign="center"
                            >
                                <Text as="p" variant="bodyMd" tone="subdued">
                                    Product Details
                                </Text>
                                <div>
                                    <Icon source={PlusIcon} />
                                </div>
                            </InlineStack>
                        </Box>
                    </BlockStack>
                </Box>
            </BlockStack>
        </Box>
    );
}