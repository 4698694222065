import { BlockStack, Box, Button, Divider, InlineStack, Text } from "@shopify/polaris";

type MobilePlanAddonCardProps = {
    addonData: any;
    addonValue: string;
    isSelected: boolean;
    isDisabled: boolean;
    onSelectPlan: () => void;
};

export default function MobilePlanAddonCard({
    addonData,
    addonValue,
    isSelected,
    onSelectPlan,
    isDisabled,
}: MobilePlanAddonCardProps) {
    const value = addonValue ? addonValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';

    return (
        <Box
            width="100%"
            borderRadius="400"
            borderWidth="025"
            padding="400"
            borderColor="border-disabled"
            shadow="200"
        >
            <BlockStack gap="400">
                <InlineStack align="space-between" wrap={false}>
                    <BlockStack gap="100">
                        <Text as="p" fontWeight="bold" variant="headingLg">
                            {addonData.name}
                        </Text>
                        <Text as="p" fontWeight="medium" variant="bodySm">
                            Up to {value} app downloads
                        </Text>
                    </BlockStack>
                    {Math.round(parseFloat(addonData.price[0].amount)) === 0 ? (
                        <Text as="p" fontWeight="bold" variant="heading2xl">
                            Free
                        </Text>
                    ) : (
                        <InlineStack blockAlign="center" gap="200">
                            <Text as="p" fontWeight="bold" variant="heading2xl">
                                {addonData.price[0].amount}
                            </Text>
                            <BlockStack>
                                <Text as="p" variant="bodyXs">
                                    {addonData.price[0].currencyCode}
                                </Text>
                                <Text as="p" variant="bodyXs">
                                    /mo
                                </Text>
                            </BlockStack>
                        </InlineStack>
                    )}
                </InlineStack>
                <Divider />
                <InlineStack align="end" gap="200">
                    {isSelected ? (
                        <Button
                            variant="primary"
                            tone="success"
                            onClick={onSelectPlan}
                            disabled={isDisabled}
                        >
                            Selected
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={onSelectPlan}
                            disabled={isDisabled}
                        >
                            Select Plan
                        </Button>
                    )}
                </InlineStack>
            </BlockStack>
        </Box>
    );
}