import { billingStore, shopStore } from "@/stores";
import AlertBannerComponent from "../AlertBannerComponent";
import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";
import { vfsRoutes } from "@/constants/routes.constants";

type CustomPlanAlertBannerProps = {
    isDisabled?: boolean;
};

export default function CustomPlanAlertBanner({ isDisabled }: CustomPlanAlertBannerProps) {
    const { activePlan, subscriptionActive } = useSnapshot(shopStore);
    const navigate = useNavigate();

    return (
        <>
            {subscriptionActive &&
                activePlan?.skuCode != billingStore.selectedPlanSku && (
                    <AlertBannerComponent
                        type="warning"
                        title="You are on a Customized Plan"
                        message={`You are on a customized plan, reach out to support with your plan code ${
                            activePlan?.skuCode ?? 'bundle-legacy-unknown'
                        }`}
                        size="large"
                        buttonText="Contact Support"
                        primaryAction={() => navigate(vfsRoutes.landing)}
                        loading={isDisabled}
                    />
                )}
        </>
    );
}