import { useCallback } from 'react';
import { useVFSAuthFetch } from '@/hooks/useAuthenticatedFetch';
import { buildMobileAppStore } from '@/stores';

export const useGetAppleEmail = () => {
    const fetch = useVFSAuthFetch();
    const getAppleEmail = useCallback(async () => {
        try {
            buildMobileAppStore.isAppleEmailLoading = true;
            const response = await fetch<{
                emailAddress: string;
            }>('/apple-email');
            if (response.data) {
                const { emailAddress } = response.data;
                buildMobileAppStore.rotatingAppleEmail = emailAddress;
            }
        } catch (e) {
            shopify.toast.show(
                'Failed to get plan information. Please contact support.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
        } finally {
            buildMobileAppStore.isAppleEmailLoading = false;
        }
    }, [fetch]);
    return {
        getAppleEmail,
    };
};
