import { BlockStack, Icon, InlineStack, Link, Text } from '@shopify/polaris';
import { ArrowRightIcon } from '@shopify/polaris-icons';
import LiveSellingCard from '../Components/WelcomePage/LiveSellingCard';
import MobileAppCard from '../Components/WelcomePage/MobileAppCard';
import RecommendedFeaturesCard from '../Components/WelcomePage/RecommendedFeatures';
import PopclipsCard from '../Components/WelcomePage/PopclipsCard';
import { BaseLayout } from './Layout/BaseLayout';
import PlanChangeBanner from '../Components/Banners/PlanChangeBanner';
import PopclipsFeatureGatingBanner from '../Components/Banners/PopclipsFeatureGatingBanner';
import '../../assets/css/WelcomePage.css';
import { shopStore } from '@/stores';
import { useSnapshot } from 'valtio';

export default function WelcomePage() {
    const { getStartedStatus } = useSnapshot(shopStore);
    
    return (
        <BaseLayout
            title="👋 Welcome to Videeo!"
            subtitle={
                <InlineStack gap="200">
                    <Text as="p" variant="bodyMd">
                        Setup questions?
                    </Text>
                    <div className="schedule-free-onboarding-call">
                        <Link
                            url="https://calendly.com/videeo/initial-videeo-onboarding"
                            removeUnderline
                            target="_blank"
                        >
                            <InlineStack gap="100" blockAlign="center">
                                <Text
                                    as="span"
                                    fontWeight="semibold"
                                    tone="magic"
                                >
                                    Schedule a free onboarding call
                                </Text>
                                <Icon source={ArrowRightIcon} tone="magic" />
                            </InlineStack>
                        </Link>
                    </div>
                </InlineStack>
            }
            showProgressStatus={getStartedStatus.completionPercentage !== 100}
        >
            <div style={{ marginBottom: '10px' }}>
                <PlanChangeBanner />
                <PopclipsFeatureGatingBanner />
            </div>
            <BlockStack gap="800">
                <PopclipsCard />
                <InlineStack gap="400" wrap={false}>
                    <RecommendedFeaturesCard />
                    <LiveSellingCard />
                    <MobileAppCard />
                </InlineStack>
            </BlockStack>
        </BaseLayout>
    );
};