import { Box, InlineStack, Checkbox, InlineError } from '@shopify/polaris';

import { PropsWithChildren, ReactNode } from 'react';
export interface CheckboxProps {
    label: string | ReactNode;
    helperText: string | ReactNode;
    checked: boolean;
    handleChange: (checked: boolean) => void;
    badgeText: string;
    badgeTextColor: string;
    badgeBGColor: string;
    disabled: boolean;
    error: string;
}
const AppCheckbox = (props: PropsWithChildren<CheckboxProps>) => {
    return (
        <Box padding="400">
            <InlineStack
                gap="1200"
                align="space-between"
                blockAlign="start"
                wrap={false}
            >
                <Checkbox
                    disabled={props.disabled}
                    fill={true}
                    label={props.label}
                    labelHidden={false}
                    checked={props.checked}
                    onChange={(value) => {
                        props.handleChange(value);
                    }}
                    helpText={props.helperText}
                />
                <Box minWidth="fit-content" paddingBlockStart="400">
                    <InlineStack align="end">
                        <div
                            style={{
                                paddingInline: '10px',
                                borderRadius: 5,
                                backgroundColor: props.badgeBGColor,
                                color: props.badgeTextColor,
                            }}
                        >
                            {props.badgeText}
                        </div>
                    </InlineStack>
                </Box>
            </InlineStack>
            <InlineError message={props.error} fieldID="" />
        </Box>
    );
};

export default AppCheckbox;
