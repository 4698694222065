import { useCallback } from 'react';
import { useVFSAuthFetch } from '@/hooks/useAuthenticatedFetch';
import { buildMobileAppStore } from '@/stores';

export const useGetAppSetup = () => {
    const fetch = useVFSAuthFetch();
    const getAppSetup = useCallback(async () => {
        try {
            buildMobileAppStore.isAppSetupLoading = true;
            const response = await fetch<{
                dunsCompletedAt: number;
                iosDevCompletedAt: number;
                addDevEmailCompletedAt: number;
                updatedAt: number;
            }>('/app-setup');
            if (response.data) {
                const {
                    dunsCompletedAt,
                    iosDevCompletedAt,
                    addDevEmailCompletedAt,
                } = response.data;
                buildMobileAppStore.dunsCompletedAt = dunsCompletedAt || 0;
                buildMobileAppStore.iosDevCompletedAt = iosDevCompletedAt || 0;
                buildMobileAppStore.addDevEmailCompletedAt =
                    addDevEmailCompletedAt || 0;
            }
        } catch (e) {
            console.error(e);
            shopify.toast.show('Failed to get plan information. Please contact support.', {
                isError: true,
                duration: 5000,
            });
        } finally {
            buildMobileAppStore.isAppSetupLoading = false;
        }
    }, [fetch]);
    return {
        getAppSetup,
    };
};
