import { PropsWithChildren, ReactNode, useMemo } from 'react';
import {
    Collapsible,
    Button,
    Card,
    InlineStack,
    Text,
    BlockStack,
    Icon,
    Box,
} from '@shopify/polaris';

import {
    ChevronUpIcon,
    ChevronDownIcon,
    CheckCircleIcon,
} from '@shopify/polaris-icons';

export interface CollapsibleCardWithTitleSectionProps {
    title: string;
    titleVariant?: Parameters<typeof Text>[0]['variant'];
    titleTone?: Parameters<typeof Text>[0]['tone'];
    subtitle?: string;
    subtitleTwo?: string;
    showCheck: boolean;
    open: boolean;
    headerAppendComponent?: ReactNode;
    topBarComponent?: ReactNode;
    titleComponent?: ReactNode;
    onToggleOpen?: (updatedValue: boolean) => void;
    hideSubtitleWhenClosed: boolean;
    hideSubtitleTwoWhenClosed?: boolean;
    feature?: string;
}

const CollapsibleCardWithTitleSection: React.FC<
    PropsWithChildren<CollapsibleCardWithTitleSectionProps>
> = (props: PropsWithChildren<CollapsibleCardWithTitleSectionProps>) => {
    const shouldShowTopBar = useMemo(() => {
        return (
            props.title !== '' ||
            props.subtitle !== '' ||
            props.showCheck ||
            props.onToggleOpen !== undefined
        );
    }, []);

    const shouldShowSubtitle = useMemo(() => {
        if (props.subtitle) {
            if (props.open) {
                return true;
            } else {
                return !props.hideSubtitleWhenClosed;
            }
        }

        return false;
    }, [props.open]);

    const shouldShowSubtitleTwo = useMemo(() => {
        if (props.subtitleTwo) {
            if (props.open) {
                return true;
            } else {
                return !props.hideSubtitleTwoWhenClosed;
            }
        }

        return false;
    }, [props.open]);

    return (
        <Card>
            {props.headerAppendComponent && <>{props.topBarComponent}</>}
            <Box padding="800">
                <BlockStack gap={shouldShowTopBar ? '600' : '0'}>
                    {shouldShowTopBar ? (
                        <BlockStack>
                            <InlineStack align="space-between">
                                {props.titleComponent ? (
                                    <>{props.titleComponent}</>
                                ) : (
                                    <BlockStack gap="300" align="center">
                                        <Box paddingBlock="200">
                                            <Text
                                                as="h3"
                                                tone={props.titleTone}
                                                variant={
                                                    props.titleVariant ||
                                                    'headingSm'
                                                }
                                            >
                                                {props.title}
                                            </Text>
                                        </Box>
                                    </BlockStack>
                                )}
                                <InlineStack
                                    align="end"
                                    blockAlign="center"
                                    gap="050"
                                >
                                    {props.headerAppendComponent && (
                                        <div style={{ marginRight: '1rem' }}>
                                            {props.headerAppendComponent}
                                        </div>
                                    )}
                                    {props.showCheck && (
                                        <Icon
                                            source={CheckCircleIcon}
                                            tone="textSuccess"
                                        ></Icon>
                                    )}
                                    {props.onToggleOpen ? (
                                        <Button
                                            variant="tertiary"
                                            onClick={() =>
                                                props.onToggleOpen?.(
                                                    !props.open,
                                                )
                                            }
                                            aria-expanded={props.open}
                                            aria-controls="basic-collapsible"
                                            icon={
                                                !props.open
                                                    ? ChevronDownIcon
                                                    : ChevronUpIcon
                                            }
                                        ></Button>
                                    ) : null}
                                </InlineStack>
                            </InlineStack>
                            {shouldShowSubtitle && (
                                <Text as="h4" variant="bodySm">
                                    {props.subtitle}
                                </Text>
                            )}
                            {shouldShowSubtitleTwo && (
                                <Text as="h4" variant="bodySm">
                                    {props.subtitleTwo}
                                </Text>
                            )}
                        </BlockStack>
                    ) : null}
                    <Collapsible
                        open={props.open}
                        id="basic-collapsible"
                        transition={{
                            duration: '150ms',
                            timingFunction: 'ease-in-out',
                        }}
                    >
                        {props.children}
                    </Collapsible>
                </BlockStack>
            </Box>
        </Card>
    );
};

CollapsibleCardWithTitleSection.defaultProps = {
    hideSubtitleWhenClosed: false,
};

export default CollapsibleCardWithTitleSection;
