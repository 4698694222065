export const formatMoney = (
    amount: number,
    maximumFractionDigits: number = 3,
) => {
    const formatter = new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits,
    });

    return formatter.format(amount);
};

export const shopifyCurrencyFormatter = (price: string, currency?: string) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency ?? 'USD',
    }).format(parseFloat(price));
    if (currency === 'XXX') {
      return formattedValue.toString().substring(1);
    } else {
      return formattedValue.toString();
    }
  };

export function commaSeprateValue(value: number) {
  // Check if the input is a valid number
  if (isNaN(value)) {
    return value;
  }

  // Convert to a number (in case it's a string)
  const numberValue = Number(value);

  // Check if the number is a whole number
  if (numberValue % 1 === 0) {
    return numberValue.toLocaleString('en-US'); // No decimals for whole numbers
  }

  // Format with commas and up to two decimal places
  return numberValue.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}