import { useCallback } from 'react';
import { useMediaGalleryAuthFetch } from './useAuthenticatedFetch';
import { videoLiveSellingStore } from '@/stores';
import shopStore from '@/stores/shopStore';

type HookParams = {
    fileName: string;
};

type ApiResponse = {
    uploadFileUrl: string;
    validfor: number;
    clip: {
        id: string;
        metaIdentifier: string;
        fileType: string;
        sortKey: string;
        metaData: {
            products: [];
            videoDescription: string;
            videoTitle: string;
            videoURL: string;
            gifURL: string;
            thumbnailURL: string;
            processedURL: string;
            available: boolean;
            type: string;
            featured: boolean;
            status: string;
        };
        createdAt: string;
        updatedAt: string;
        cdnUrl: string;
    };
};

const FILE_CATEGORY = 'shopifyClips';

export const useUploadClip = () => {
    const fetch = useMediaGalleryAuthFetch();

    const uploadClip = useCallback(
        async ({ fileName }: HookParams) => {
            const apiUrl = 'csUpload';
            const firebaseUID = shopStore.popshopliveSellerId;
            const productsArr = videoLiveSellingStore.shopifySelectedProducts.map(prod => {
                return {
                    defaultImage: prod.imgSrc,
                    shopifyProductId: prod.id,
                    shopifyProductHandle: prod.handle,
                    shopifyProductUrl: `https://${shopStore.shopifyDomain}/products/${prod.handle}`,
                };
            });

            const dataObj = {
                fileName: fileName,
                fileMeta: { fileCategory: FILE_CATEGORY },
                metaData: {
                    isPrivate: true,
                    products: productsArr,
                    shopifyDomain: shopStore.shopifyDomain,
                    videoTitle: videoLiveSellingStore.clipTitle,
                    sellerId: firebaseUID,
                    display: videoLiveSellingStore.isClipExcluded ? [] : ['WEB_PDP', 'MOBILE_PDP', 'MOBILE_WEB_PDP'],
                },
            };

            const response = await fetch<ApiResponse>(apiUrl, {
                method: 'POST',
                body: JSON.stringify(dataObj),
            });

            return response;
        },
        [fetch],
    );
    return { uploadClip };
};
