import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    DatePicker,
    Popover,
    ResourceList,
    InlineStack,
    Button,
} from '@shopify/polaris';
import { CalendarIcon, ClockIcon } from '@shopify/polaris-icons';

const toInt = (time: string) =>
    ((h, m) => h * 12 + m / 5)(...time.split(':').map(parseFloat));

const toTime = (int: number) => {
    const hours = Math.floor(int / 12)
        .toString()
        .padStart(2, '0');
    const minutes = ((int % 12) * 5).toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

const range = (from: number, to: number) =>
    Array(to - from + 1)
        .fill(0)
        .map((_, i) => from + i);

const eachFiveMinutes = (t1: string, t2: string) =>
    range(toInt(t1), toInt(t2)).map(toTime);

const timeList = eachFiveMinutes('00:00', '23:55');

const getDateString = (d: Date) => {
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const getTimeString = (d: Date) => {
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

const today = new Date();
const yesterday = new Date(today.setDate(today.getDate() - 1));
const nextMonth = new Date(today.setMonth(today.getMonth() + 1));

interface DateTimePickerProps {
    initialValue?: number;
    onChange?: (date: Date) => void;
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
    initialValue = Date.now(),
    onChange = () => {},
}) => {
    const isInitialMount = useRef(true);

    const [{ month, year }, setDate] = useState({
        month: today.getMonth(),
        year: today.getFullYear(),
    });
    const [datePopoverActive, setDatePopoverActive] = useState(false);
    const [timePopoverActive, setTimePopoverActive] = useState(false);
    const [selectedDates, setSelectedDates] = useState(new Date(initialValue));
    const [selectedTime, setSelectedTime] = useState(
        getTimeString(new Date(initialValue)),
    );

    const dateString = getDateString(selectedDates);

    const onDateChange = useCallback((v: { start: Date; end: Date }) => {
        setSelectedDates(v.start);
        setDatePopoverActive(false);
    }, []);

    const onTimeSelect = useCallback((v: string) => {
        setSelectedTime(v);
        setTimePopoverActive(false);
    }, []);

    const toggleDatePopoverActive = useCallback(() => {
        setDatePopoverActive((v) => !v);
    }, []);

    const toggleTimePopoverActive = useCallback(() => {
        setTimePopoverActive((v) => !v);
    }, []);

    const handleMonthChange = useCallback((month: number, year: number) => {
        setDate({ month, year });
    }, []);

    const filteredTimeList = () => {
        const now = new Date();
        if (getDateString(selectedDates) === getDateString(now)) {
            return timeList.filter(
                (time) => toInt(time) > toInt(getTimeString(now)),
            );
        }
        return timeList;
    };

    const dateActivator = (
        <Button
            onClick={toggleDatePopoverActive}
            icon={CalendarIcon}
            disclosure
        >
            {dateString}
        </Button>
    );

    const timeActivator = (
        <Button onClick={toggleTimePopoverActive} icon={ClockIcon} disclosure>
            {selectedTime}
        </Button>
    );

    useEffect(() => {
        if (!isInitialMount.current) {
            if (selectedTime && selectedDates) {
                const selectedDateTime = new Date(selectedDates);
                const [hour, minute] = selectedTime.split(':');
                selectedDateTime.setHours(parseInt(hour), parseInt(minute));
                onChange(selectedDateTime);
            }
        } else {
            isInitialMount.current = false;
        }
    }, [selectedDates, selectedTime]);

    return (
        <InlineStack gap="400">
            <Popover
                preferredPosition="above"
                active={datePopoverActive}
                activator={dateActivator}
                onClose={toggleDatePopoverActive}
            >
                <div style={{ padding: '16px' }}>
                    <DatePicker
                        month={month}
                        year={year}
                        disableDatesBefore={yesterday}
                        disableDatesAfter={nextMonth}
                        onChange={onDateChange}
                        onMonthChange={handleMonthChange}
                        selected={{ start: selectedDates, end: selectedDates }}
                    />
                </div>
            </Popover>

            <Popover
                preferredPosition="above"
                active={timePopoverActive}
                activator={timeActivator}
                onClose={toggleTimePopoverActive}
            >
                <div style={{ minWidth: '120px' }}>
                    <ResourceList
                        items={filteredTimeList()}
                        renderItem={(time) => (
                            <ResourceList.Item
                                id={time}
                                onClick={() => onTimeSelect(time)}
                            >
                                {time}
                            </ResourceList.Item>
                        )}
                    />
                </div>
            </Popover>
            {today.toTimeString().slice(9)
                ? today.toTimeString().slice(9)
                : null}
        </InlineStack>
    );
};
