import { BlockStack, ProgressBar, Text } from "@shopify/polaris";

type OnboardingWizardHeaderProps = {
    completionPercentage: number;
};

export default function OnboardingWizardHeader({
    completionPercentage,
}: OnboardingWizardHeaderProps) {
    return (
        <div className="onboarding-wizard-modal__header">
            <BlockStack gap="500">
                <h1>Get started with Videeo</h1>
                <BlockStack gap="200">
                    <ProgressBar
                        progress={completionPercentage}
                        size="small"
                        animated
                        tone="highlight"
                    />
                    <Text as="p" tone="subdued">
                        {completionPercentage}% Complete
                    </Text>
                </BlockStack>
            </BlockStack>
        </div>
    );
}