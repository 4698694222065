import { proxy, subscribe } from 'valtio';
import shopStore from './shopStore';

const installEmbedStore = proxy({
    isCreatingLiveShoppingPage: false,
    isInstallingEmbed: false,
    isCreatingCsShop: false,
    isActivateSnippetCardOpen: false,
    isLivePlayerInstallCardOpen: false,
    isActivatingSnippet: false,
    setIsActivateSnippetCardOpen: (isOpen: boolean) => {
        installEmbedStore.isActivateSnippetCardOpen = isOpen;
        if (isOpen) {
            installEmbedStore.isLivePlayerInstallCardOpen = false;
        }
    },
    setIsLivePlayerInstallCardOpen: (isOpen: boolean) => {
        installEmbedStore.isLivePlayerInstallCardOpen = isOpen;
        if (isOpen) {
            installEmbedStore.isActivateSnippetCardOpen = false;
        }
    },
});

subscribe(shopStore, () => {
    if (!shopStore.embedEnabled) {
        installEmbedStore.setIsActivateSnippetCardOpen(true);
    } else {
        installEmbedStore.setIsLivePlayerInstallCardOpen(true);
    }
});

export default installEmbedStore;
