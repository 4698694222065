import {
    BlockStack,
    Box,
    FooterHelp,
    Layout,
    Link,
    Page,
    Text,
    Loading,
    InlineStack,
    Button,
    PageProps,
    Frame,
} from '@shopify/polaris';
import { useLocation } from 'react-router-dom';
import { ArrowLeftIcon } from '@shopify/polaris-icons';
import { PropsWithChildren, useState } from 'react';
import { mobileRoutes, vfsRoutes } from '@/constants/routes.constants';
import ZendeskSupportWidget from '../common/ZendeskSupportWidget';

export interface BaseLayoutProps extends PageProps {
    isLoading?: boolean;
    buttonLabel?: string;
    isSelectPlanPage?: boolean;
    isNewSelectPlanPage?: boolean;
    onHandleButton?: () => void;
}

const BaseLayout = (props: PropsWithChildren<BaseLayoutProps>) => {
    const location = useLocation();
    const [animationActive, setAnimationActive] = useState(false);

    const { pathname } = location;
    const showBackArrow =
        pathname == vfsRoutes.managePlans ||
        pathname == mobileRoutes.setup ||
        pathname == mobileRoutes.composePushNotification;

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    };

    const onCheckOutClicked = () => {
        props.onHandleButton && props.onHandleButton();
        setAnimationActive(true);
        setTimeout(() => {
            setAnimationActive(false);
        }, 700);
    };

    return (
        <Page fullWidth>
            {props.isLoading && (
                <Frame>
                    <Loading />
                </Frame>
            )}
            {!props.isLoading && (
                <Box>
                    <Layout>
                        <Layout.Section>
                            <BlockStack inlineAlign="center">
                                <Box
                                    width={
                                        props.isNewSelectPlanPage
                                            ? 'min(90rem, 100%)'
                                            : 'min(60rem, 100%)'
                                    }
                                >
                                    <BlockStack gap="800">
                                        <BlockStack>
                                            <InlineStack
                                                align="space-between"
                                                blockAlign="center"
                                            >
                                                <InlineStack
                                                    blockAlign="center"
                                                    align="start"
                                                    gap="200"
                                                >
                                                    {showBackArrow ? (
                                                        <Button
                                                            icon={ArrowLeftIcon}
                                                            accessibilityLabel="Back to landing page"
                                                            size="large"
                                                            variant="monochromePlain"
                                                            url={
                                                                pathname ==
                                                                mobileRoutes.composePushNotification
                                                                    ? mobileRoutes.notification
                                                                    : vfsRoutes.landing
                                                            }
                                                        />
                                                    ) : null}
                                                    <Text
                                                        as="h1"
                                                        variant="heading2xl"
                                                    >
                                                        {props.title}
                                                    </Text>
                                                </InlineStack>
                                                {props.isSelectPlanPage && (
                                                    <InlineStack
                                                        align="end"
                                                        blockAlign="center"
                                                        gap="200"
                                                    >
                                                        {props.isNewSelectPlanPage ? (
                                                            <button
                                                                className={`bubbly-button ${animationActive ? 'animate' : ''}`}
                                                                onClick={onCheckOutClicked}
                                                            >
                                                                Checkout
                                                            </button>
                                                        ) : (
                                                            <Button
                                                                variant="primary"
                                                                onClick={scrollToBottom}
                                                            >
                                                                Continue Setup
                                                            </Button>
                                                        )}
                                                    </InlineStack>
                                                )}
                                            </InlineStack>
                                            {props.subtitle && (
                                                <Box
                                                    paddingInlineStart={
                                                        showBackArrow
                                                            ? '800'
                                                            : '0'
                                                    }
                                                >
                                                    <Text
                                                        as="h2"
                                                        variant="bodyLg"
                                                    >
                                                        {props.subtitle}
                                                    </Text>
                                                </Box>
                                            )}
                                        </BlockStack>

                                        {props.children}
                                        <Box insetBlockEnd="0">
                                            <FooterHelp>
                                                <BlockStack
                                                    gap="100"
                                                    inlineAlign="center"
                                                >
                                                    {pathname ==
                                                    vfsRoutes.managePlans ? (
                                                        <Text as="p">
                                                            *Discounts not
                                                            reflected on this
                                                            page. Any previously
                                                            applied discounts
                                                            will not be applied
                                                            to a new plan.
                                                        </Text>
                                                    ) : null}
                                                    <Text as="p">
                                                        Need help at any step?
                                                        Reach out to{' '}
                                                        <Link url="mailto:support@videeo.live">
                                                            support@videeo.live
                                                        </Link>{' '}
                                                        |{' '}
                                                        <Link url="https://help.videeo.live">
                                                            help.videeo.live
                                                        </Link>
                                                    </Text>
                                                </BlockStack>
                                            </FooterHelp>
                                        </Box>
                                    </BlockStack>
                                </Box>
                                <ZendeskSupportWidget />
                            </BlockStack>
                        </Layout.Section>
                    </Layout>
                </Box>
            )}
        </Page>
    );
};
export default BaseLayout;
