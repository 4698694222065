import { billingStore } from "@/stores";
import { Button, Icon, InlineGrid, InlineStack, TextField } from "@shopify/polaris";
import { CheckboxIcon } from "@shopify/polaris-icons";
import { useSnapshot } from "valtio";

type PlanDiscountInputProps = {
    shouldShowDiscountInput: boolean;
    isDisabled: boolean;
    toggleDiscountInput: (value: boolean) => void;
};

export default function PlanDiscountInput({
    shouldShowDiscountInput,
    toggleDiscountInput,
    isDisabled,
}: PlanDiscountInputProps) {
    const { discountCode, isDiscountCodeApplied } = useSnapshot(billingStore);

    return (
        <InlineStack gap="200" align="end" wrap={false}>
            {shouldShowDiscountInput ? (
                <InlineGrid
                    gap="300"
                    columns="1fr auto auto"
                    alignItems="center"
                >
                    <TextField
                        label
                        labelHidden
                        autoComplete="off"
                        placeholder="Enter coupon code"
                        size="medium"
                        value={discountCode}
                        onChange={(value) =>
                            (billingStore.discountCode = value)
                        }
                        clearButton
                        onClearButtonClick={
                            billingStore.removeAppliedDiscountCode
                        }
                        disabled={isDiscountCodeApplied}
                        prefix={
                            isDiscountCodeApplied && (
                                <Icon source={CheckboxIcon} tone="success" />
                            )
                        }
                    />
                    <Button
                        variant="primary"
                        tone={isDiscountCodeApplied ? 'success' : undefined}
                        onClick={
                            isDiscountCodeApplied
                                ? billingStore.removeAppliedDiscountCode
                                : billingStore.applyDiscountCode
                        }
                        disabled={discountCode.length == 0}
                    >
                        {isDiscountCodeApplied ? 'Remove' : 'Apply'}
                    </Button>
                    <Button
                        variant="plain"
                        onClick={() => {
                            billingStore.removeAppliedDiscountCode();
                            toggleDiscountInput(false);
                        }}
                    >
                        Cancel
                    </Button>
                </InlineGrid>
            ) : (
                <Button
                    variant="plain"
                    tone="success"
                    disabled={isDisabled}
                    onClick={() => toggleDiscountInput(true)}
                >
                    Have a Coupon Code?
                </Button>
            )}
        </InlineStack>
    );
}