import {
    BlockStack,
    Box,
    Divider,
    Text,
    Link,
    SkeletonBodyText,
    InlineStack,
    Button,
} from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import { buildMobileAppStore } from '@/stores';
import {
    CollapsibleCardWithTitleSection,
    AppCheckBox,
} from '@/components/common';
import { useUpdateAppSetup } from '@/hooks/useUpdateAppSetup';
import { formatDate } from '@/utils/formatDate';
import { useNavigate } from 'react-router-dom';
import { mobileRoutes } from '@/constants/routes.constants';

const IOSChecklist = () => {
    const navigate = useNavigate();
    const { updateAppSetup } = useUpdateAppSetup();
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });
    const handleStepToggle = async (step: string, checked: boolean) => {
        const previousValue = buildMobileAppStore[step];
        buildMobileAppStore[step] = checked ? Date.now() : 0;
        try {
            await updateAppSetup({
                dunsCompletedAt: buildMobileAppStore.dunsCompletedAt || 0,
                addDevEmailCompletedAt:
                    buildMobileAppStore.addDevEmailCompletedAt || 0,
                iosDevCompletedAt: buildMobileAppStore.iosDevCompletedAt || 0,
            });
        } catch (e) {
            buildMobileAppStore[step] = previousValue;
            shopify.toast.show('Failed to save step. Please try again or contact support.', {
                isError: true,
                duration: 5000,
            });
        }
    };
    const {
        dunsCompletedAt,
        addDevEmailCompletedAt,
        iosDevCompletedAt,
        isAppSetupLoading,
    } = buildMobileAppSnapshot;
    const handleNext = () => {
        if (
            buildMobileAppStore.dunsCompletedAt &&
            buildMobileAppStore.iosDevCompletedAt &&
            buildMobileAppStore.addDevEmailCompletedAt
        ) {
            buildMobileAppStore.setIsSupportInfoCardOpen(true);
            return;
        } else {
            buildMobileAppStore.hasDunsCompletedAtErr();
            buildMobileAppStore.hasIosDevCompletedAtErr();
            buildMobileAppStore.hasAddDevEmailCompletedAtErr();
            return;
        }
    };
    const redirectToAppConfig = () => {
        navigate(mobileRoutes.settings);
    }
    return (
        <CollapsibleCardWithTitleSection
            title="Videeo App Request Checklist"
            subtitle="Please note that mobile apps are only available if you are an LLC."
            subtitleTwo="To get started, there are a few things you need to complete. Use this checklist to ensure you have everything you need to get your app created."
            showCheck={false}
            open={buildMobileAppSnapshot.isIOSChecklistCardOpen}
            onToggleOpen={buildMobileAppStore.setIsIOSChecklistCardOpen}
            hideSubtitleWhenClosed={true}
            hideSubtitleTwoWhenClosed={true}
        >
            <Box width="56rem" paddingBlockEnd="600">
                <BlockStack gap="600">
                    <Divider />
                </BlockStack>
            </Box>

            {isAppSetupLoading || buildMobileAppSnapshot.isAppleEmailLoading ? (
                <SkeletonBodyText />
            ) : (
                <>
                    <AppCheckBox
                        disabled={buildMobileAppSnapshot.isAppRequestSubmitted}
                        label={
                            <Text as="p">
                                Create a DUNS number
                                <Text as="span" tone="critical">
                                    {' '}
                                    *
                                </Text>
                            </Text>
                        }
                        helperText={
                            <BlockStack>
                                <Text as="p">
                                    If you don’t know your DUNs number, or don’t
                                    have one, you can search or apply for one{' '}
                                    <Link url="https://www.dnb.com/duns-number/lookup.html">
                                        here
                                    </Link>
                                </Text>
                            </BlockStack>
                        }
                        checked={!!dunsCompletedAt}
                        handleChange={(checked) => {
                            handleStepToggle('dunsCompletedAt', checked);
                            buildMobileAppStore.hasDunsCompletedAtErr();
                        }}
                        badgeText={
                            dunsCompletedAt
                                ? `Completed: ${formatDate(dunsCompletedAt)}`
                                : 'Incomplete'
                        }
                        badgeTextColor={dunsCompletedAt ? '#FFFFFF' : '#000'}
                        badgeBGColor={dunsCompletedAt ? '#181A1C' : '#EAECEE'}
                        error={buildMobileAppSnapshot.dunsCompletedAtErr}
                    />
                    <AppCheckBox
                        disabled={buildMobileAppSnapshot.isAppRequestSubmitted}
                        label={
                            <Text as="p">
                                Create an IOS dev account
                                <Text as="span" tone="critical">
                                    {' '}
                                    *
                                </Text>
                            </Text>
                        }
                        helperText={
                            <BlockStack>
                                <Text as="p">
                                    Click{' '}
                                    <Link url="https://developer.apple.com/programs/enroll">
                                        here
                                    </Link>{' '}
                                    and follow the steps to enroll as an{' '}
                                    <Text as="span" fontWeight="semibold">
                                        <em>organization</em>
                                    </Text>{' '}
                                    to begin Apple’s dev account creation
                                    process.
                                </Text>
                                <Text as="p">
                                    Requirements include: a custom domain and
                                    professional email address.
                                </Text>
                            </BlockStack>
                        }
                        checked={!!iosDevCompletedAt}
                        handleChange={(checked) => {
                            handleStepToggle('iosDevCompletedAt', checked);
                            buildMobileAppStore.hasIosDevCompletedAtErr();
                        }}
                        badgeText={
                            iosDevCompletedAt
                                ? `Completed: ${formatDate(iosDevCompletedAt)}`
                                : 'Incomplete'
                        }
                        badgeTextColor={iosDevCompletedAt ? '#FFFFFF' : '#000'}
                        badgeBGColor={iosDevCompletedAt ? '#181A1C' : '#EAECEE'}
                        error={buildMobileAppSnapshot.iosDevCompletedAtErr}
                    />
                    <AppCheckBox
                        disabled={buildMobileAppSnapshot.isAppRequestSubmitted}
                        label={
                            <Text as="p">
                                Add CS Dev email
                                <Text as="span" tone="critical">
                                    {' '}
                                    *
                                </Text>
                            </Text>
                        }
                        helperText={
                            <BlockStack gap="300">
                                <Text as="p">
                                    Invite the Mobile app team by adding this
                                    email{' '}
                                    <strong>
                                        &#123;
                                        {
                                            buildMobileAppSnapshot.rotatingAppleEmail
                                        }
                                        &#125;
                                    </strong>{' '}
                                    to the Users section, to provide CS access
                                    on your Apple Developer Account.
                                </Text>
                                <Text as="p">
                                    On your Apple Developer Account, when
                                    inviting the CS development team to access
                                    the account, please use the following values
                                    in the required fields:
                                </Text>
                                <BlockStack>
                                    <Text as="p">First Name: Mobile</Text>
                                    <Text as="p">Last Name: Team</Text>
                                    <Text as="p">Access: Admin</Text>
                                </BlockStack>
                            </BlockStack>
                        }
                        checked={!!addDevEmailCompletedAt}
                        handleChange={(checked) => {
                            handleStepToggle('addDevEmailCompletedAt', checked);
                            buildMobileAppStore.hasAddDevEmailCompletedAtErr();
                        }}
                        badgeText={
                            addDevEmailCompletedAt
                                ? `Completed: ${formatDate(
                                      addDevEmailCompletedAt,
                                  )}`
                                : 'Incomplete'
                        }
                        badgeTextColor={
                            addDevEmailCompletedAt ? '#FFFFFF' : '#000'
                        }
                        badgeBGColor={
                            addDevEmailCompletedAt ? '#181A1C' : '#EAECEE'
                        }
                        error={buildMobileAppSnapshot.addDevEmailCompletedAtErr}
                    />
                    {/* <Card background="bg-surface-secondary">
                        <BlockStack gap="200">
                            <Text
                                variant="headingMd"
                                as="h5"
                                fontWeight="medium"
                            >
                                Mobile App Preview (Video Preview)
                            </Text>
                            <Text as="p">
                                Take a Videeo mobile app builder for a spin to
                                see your branded mobile app live in action.
                            </Text>
                            <InlineStack align="start">
                                <Button
                                    size="large"
                                    onClick={redirectToAppConfig}
                                >
                                    Start Building Your Mobile App
                                </Button>
                            </InlineStack>
                        </BlockStack>
                    </Card> */}
                    <Box paddingBlock="600">
                        <Divider borderColor="border" />
                    </Box>
                    <InlineStack align="end">
                        <Button variant="primary" onClick={handleNext}>
                            Next
                        </Button>
                    </InlineStack>
                </>
            )}
        </CollapsibleCardWithTitleSection>
    );
};

export default IOSChecklist;
