import { shopStore, videoLiveSellingStore } from '@/stores';
import { BlockStack, Box, Button, ButtonGroup, Icon, InlineStack, Link, Spinner, Text, TextField } from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import AppVideoPicker from '@/components/common/AppVideoPicker';
import PopClipsCard from '@/components/common/PopClipsCard';
import { useEffect, useState } from 'react';
import { popclipsRoutes } from '@/constants/routes.constants';
import { useNavigate } from 'react-router-dom';
import { LockFilledIcon } from '@shopify/polaris-icons';
import { generateFileFromInstagramUrl, importInstagramReel } from '@/utils/InstagramReelImporter';
import { PlanUpgradeAlertModal } from '@/components/common/PlanUpgradeAlertModal';

const ClipsFormCard = () => {
    const [fromInstagram, setFromInstagram] = useState(false);
    const [isImportingInstaReel, setIsImportingInstaReel] = useState(false);
    const [shouldShowInstaImport, setShouldShowInstaImport] = useState(false);
    const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);
    const { subscription } = useSnapshot(shopStore);

    const navigate = useNavigate();
    const {
        clipTitle,
        clipTitleErr,
        savingPopclip,
        clipInstaUrl,
        clipInstaUrlErr,
        videoUrl,
        editClipData,
    } = useSnapshot(videoLiveSellingStore, {
        sync: true,
    });

    useEffect(() => {
        const gatingData = shopStore.featureGatingData?.popclips;
        if (gatingData && gatingData?.limitAccess) {
            navigate(popclipsRoutes.list);
        }
    }, []);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices('popclips', true);
        const maxPrice = Math.max(...addonPrices);
        setShouldShowInstaImport(maxPrice >= 29.99);
    }, [subscription]);

    const getInstagramGetUrl = async () => {
        setIsImportingInstaReel(true);

        try {
            const links = await importInstagramReel(clipInstaUrl);
            if (links && links.url_list && links.url_list.length > 0) {
                videoLiveSellingStore.videoUrl = links.url_list[0];

                const instaVideoFile = await generateFileFromInstagramUrl(links.url_list[0]);
                const formData = new FormData();
                const fileName = instaVideoFile.name;
                const sanitizedName = `${fileName.slice(0, -3).replace(/[^a-zA-Z0-9]/g, '')}.${fileName.slice(-3)}`;
                formData.append('file', instaVideoFile, sanitizedName);

                videoLiveSellingStore.dropzoneFile = formData;
            } else {
                shopify.toast.show('Invalid Instagram URL', {
                    isError: true,
                    duration: 5000,
                });
            }
        } catch (error) {
            console.error(error);
            shopify.toast.show('Invalid Instagram URL', {
                isError: true,
                duration: 5000,
            });
        } finally {
            setIsImportingInstaReel(false);
        }
    };

    const onRemoveInstaVideo = () => {
        videoLiveSellingStore.dropzoneFile = null;
        videoLiveSellingStore.videoUrl = '';
        videoLiveSellingStore.clipInstaUrl = '';
    }

    return (
        <PopClipsCard>
            <BlockStack gap="300">
                <TextField
                    label="Clip Title:"
                    placeholder="Enter Clip Title"
                    autoComplete="off"
                    value={clipTitle}
                    onChange={(value) => {
                        videoLiveSellingStore.setClipTitle(value);
                        videoLiveSellingStore.hasClipTitleErr();
                    }}
                    onFocus={() => {
                        videoLiveSellingStore.clipTitleErr = '';
                    }}
                    error={clipTitleErr}
                    disabled={savingPopclip}
                />
                <Box padding="200" paddingBlockEnd="0">
                    <InlineStack align="center">
                        <ButtonGroup variant="segmented">
                            <Button
                                pressed={fromInstagram}
                                icon={
                                    !shouldShowInstaImport ? (
                                        <Icon
                                            source={LockFilledIcon}
                                            tone="base"
                                        />
                                    ) : undefined
                                }
                                onClick={() =>
                                    shouldShowInstaImport
                                        ? setFromInstagram(true)
                                        : setShowPlanUpgradeModal(true)
                                }
                            >
                                Import from Instagram
                            </Button>
                            <Button
                                pressed={!fromInstagram}
                                onClick={() => setFromInstagram(false)}
                            >
                                Upload from Device
                            </Button>
                        </ButtonGroup>
                    </InlineStack>
                </Box>
                {fromInstagram ? (
                    <BlockStack gap="200" inlineAlign="center">
                        <Box width="32rem" paddingBlockStart="200">
                            <Text as="p" tone="subdued" alignment="center">
                                By clicking on import, you certify that you have
                                obtained all necessary permissions to share this
                                content and confirm your agreement with our{' '}
                                <Link
                                    url="https://commentsold.com/legal/main-terms"
                                    target="_blank"
                                    removeUnderline
                                >
                                    <Text
                                        as="span"
                                        tone="magic"
                                        fontWeight="semibold"
                                    >
                                        Main Services Agreement
                                    </Text>
                                </Link>
                            </Text>
                        </Box>
                        <Box width="100%">
                            <TextField
                                label="Paste Video Link:"
                                placeholder="Paste your Instagram Video Link here"
                                autoComplete="off"
                                value={clipInstaUrl}
                                onChange={(value) => {
                                    videoLiveSellingStore.clipInstaUrl = value;
                                    videoLiveSellingStore.hasInstaUrlErr();
                                }}
                                error={clipInstaUrlErr}
                                disabled={
                                    videoUrl != '' ||
                                    savingPopclip ||
                                    isImportingInstaReel
                                }
                                connectedRight={
                                    <InlineStack blockAlign="center">
                                        {isImportingInstaReel && (
                                            <Box paddingInline="200">
                                                <Spinner size="small" />
                                            </Box>
                                        )}
                                        {!editClipData && videoUrl ? (
                                            <Button
                                                tone="critical"
                                                variant="primary"
                                                onClick={onRemoveInstaVideo}
                                                disabled={
                                                    savingPopclip ||
                                                    isImportingInstaReel
                                                }
                                            >
                                                Delete & import a new clip
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="primary"
                                                onClick={getInstagramGetUrl}
                                                disabled={
                                                    clipInstaUrl == '' ||
                                                    clipInstaUrlErr != '' ||
                                                    savingPopclip ||
                                                    isImportingInstaReel
                                                }
                                            >
                                                Import
                                            </Button>
                                        )}
                                    </InlineStack>
                                }
                            />
                        </Box>
                    </BlockStack>
                ) : (
                    <AppVideoPicker
                        labelStr="Upload Video"
                        videoUrl={videoUrl}
                        hintText="Maximum upload file size: 100MB"
                    />
                )}
            </BlockStack>
            {showPlanUpgradeModal && (
                <PlanUpgradeAlertModal
                    showPlanUpgradeModal={showPlanUpgradeModal}
                    handleCloseModal={() => setShowPlanUpgradeModal(false)}
                    type="POPCLIPS_BASIC_PLAN"
                />
            )}
        </PopClipsCard>
    );
};

export default ClipsFormCard;
