import { Box, Button, Icon, InlineStack, Text } from '@shopify/polaris';
import {
    InfoIcon,
    MaximizeIcon,
    MenuHorizontalIcon,
    MinimizeIcon,
} from '@shopify/polaris-icons';

interface DashboardHeaderSectionProps {
    title: string;
    showResizeButton: boolean;
    isExpand: boolean;
    handleResizeChart: () => void;
}

const DashboardHeaderSection = ({
    title,
    showResizeButton = false,
    isExpand = false,
    handleResizeChart,
}: DashboardHeaderSectionProps) => {
    return (
        <Box paddingBlockEnd="400">
        <InlineStack align="space-between" blockAlign="center">
            <InlineStack gap="200" blockAlign="center">
                <div style={{
                    fontFamily: 'clashDisplay',
                    fontSize: '20px',
                    fontWeight: 600,
                    lineHeight: '30px',
                }}>
                    {title}
                </div>
                {/* <Box>
                    <Icon source={InfoIcon} tone="base" />
                </Box> */}
            </InlineStack>
            <InlineStack gap="100" blockAlign="center">
                {showResizeButton && (
                    <Button
                        icon={isExpand ? MinimizeIcon : MaximizeIcon}
                        onClick={handleResizeChart}
                    />
                )}
                {/* <Button icon={MenuHorizontalIcon} /> */}
            </InlineStack>
        </InlineStack>
        </Box>
    );
};

export default DashboardHeaderSection;
