import { useCallback } from 'react';
import { useVFSAuthFetch } from './useAuthenticatedFetch';

export const useUpdateAppSetup = () => {
    const fetch = useVFSAuthFetch();
    const updateAppSetup = useCallback(
        async (body: {
            dunsCompletedAt?: number;
            iosDevCompletedAt?: number;
            addDevEmailCompletedAt?: number;
        }) => {
            await fetch('/app-setup', {
                method: 'POST',
                body: JSON.stringify(body),
            });
        },
        [fetch],
    );
    return { updateAppSetup };
};
