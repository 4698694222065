import PopClipsCard from '@/components/common/PopClipsCard';
import { BlockStack, Box, TextField } from '@shopify/polaris';

type CarouselFormProps = {
    carouselId: string;
    title: string;
    handleTitle: (value: string) => void;
    isApiLoading: boolean;
};

export default function CarouselForm({
    carouselId,
    title,
    handleTitle,
    isApiLoading,
}: CarouselFormProps) {
    return (
        <PopClipsCard>
            <Box padding="200">
                <BlockStack gap="300">
                    {carouselId && carouselId?.length > 0 && (
                        <TextField
                            value={carouselId}
                            label="Carousel ID:"
                            autoComplete="off"
                            disabled={true}
                        />
                    )}
                    <TextField
                        value={title}
                        onChange={handleTitle}
                        label="Carousel Title:"
                        placeholder='Please make sure to use unique title for each carousel'
                        autoComplete="off"
                        disabled={isApiLoading}
                    />
                </BlockStack>
            </Box>
        </PopClipsCard>
    );
}
