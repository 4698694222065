import { mobileRoutes, vfsRoutes } from "@/constants/routes.constants";
import { buildMobileAppStore, shopStore } from "@/stores";
import { Icon, InlineStack, Link } from "@shopify/polaris";
import { ArrowDiagonalIcon } from "@shopify/polaris-icons";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { mobileAppCard } from "../../../assets/images";
import { useGetAppRequest } from "@/hooks/useGetAppRequest";

const LEARN_MORE_LINK = 'https://help.videeo.live/hc/en-us/categories/23184052620308';

export default function MobileAppCard() {
    const navigate = useNavigate();
    const { enabledFeatures } = useSnapshot(shopStore);
    const { getAppRequests } = useGetAppRequest();
    const { iosLink, androidLink, isAppRequestLoading, isAppRequestSubmitted } = useSnapshot(buildMobileAppStore);

    useEffect(() => {
        getAppRequests();
    }, []);

    const mobileAppButton = useMemo(() => {
        if (iosLink || androidLink) {
            return (
                <button
                    className="get-started-button"
                    onClick={() => navigate(mobileRoutes.notification)}
                    disabled={isAppRequestLoading}
                >
                    Manage <Icon source={ArrowDiagonalIcon} />{' '}
                </button>
            );
        }

        const btnText = isAppRequestSubmitted ? 'Approval Pending' : 'Set Up';

        return (
            <InlineStack gap="400" blockAlign="center">
                {enabledFeatures.includes('mobile') ? (
                    <button
                        className="get-started-button"
                        onClick={() => navigate(mobileRoutes.setup)}
                        disabled={isAppRequestLoading}
                    >
                        {btnText} <Icon source={ArrowDiagonalIcon} />{' '}
                    </button>
                ) : (
                    <button
                        className="get-started-button"
                        onClick={() => navigate(vfsRoutes.managePlans)}
                    >
                        Get Started <Icon source={ArrowDiagonalIcon} />{' '}
                    </button>
                )}
            </InlineStack>
        );
    }, [iosLink, androidLink, isAppRequestLoading, isAppRequestSubmitted]);

    return (
        <div className="mobile-app-card">
            <div className="card-shapes">
                <div className="square"></div>
                <div className="circle"></div>
            </div>
            <p className="card-title">
                <span>Mobile</span>
                <span>App</span>
            </p>
            <div className="card-content">
                <p>
                    Drive Loyalty with a tap-to-shop mobile app.{' '}
                    <Link url={LEARN_MORE_LINK}>Learn More</Link>
                </p>
                {mobileAppButton}
            </div>
            <div className="card-image">
                <video autoPlay muted loop style={{ width: '102px' }}>
                    <source src={mobileAppCard} type="video/webm" />
                </video>
            </div>
        </div>
    );
};
