import { ReactNode } from "react";
import { InlineStack } from "@shopify/polaris";
import '../../../assets/css/CreateAccountPage.css';

type CreateAccountLayoutProps = {
    children: ReactNode;
    cssStyle?: object;
};

export default function CreateAccountLayout({ children, cssStyle }: CreateAccountLayoutProps) {
    return (
        <InlineStack gap="400" align="center" blockAlign="center">
            <div className="create-account-card" style={cssStyle || undefined}>
                <div className="card-header">
                    <h2 className="card-title">Welcome to Videeo!</h2>
                </div>
                <div className="card-body">{children}</div>
            </div>
        </InlineStack>
    );
};