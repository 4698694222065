import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { shopStore } from '@/stores';
import { vfsRoutes } from '@/constants/routes.constants';

type ComponentProps = { children: React.ReactNode };

const RequireCommentsoldAccount = ({ children }: ComponentProps) => {
    const shopStoreSnap = useSnapshot(shopStore);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const redirectTo = vfsRoutes.createAccount;

    useEffect(() => {
        if (
            (
                !shopStoreSnap.csStoreCreated ||
                !shopStoreSnap.popshopliveAccountCreated
            ) && pathname !== redirectTo
        ) {
            navigate(redirectTo);
        }
    }, [
        shopStoreSnap.csStoreCreated,
        shopStoreSnap.popshopliveAccountCreated,
    ]);

    return <>{children}</>;
};

export default RequireCommentsoldAccount;
