import { Box, FormLayout, InlineStack, SkeletonBodyText, TextField } from '@shopify/polaris';
import { CardWithTitleSection } from '../common';
import { useSnapshot } from 'valtio';
import { mobileAppConfigStore } from '@/stores';
import { AppColorPicker } from '../common';
import MobileHomePage from './mobile-preview-screens/MobileHomePage';
import { Modal, TitleBar } from '@shopify/app-bridge-react';
import { useState } from 'react';

const BannerCard = () => {
    const [openModal, setOpenModal] = useState(false);
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    return (
        <CardWithTitleSection
            title="Marketing Banner"
            subtitle="Take control of your app with these customizable features."
        >
            {mobileAppConfigSnap.isLoading ? (
                <SkeletonBodyText />
            ) : (
                <Box>
                    <FormLayout>
                        <TextField
                            autoComplete="off"
                            label="Main Title"
                            placeholder="Enter headline here"
                            maxLength={50}
                            value={mobileAppConfigSnap.bannerTitle}
                            onChange={(value) => {
                                mobileAppConfigStore.bannerTitle = value;
                            }}
                        />
                        <TextField
                            autoComplete="off"
                            label="Sub-Title"
                            placeholder="Enter subtitle here"
                            maxLength={50}
                            value={mobileAppConfigSnap.bannerText}
                            onChange={(value) => {
                                mobileAppConfigStore.bannerText = value;
                            }}
                        />
                    </FormLayout>
                    <AppColorPicker
                        required={true}
                        label={'Banner Background Color'}
                        hexColor={mobileAppConfigSnap.bannerColor}
                        onHexColorChange={(val: string) => {
                            mobileAppConfigStore.bannerColor = val;
                        }}
                        validationError={
                            !mobileAppConfigSnap.isBannerColorValid &&
                            mobileAppConfigSnap.showFormErrorLabel
                                ? 'Required field'
                                : ''
                        }
                        previewButton
                        onPreviewButtonClick={() => setOpenModal(true)}
                    />
                    <Modal open={openModal} variant="base" onHide={() => setOpenModal(false)}>
                        <TitleBar title="Marketing Banner Preview">
                            <button variant="primary" onClick={() => setOpenModal(false)}>
                                Close
                            </button>
                        </TitleBar>
                        <Box padding="400">
                            <InlineStack align='center'>
                                <MobileHomePage isMarketingBanner />
                            </InlineStack>
                        </Box>
                    </Modal>
                </Box>
            )}
        </CardWithTitleSection>
    );
};

export default BannerCard;
