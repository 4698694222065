import { useCallback } from 'react';
import { useVFSAuthFetch } from './useAuthenticatedFetch';

type Params = {
    collectionId: string;
    primaryColor: string;
    bannerColor: string;
    secondaryColor: string;
    font?: string;
    bannerTitle?: string;
    bannerText?: string;
    headerLogoUrl?: string;
    liveBannerTitle?: string;
    liveBannerText?: string;
    liveBannerColor: string;
    menuHandle: string;
    shopMenuHandle: string;
    customLinksHandle: string;
    showInventoryLimit: number;
};
export const useUpdateAppConfig = () => {
    const fetch = useVFSAuthFetch();
    const updateAppConfig = useCallback(
        async ({
            collectionId,
            primaryColor,
            bannerColor,
            secondaryColor,
            font,
            bannerTitle,
            bannerText,
            headerLogoUrl,
            liveBannerTitle,
            liveBannerText,
            liveBannerColor,
            menuHandle,
            shopMenuHandle,
            customLinksHandle,
            showInventoryLimit,
        }: Params) => {
            const response = await fetch('app-config', {
                method: 'POST',
                body: JSON.stringify({
                    featuredShopifyCollectionGid: collectionId,
                    primaryColor: primaryColor,
                    bannerColor: bannerColor,
                    secondaryColor: secondaryColor,
                    font: font ? font : '',
                    bannerTitle: bannerTitle ? bannerTitle : '',
                    bannerText: bannerText ? bannerText : '',
                    headerLogoUrl: headerLogoUrl ? headerLogoUrl : '',
                    liveBannerTitle: liveBannerTitle ? liveBannerTitle : '',
                    liveBannerText: liveBannerText ? liveBannerText : '',
                    liveBannerColor: liveBannerColor,
                    menuHandle: menuHandle ? menuHandle : '',
                    shopMenuHandle: shopMenuHandle ? shopMenuHandle : '',
                    customLinksHandle: customLinksHandle ? customLinksHandle : '',
                    showInventoryLimit: showInventoryLimit,
                }),
            });
            return response;
        },
        [fetch],
    );
    return { updateAppConfig };
};
