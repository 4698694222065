import {
    BlockStack,
    Box,
    Button,
    Divider,
    FormLayout,
    InlineStack,
    SkeletonBodyText,
    TextField,
} from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import { buildMobileAppStore } from '@/stores';

import { CollapsibleCardWithTitleSection } from '@/components/common';

const SupportInformation = () => {
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });
    const { isAppRequestLoading, isSupportInfoCardOpen } =
        buildMobileAppSnapshot;
    const addHttp = () => {
        if (!buildMobileAppStore.supportURL.startsWith('http')) {
            buildMobileAppStore.setSupportURL(
                'https://' + buildMobileAppStore.supportURL,
            );
        }
    };
    const handleNext = () => {
        const { supportURL } = buildMobileAppStore;
        if (supportURL && buildMobileAppStore.isSupportURLValid) {
            buildMobileAppStore.supportURLErr = '';
            buildMobileAppStore.setIsAppNameCardOpen(true);
            return;
        } else {
            buildMobileAppStore.hasSupportURLErr();
            return;
        }
    };
    return (
        <CollapsibleCardWithTitleSection
            title="Support Information"
            subtitle="Let us know how your customers receive support from you."
            showCheck={false}
            open={isSupportInfoCardOpen}
            onToggleOpen={buildMobileAppStore.setIsSupportInfoCardOpen}
            hideSubtitleWhenClosed={true}
        >
            <Box width="56rem" paddingBlockEnd="600">
                <BlockStack gap="600">
                    <Divider />
                </BlockStack>
            </Box>
            {isAppRequestLoading ? (
                <SkeletonBodyText />
            ) : (
                <BlockStack gap={400}>
                    <Box>
                        <FormLayout>
                            <TextField
                                readOnly={
                                    buildMobileAppSnapshot.isAppRequestSubmitted
                                }
                                autoComplete="off"
                                requiredIndicator
                                label="Your Shop Support URL"
                                placeholder="www.yourshop.com"
                                maxLength={50}
                                value={buildMobileAppSnapshot.supportURL}
                                onBlur={addHttp}
                                onChange={(value) => {
                                    buildMobileAppStore.supportURLErr = '';
                                    buildMobileAppStore.setSupportURL(value);
                                    buildMobileAppStore.hasSupportURLErr();
                                }}
                                error={buildMobileAppSnapshot.supportURLErr}
                            />
                        </FormLayout>
                    </Box>
                    <InlineStack align="end">
                        <Button
                            variant="primary"
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    </InlineStack>
                </BlockStack>
            )}
        </CollapsibleCardWithTitleSection>
    );
};

export default SupportInformation;
