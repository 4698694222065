import { proxy } from 'valtio';
import shopStore from './shopStore';
import { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';

export type PopclipsCarouselItem = {
    updated_at: number;
    carousel_id: string;
    created_at: number;
    timestamp: number;
    customer_id: string;
    carousel_contents: string[];
    type_with_prefix: string;
    carousel_title: string;
    carousel_description: string;
    type: string;
    is_published: boolean;
    carousel_type: string;
    carousel_type_ids: string[];
};

interface PopclipsStoreState {
    popclipsCarousels: PopclipsCarouselItem[];
    carouselToEdit: PopclipsCarouselItem;
    carouselCurrentPage: number;
    carouselLastEvalKey: {
        [key: string]:
            | {
                  id: string;
                  fileTypeAndIdKey: string;
                  metaIdentifier: string;
              }
            | undefined;
    };
    clipsForEditCarousel: ListMediaByIdResponse[];
    getPopclipsCarousels: () => Promise<void>;
    saveCustomCarousel: (carouselData: any, isEdit: boolean) => Promise<{carousel_id: string}>;
    handleEditCarousel: (
        carousel: PopclipsCarouselItem,
        MGAuthFetch: any,
    ) => Promise<void>;
    getNewCarouselID: (customerId: string) => Promise<{carousel_id: string}>;
}

const popclipsStore: PopclipsStoreState = proxy<PopclipsStoreState>({
    carouselCurrentPage: 1,
    carouselLastEvalKey: {},
    popclipsCarousels: [],
    clipsForEditCarousel: [],
    carouselToEdit: undefined as unknown as PopclipsCarouselItem,
    getPopclipsCarousels: async () => {
        if (!shopStore.popshopliveAccountCreated) return;

        try {
            const response = await fetch(
                `/api/popclips/carousels?customerId=${shopStore.shopifyDomain}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                },
            );

            if (!response.ok)
                throw new Error('Error fetching popclips carousels');

            const result = await response.json();
            popclipsStore.popclipsCarousels = result.data;
        } catch (error) {
            console.error('Error fetching popclips carousels', error);
        }
    },
    handleEditCarousel: async (
        carousel: PopclipsCarouselItem,
        MGAuthFetch: any,
    ) => {
        try {
            const { data } = await MGAuthFetch('/listMediabyTypeAndMetaId', {
                method: 'POST',
                body: JSON.stringify({
                    metaIds: carousel.carousel_type_ids,
                    sellerId: shopStore.popshopliveSellerId,
                }),
            });

            popclipsStore.carouselToEdit = carousel;
            popclipsStore.clipsForEditCarousel = data;
        } catch (error) {
            console.error('Error fetching carousel clips:', error);
        }
    },
    saveCustomCarousel: async (carouselData: any, isEdit = false) => {
        try {
            const response = await fetch('/api/popclips/carousels', {
                method: isEdit ? 'PUT' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(carouselData),
            });

            const result = await response.json();
            if (!response.ok) throw new Error(result.message);

            return result.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getNewCarouselID: async (customerId) => {
        try {
            const response = await fetch(`/api/popclips/carousels?customerId=${customerId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            const result = await response.json();
            if (!response.ok) throw new Error(result.message);

            return result.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
});

export default popclipsStore;
