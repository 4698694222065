import {
    BlockStack,
    Box,
    Button,
    Card,
    Grid,
    IndexTable,
    InlineStack,
    Pagination,
    SkeletonBodyText,
    Text,
    useIndexResourceState,
} from '@shopify/polaris';
import DashboardHeaderSection from './DashboardHeaderSection';
import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { shopStore } from '@/stores';
import { NamingObjectTypes, PopclipsStaticsTypes } from './dashboardTypes';
import VfsModal from '../../v2/Components/VfsModal';
import { commaSeprateValue } from '@/utils/MoneyFormatter';

const modalContetToDisplay = [
    'popclip_title',
    'total_orders',
    'total_sales',
    'total_add_to_carts',
    'created_at',
    'sales_influenced_amount',
    'sales_indirect_amount',
    'shop_id',
    'sales_direct_amount',
    'total_clicks',
    'total_views',
];

const spanGap = '100';
const rowSpanStyle = { fontSize: '14px', padding: '8px' };

const DashboardPopclipStatsTable = ({
    namingObject,
}: {
    namingObject: NamingObjectTypes;
}) => {
    const [isModalOpen, setIsOpenModal] = useState(false);
    const [popclipsDetailsToDisplay, setPopclipsDetailsToDisplay] =
        useState<PopclipsStaticsTypes | null>(null);
    const [popclipsStatData, setPopclipsStatData] = useState([]);
    const [isExpand, setIsExpand] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        items: [],
        currentCursor: null, // Cursor for the current page
        nextCursor: null, // Cursor for the next page
        hasNextPage: false,
        hasPreviousPage: false,
        limit: 5, // Number of items per page
    });

    const [cursorHistory, setCursorHistory] = useState([]); // Track previous cursors
    const { shopifyDomain, popclipsServiceApi } = useSnapshot(shopStore);

    const handleOpenPopclipDetails = (details: PopclipsStaticsTypes) => {
        setPopclipsDetailsToDisplay(details);
        setIsOpenModal(true);
    };

    const ClosePoplipDetailModal = () => {
        setIsOpenModal(false);
        setPopclipsDetailsToDisplay(null);
    };

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    const handleNextPage = () => {
        if (pagination.hasNextPage) {
            setCursorHistory([...cursorHistory, pagination?.currentCursor]); // Save current cursor
            fetchPopclipsStatsData(pagination.nextCursor); // Fetch next page
        }
    };

    const handlePreviousPage = () => {
        if (cursorHistory.length > 0) {
            const previousCursor = cursorHistory[cursorHistory.length - 1];
            setCursorHistory(cursorHistory.slice(0, -1)); // Remove last cursor
            fetchPopclipsStatsData(previousCursor); // Fetch previous page
        }
    };

    const fetchPopclipsStatsData = async (cursor = null) => {
        try {
            setIsLoading(true);
            const resp = await fetch(
                `${popclipsServiceApi}/dashboard/popclips-stats?shop_id=mainstream-applevalley.myshopify.com${
                    cursor ? '&cursor=' + cursor : ''
                }`,
                {
                    method: 'GET',
                },
            );
            const { data } = await resp.json();
            const { items } = data;
            console.log(data);

            setPopclipsStatData(items);

            if (data?.cursor) {
                // Update state with new data and next cursor
                setPagination((prevState) => ({
                    ...prevState,
                    items: data.items, // Update items
                    currentCursor: cursor, // Set current cursor
                    nextCursor: data.cursor, // Set next cursor from response
                    hasNextPage: data.hasNextPage,
                    hasPreviousPage: data.hasPreviousPage,
                }));
            } else {
                setPagination((prevState) => ({
                    ...prevState,
                    items: data.items, // Update items
                    hasNextPage: data.hasNextPage,
                    hasPreviousPage: data.hasPreviousPage,
                }));
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (popclipsServiceApi) {
            fetchPopclipsStatsData();
        }
    }, []);

    const resourceName = {
        singular: 'data',
        plural: 'data',
    };

    const { selectedResources } = useIndexResourceState(popclipsStatData);

    const rowMarkup =
        popclipsStatData &&
        popclipsStatData.map((item: PopclipsStaticsTypes, index: number) => (
            <IndexTable.Row
                id={item.popclip_id}
                key={item.popclip_id}
                selected={selectedResources.includes(item.popclip_id)}
                position={index}
            >
                <IndexTable.Cell>
                    <Box padding={spanGap}>
                        <Text variant="bodyMd" fontWeight="bold" as="span">
                            <Button
                                variant="plain"
                                onClick={() => handleOpenPopclipDetails(item)}
                            >
                                {item.popclip_title}
                            </Button>
                        </Text>
                    </Box>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Box padding={spanGap}>{item.total_clicks}</Box>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Box padding={spanGap}>
                        {commaSeprateValue(item.total_sales)}{' '}
                        {item?.currency || ''}
                    </Box>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Box padding={spanGap}>{item.total_orders}</Box>
                </IndexTable.Cell>
                {/* <IndexTable.Cell>
                    <Box padding={spanGap}>
                        <Button
                            variant="plain"
                            onClick={() => handleOpenPopclipDetails(item)}
                        >
                            View More
                        </Button>
                    </Box>
                </IndexTable.Cell> */}
            </IndexTable.Row>
        ));

    const rowMarkupExpandView =
        popclipsStatData &&
        popclipsStatData.map((item: PopclipsStaticsTypes, index: number) => (
            <IndexTable.Row
                id={item.popclip_id + item.popclip_title}
                key={item.popclip_id + item.popclip_title}
                selected={selectedResources.includes(item.popclip_id)}
                position={index}
            >
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.popclip_title}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.total_clicks}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {commaSeprateValue(item.total_sales)}{' '}
                        {item?.currency || ''}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.total_orders}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {item?.total_add_to_carts || 0}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {commaSeprateValue(item?.sales_influenced_amount || 0)}
                        {item?.currency || ''}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {commaSeprateValue(item?.sales_indirect_amount || 0)}
                        {item?.currency || ''}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {commaSeprateValue(item?.sales_direct_amount || 0)}
                        {item?.currency || ''}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item?.shop_id || 0}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item?.total_views || 0}</div>
                </IndexTable.Cell>
            </IndexTable.Row>
        ));

    return (
        <div style={{ height: '100%' }}>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'grid',
                }}
            >
                <Card padding="600">
                    <Box paddingBlockEnd="400">
                        <DashboardHeaderSection
                            title="Popclips Stats"
                            showResizeButton={true}
                            isExpand={isExpand}
                            handleResizeChart={handleResizeChart}
                        />
                    </Box>
                    {isLoading && (
                        <Card>
                            <SkeletonBodyText />
                        </Card>
                    )}
                    {popclipsStatData && !isLoading && (
                        <Box>
                            <IndexTable
                                resourceName={resourceName}
                                itemCount={popclipsStatData?.length || 0}
                                headings={[
                                    { title: 'Popclips Name' },
                                    { title: 'Clicks' },
                                    { title: 'Total Sales' },
                                    { title: 'Total Orders' },
                                ]}
                                selectable={false}
                            >
                                {rowMarkup}
                            </IndexTable>
                            <Box paddingBlockStart="400">
                                <InlineStack align="center">
                                    <Pagination
                                        hasPrevious={
                                            pagination?.hasPreviousPage
                                        }
                                        onPrevious={handlePreviousPage}
                                        hasNext={pagination?.hasNextPage}
                                        onNext={handleNextPage}
                                    />
                                </InlineStack>
                            </Box>
                        </Box>
                    )}
                </Card>
            </div>
            {popclipsStatData && isExpand && (
                <VfsModal
                    onCloseModal={handleResizeChart}
                    title="Popclip Stats"
                    modalWidth="90%"
                >
                    <Box width="100%">
                        <IndexTable
                            resourceName={resourceName}
                            itemCount={popclipsStatData?.length || 0}
                            headings={[
                                { title: 'Popclips Name' },
                                { title: 'Clicks' },
                                { title: 'Total Sales' },
                                { title: 'Total Orders' },
                                { title: 'Total Add to Carts' },
                                { title: 'Sales Influenced Amount' },
                                { title: 'Sales Indirect Amount' },
                                { title: 'Sales Direct Amount' },
                                { title: 'Shop ID' },
                                { title: 'Total Views' },
                            ]}
                            selectable={false}
                        >
                            {rowMarkupExpandView}
                        </IndexTable>
                        <Box paddingBlockStart="200">
                            <InlineStack align="center">
                                <Pagination
                                    hasPrevious={pagination?.hasPreviousPage}
                                    onPrevious={handlePreviousPage}
                                    hasNext={pagination?.hasNextPage}
                                    onNext={handleNextPage}
                                />
                            </InlineStack>
                        </Box>
                    </Box>
                </VfsModal>
            )}

            {isModalOpen && popclipsDetailsToDisplay && (
                <VfsModal
                    onCloseModal={ClosePoplipDetailModal}
                    title={popclipsDetailsToDisplay?.popclip_title}
                    modalWidth="30rem"
                >
                    <Box padding="400">
                        <Grid
                            gap={{
                                xs: '30px',
                                sm: '30px',
                                md: '20px',
                                lg: '30px',
                                xl: '30px',
                            }}
                        >
                            {modalContetToDisplay.map((key: string) => (
                                <Grid.Cell
                                    key={key}
                                    columnSpan={{
                                        xs: 6,
                                        sm: 3,
                                        md: 3,
                                        lg: 6,
                                        xl: 6,
                                    }}
                                >
                                    <BlockStack gap="200">
                                        <Text
                                            variant="bodyLg"
                                            fontWeight="semibold"
                                            as="p"
                                        >
                                            {namingObject[key] || key}
                                        </Text>
                                        {key == 'total_sales' ? (
                                            <Text variant="bodyLg" as="p">
                                                {popclipsDetailsToDisplay?.final_total_sale ||
                                                    0}{' '}
                                                {popclipsDetailsToDisplay?.currency ||
                                                    ''}
                                            </Text>
                                        ) : (
                                            <Text variant="bodyLg" as="p">
                                                {popclipsDetailsToDisplay[key]}
                                            </Text>
                                        )}
                                    </BlockStack>
                                </Grid.Cell>
                            ))}
                        </Grid>
                    </Box>
                </VfsModal>
            )}
        </div>
    );
};

export default DashboardPopclipStatsTable;
