import BaseLayout from '@/components/layout/BaseLayout';
import LaunchVideeoDashboardCard from '@/components/landing/LaunchVideeoDashboardCard';

const LandingPage = () => {
    return (
        <BaseLayout
            title='Manage Live Sales'
            subtitle='From your Go Live dashboard you can go live, schedule upcoming live shows, manage your replays, and much more!'
        >
            <LaunchVideeoDashboardCard />
        </BaseLayout>
    );
};

export default LandingPage;
