import { popclipsRoutes } from '@/constants/routes.constants';
import {
    BlockStack,
    Box,
    Button,
    Icon,
    InlineGrid,
    InlineStack,
    Link,
    Text,
} from '@shopify/polaris';
import { LockFilledIcon } from '@shopify/polaris-icons';
import { customCarouselImg } from '../../../assets/images';
import { PlanUpgradeAlertModal } from '@/components/common/PlanUpgradeAlertModal';
import { useState } from 'react';

type CustomCarouselIntroCardProp = {
    openEmbedWindow: () => void;
    hasCustomCarousel: boolean;
    showPopclipsLockButton: boolean;
};

const LEARN_MORE_LINK =
    'https://help.videeo.live/hc/en-us/articles/30945961727764-Add-Custom-Popclips-Carousels-to-Your-Online-Store';

export default function CustomCarouselIntroCard({
    openEmbedWindow,
    hasCustomCarousel,
    showPopclipsLockButton,
}: CustomCarouselIntroCardProp) {
    const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);

    return (
        <Box padding="500">
            <PlanUpgradeAlertModal
                showPlanUpgradeModal={showPlanUpgradeModal}
                handleCloseModal={() => setShowPlanUpgradeModal(false)}
                type="POPCLIPS_BASIC_PLAN"
            />
            <InlineGrid columns={['twoThirds', 'oneHalf']}>
                <BlockStack gap="400" inlineAlign="baseline">
                    <Box
                        background="bg-fill-info"
                        borderRadius="full"
                        paddingInline="100"
                        width="9rem"
                    >
                        🎉 New Feature Alert
                    </Box>
                    <Text as="h1" variant="headingLg" fontWeight="bold">
                        Customizable Carousels
                    </Text>
                    <Text as="p" variant="bodySm" tone="subdued">
                        Take full control of your Popclips carousel by selecting
                        which videos appear and placing them anywhere on your
                        store. Want to highlight denim-related clips on your
                        Denim page? With this feature, you are in control to
                        showcase exactly what you want, where you want.{' '}
                        <Link url={LEARN_MORE_LINK}>Learn More</Link>
                    </Text>
                    {hasCustomCarousel ? (
                        <InlineStack
                            gap="300"
                            align="start"
                            blockAlign="end"
                            wrap={false}
                        >
                            <Button variant="primary" onClick={openEmbedWindow}>
                                Create All-Popclips Carousel
                            </Button>
                            <Button
                                variant="primary"
                                url={popclipsRoutes.createCarousel}
                            >
                                Create Custom Carousel
                            </Button>
                            <Button variant="primary" url={popclipsRoutes.manageCarousel}>
                                Manage Custom Carousel
                            </Button>
                        </InlineStack>
                    ) : showPopclipsLockButton ? (
                        <InlineStack
                            gap="600"
                            align="space-between"
                            blockAlign="center"
                        >
                            <Button variant="primary" onClick={openEmbedWindow}>
                                Create All-Popclips Carousel
                            </Button>
                            <Button
                                icon={<Icon source={LockFilledIcon} tone="base" />}
                                variant="primary"
                                onClick={() => setShowPlanUpgradeModal(true)}
                            >
                                Create Custom Carousel
                            </Button>
                        </InlineStack>
                    ) : (
                        <InlineStack
                            gap="600"
                            align="space-between"
                            blockAlign="center"
                        >
                            <Button variant="primary" onClick={openEmbedWindow}>
                                Create All-Popclips Carousel
                            </Button>
                            <Button
                                variant="primary"
                                url={popclipsRoutes.createCarousel}
                            >
                                Create Custom Carousel
                            </Button>
                        </InlineStack>
                    )}
                </BlockStack>
                <BlockStack align="center" inlineAlign="center">
                    <img
                        style={{
                            height: '10rem',
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                        src={customCarouselImg}
                    />
                </BlockStack>
            </InlineGrid>
        </Box>
    );
};
