import {
    BlockStack,
    Box,
    Card,
    InlineStack,
    Spinner,
    Text,
} from '@shopify/polaris';
import Plot from 'react-plotly.js';
import DashboardHeaderSection from './DashboardHeaderSection';
import DashboardIndicatorTag from './DashboardIndicatorTag';
import { LAYOUT, CONFIG, LINE_SMOOTHING_VALUE } from './chartConstantConfig';
import { useState } from 'react';

interface DashboardTotalCliksChartPropsTypes {
    title: string;
    value: number;
    chartData: number[];
    labels: string[];
    isLoading: boolean;
    growthInValue: number;
}

const DashboardTotalCliksChart = ({
    title,
    value,
    chartData,
    labels,
    isLoading,
    growthInValue,
}: DashboardTotalCliksChartPropsTypes) => {
    const [isExpand, setIsExpand] = useState(false);

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    const DrawTotalClickChart = () => {
        return (
            <Plot
                data={[
                    // Shadow area
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'none',
                        fill: 'tozeroy', // Fills the area to the x-axis
                        fillcolor: 'rgba(86, 84, 212, 0.1)', // Transparent shadow color
                        hoverinfo: 'skip', // Disables hover for shadow area
                    },
                    // Line
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'lines',
                        line: {
                            color: '#5654D4',
                            width: 3, // Adjust line thickness
                            shape: 'spline', // Makes the line curved
                            smoothing: LINE_SMOOTHING_VALUE, // Controls the smoothness of the curve
                        },
                        name: 'Click',
                    },
                ]}
                layout={{
                    ...LAYOUT,
                    xaxis: {
                        visible: true,
                        showgrid: false,
                    },
                    yaxis: {
                        visible: false,
                        showgrid: true,
                    },
                    width: isExpand ? '' : 400,
                    autosize: isExpand,
                }}
                config={CONFIG}
            />
        );
    };
    return (
        <div>
            <Box
                borderWidth="025"
                borderColor="border"
                borderRadius="500"
                padding="600"
                background="bg-fill"
                shadow="200"
            >
                <DashboardHeaderSection
                    title={title}
                    showResizeButton={true}
                    isExpand={isExpand}
                    handleResizeChart={handleResizeChart}
                />
                <InlineStack blockAlign="end" wrap={false}>
                    <BlockStack gap="400">
                        <Text variant="heading3xl" as="h2">
                            {value}
                        </Text>
                        <DashboardIndicatorTag
                            growthInValue={growthInValue}
                            isMonth={false}
                        />
                    </BlockStack>
                    {isLoading ? <Spinner /> : <DrawTotalClickChart />}
                </InlineStack>
            </Box>
            {/* Expand view code */}
            {isExpand && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 'auto',
                        right: 'auto',
                        background: 'rgba(0,0,0,0.8)',
                        width: '100%',
                        height: '100%',
                        zIndex: '2147483647',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        borderWidth="025"
                        borderColor="border"
                        borderRadius="500"
                        padding="600"
                        background="bg-fill"
                        shadow="200"
                    >
                        <DashboardHeaderSection
                            title={title}
                            showResizeButton={true}
                            isExpand={isExpand}
                            handleResizeChart={handleResizeChart}
                        />
                        <InlineStack blockAlign="end" wrap={false}>
                            <BlockStack gap="400">
                                <Text variant="heading3xl" as="h2">
                                    {value}
                                </Text>
                                <DashboardIndicatorTag
                                    growthInValue={growthInValue}
                                    isMonth={false}
                                />
                            </BlockStack>
                        </InlineStack>
                        {isLoading ? (
                            <InlineStack align="center" blockAlign="center">
                                <Spinner />
                            </InlineStack>
                        ) : (
                            <DrawTotalClickChart />
                        )}
                    </Box>
                </div>
            )}
        </div>
    );
};

export default DashboardTotalCliksChart;
