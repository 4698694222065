import { proxy, subscribe } from 'valtio';
import shopStore from './shopStore';
import {
    ATTRIBUTE_IDS,
    FEATURE_NAME,
    LIVE_ID_STR,
    MOBILE_ID_STR,
    PLAN_SLIDER_INFINITY,
    POPCLIPS_ID_STR,
} from '@/constants/planFeatureCard.constants';
import { shopifyCurrencyFormatter } from '@/utils/MoneyFormatter';
import { fetchPost } from '@/utils/helpers';

let planAbortController = new AbortController();
let addonAbortController = new AbortController();
let featureAbortController = new AbortController();

interface BillingStoreType {
    plans: any[];
    addons: any[];
    features: any[];
    discount: { hasDiscountOverride: boolean; overrideSkuCode: {} } | null;
    selectedPlanSku: string;
    selectedAddonsIds: string[];
    discountCode: string;
    shouldShowFeatureCards: boolean;
    isDiscountCodeApplied: boolean;
    billingSubscription?: any;
    featureHasAddon: string[];
    getPlans: () => void;
    getAddons: () => void;
    getFeatures: () => void;
    getAddonsByModule: (module: string, featAttrId: string) => any[];
    getPlanPurchaseUrl: (subDraftId?: string) => Promise<{
        confirmationUrl: string;
        subscription: { isStubSubscription: boolean };
    }>;
    getAddonAttrMinAndMaxValue: (addons: any[], featAttrId: string) => any;
    applyDiscountCode: () => void;
    removeAppliedDiscountCode: () => void;
    isAddonAlreadyInBasePlan: (sku: string) => boolean;
    getPlanSubscription: () => Promise<boolean>;
    getFormattedAddonsForCart: (addons: any[]) => any[];
    getAddonButtonGroupValues: (addons: any[], featAttrId: string) => any;
}

const billingStore: BillingStoreType = proxy<BillingStoreType>({
    plans: [],
    addons: [],
    features: [],
    discount: null,
    selectedPlanSku: '',
    selectedAddonsIds: [],
    featureHasAddon: [],
    discountCode: '',
    isDiscountCodeApplied: false,
    shouldShowFeatureCards: false,
    billingSubscription: undefined,
    getPlans: async () => {
        try {
            planAbortController.abort();
            planAbortController = new AbortController();

            const result = await fetch('/api/billing/plans', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: planAbortController.signal,
            });

            if (!result.ok) throw new Error();

            billingStore.plans = await result.json();
        } catch (e) {
            return;
        }
    },
    getAddons: async () => {
        try {
            addonAbortController.abort();
            addonAbortController = new AbortController();

            const result = await fetch('/api/billing/addons', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: addonAbortController.signal,
            });

            if (!result.ok) throw new Error();

            billingStore.addons = await result.json();
        } catch (e) {
            return;
        }
    },
    getFeatures: async () => {
        try {
            featureAbortController.abort();
            featureAbortController = new AbortController();

            const result = await fetch('/api/billing/features', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: featureAbortController.signal,
            });

            if (!result.ok) throw new Error();

            const features = await result.json();

            // 0 => liveFeature
            // 1 => mobileFeature
            // 2 => popclipsFeature

            billingStore.features = [features[2], features[0], features[1]];
        } catch (e) {
            return;
        }
    },
    getAddonsByModule: (module: string, featAttrId: string) => {
        return billingStore.addons
            .filter(
                (addon) =>
                    addon.featureId == module && addon.visibility == 'public',
            )
            .sort((a, b) => {
                let aValue =
                    a.attributes.find(
                        ({ attributeId }: any) => attributeId === featAttrId,
                    )?.value || 0;
                let bValue =
                    b.attributes.find(
                        ({ attributeId }: any) => attributeId === featAttrId,
                    )?.value || 0;

                aValue = aValue == '-1' ? PLAN_SLIDER_INFINITY : aValue;
                bValue = bValue == '-1' ? PLAN_SLIDER_INFINITY : bValue;

                return Number(aValue) - Number(bValue);
            });
    },
    getAddonAttrMinAndMaxValue: (addons: any[], featAttrId: string) => {
        let attr = addons[0].attributes.find(
            ({ attributeId }: any) => attributeId == featAttrId,
        );
        let minValue = Math.round(parseFloat(attr?.value || '0'));
        let maxValue = Math.round(parseFloat(attr?.value || '0'));
        let maxAddonObj: any = null;
        let valueArr: number[] = [];

        addons.map((addon) => {
            const attr = addon.attributes.find(
                (attr: any) => attr.attributeId == featAttrId,
            );

            if (attr) {
                const value =
                    attr.value == '-1'
                        ? PLAN_SLIDER_INFINITY
                        : Math.round(parseInt(attr.value));
                if (minValue > value) minValue = value;
                if (maxValue < value) {
                    maxValue = value;
                    maxAddonObj = addon;
                }
                valueArr.push(value);
            }
        });

        return {
            minValue,
            maxValue,
            valueBelowMax: valueArr.sort()[valueArr.length - 2],
            maxAddonObj,
        };
    },
    getAddonButtonGroupValues: (addons: any[], featAttrId: string) => {
        let valueArr: any[] = [];
        let attr = addons[0].attributes.find(
            ({ attributeId }: any) => attributeId == featAttrId,
        );
        let maxValue = Math.round(parseFloat(attr?.value || '0'));

        addons.map((addon) => {
            const attr = addon.attributes.find(
                (attr: any) => attr.attributeId == featAttrId,
            );

            if (attr) {
                const value =
                    attr.value == '-1'
                        ? PLAN_SLIDER_INFINITY
                        : Math.round(parseInt(attr.value));
                if (maxValue < value) {
                    maxValue = value;
                }
                valueArr.push({
                    id: attr.addonId,
                    value: value,
                    displayValue: value == 1 ? 'One' : attr.displayValue,
                });
            }
        });
        if (featAttrId !== ATTRIBUTE_IDS[LIVE_ID_STR]) {
            valueArr.push({
                id: 'unlimited',
                value: PLAN_SLIDER_INFINITY,
                displayValue: `More than ${maxValue}`,
            });
        }
        return valueArr;
    },
    getPlanPurchaseUrl: async (subDraftId) => {
        if (!subDraftId && !billingStore.billingSubscription) return;

        try {
            const result = await fetchPost('/api/shopify/shop/subscription', {
                draftId: subDraftId
                    ? subDraftId
                    : billingStore.billingSubscription?.id,
            });

            if (!result.ok) throw new Error();
            return result.json();
        } catch (e) {
            shopify.toast.show(
                'Could not finish the checkout process.',
                { isError: true, duration: 5000 },
            );
            console.error('Failed to generate plan purchase link.', e);
            return;
        }
    },
    getPlanSubscription: async () => {
        try {
            const {
                plans,
                selectedPlanSku,
                selectedAddonsIds,
                isDiscountCodeApplied,
                discountCode,
            } = billingStore;

            const selectedPlan = plans.find(
                ({ skuCode }) => skuCode === selectedPlanSku,
            );

            const result = await fetchPost(
                '/api/shopify/shop/subscription/draft',
                {
                    shopifyDomain: shopStore.shopifyDomain,
                    planId: selectedPlan.id,
                    extraAddonIds: selectedAddonsIds,
                    discountCode: isDiscountCodeApplied
                        ? discountCode.toUpperCase().trim()
                        : '',
                },
            );

            if (!result.ok) throw new Error();
            const data = await result.json();
            billingStore.billingSubscription = data;
            return true;
        } catch (e) {
            const errMsg = billingStore.isDiscountCodeApplied
                ? "Discount Code doesn't exist or is not applicable to the selected plan."
                : 'Could not proceed to checkout.';

            shopify.toast.show(errMsg, {
                isError: true,
                duration: 5000,
            });
            console.error('Failed to proceed to checkout.', e);
            return false;
        }
    },
    applyDiscountCode: async () => {
        // TODO:: this is dummy data might change in future
        billingStore.isDiscountCodeApplied = true;
        billingStore.discount = {
            hasDiscountOverride: true,
            overrideSkuCode: {
                popclips: 'addon-popclips-free',
                mobile: 'addon-popclips-free',
            },
        };
    },
    removeAppliedDiscountCode: async () => {
        billingStore.isDiscountCodeApplied = false;
        billingStore.discount = null;
        billingStore.discountCode = '';
    },
    isAddonAlreadyInBasePlan: (sku: string) => {
        if (!sku) return false;

        const basePlan = billingStore.plans.find(
            ({ skuCode }) => skuCode === billingStore.selectedPlanSku,
        );
        return basePlan.addons.map(({ skuCode }: any) => skuCode).includes(sku);
    },
    getFormattedAddonsForCart: (addons: any[]) => {
        return addons.map((addon) => {
            const formattedPrice = shopifyCurrencyFormatter(
                addon.price[0].amount,
                addon.price[0].currencyCode,
            );
            const attr = addon.attributes.find(
                (attr) => attr.attributeId === ATTRIBUTE_IDS[addon.featureId],
            );

            let attrLabel;
            switch (addon.featureId) {
                case POPCLIPS_ID_STR:
                    attrLabel = 'Max Video Views';
                    break;
                case MOBILE_ID_STR:
                    attrLabel = 'Max App Downloads';
                    break;
                case LIVE_ID_STR:
                    attrLabel = 'Live Event(s)';
                    break;
            }

            return {
                id: addon.id,
                skuCode: addon.skuCode,
                addonName: addon.name,
                value: attr.displayValue,
                name: `${FEATURE_NAME[addon.featureId]} - ${addon.name}`,
                price: `${formattedPrice}`,
                attrString: `${attrLabel}: ${attr.displayValue}`,
                isLiveAddon: addon.featureId === LIVE_ID_STR,
                type: addon.featureId,
            };
        });
    },
});

subscribe(billingStore, () => {
    billingStore.shouldShowFeatureCards =
        billingStore.plans.length > 0 &&
        billingStore.features.length > 0 &&
        billingStore.addons.length > 0;
});

export default billingStore;
