import {
    BlockStack,
    Box,
    Button,
    ButtonGroup,
    Card,
    InlineStack,
    Spinner,
    Text,
} from '@shopify/polaris';
import Plot from 'react-plotly.js';
import DashboardHeaderSection from './DashboardHeaderSection';
import DashboardIndicatorTag from './DashboardIndicatorTag';
import { useEffect, useState } from 'react';
import VfsModal from '../../v2/Components/VfsModal';

interface DashboardTotalSalesChartPropsTypes {
    title: string;
    directSaleValue: number;
    indirectSaleValue: number;
    influenced: number;
    totalSales: number[];
    salesDirectAmount: number[];
    salesIndirectAmount: number[];
    salesInfluencedAmount: number[];
    labels: string[];
    isLoading: boolean;
    growthInValue: number;
}

const DashboardTotalSalesChart = ({
    title,
    directSaleValue,
    indirectSaleValue,
    influenced,
    totalSales,
    salesDirectAmount,
    salesIndirectAmount,
    salesInfluencedAmount,
    labels,
    isLoading,
    growthInValue,
}: DashboardTotalSalesChartPropsTypes) => {
    const [isExpand, setIsExpand] = useState(false);
    const [isDirectSaleValue, setIsDirectSaleValue] = useState('DIRECT');
    const [lineChartData, setLineChartData] = useState({
        x: [],
        y: [],
        type: 'scatter',
        mode: 'lines',
        line: {
            color: '#FC5452',
            width: 3, // Adjust line thickness
            shape: 'spline', // Makes the line curved
            smoothing: 0.3, // Controls the smoothness of the curve
        },
        name: 'Sale',
    });
    const [lineShadowChartData, setLineShadowChartData] = useState({
        x: [],
        y: [],
        type: 'scatter',
        mode: 'none',
        fill: 'tozeroy', // Fills the area to the x-axis
        fillcolor: 'rgba(252, 84, 82, 0.1)', // Transparent shadow color
        hoverinfo: 'skip', // Disables hover for shadow area
    });

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    const handleToggleButtonClick = (event, value:string) => {
        event.stopPropagation(); // Stop the event from bubbling
        setIsDirectSaleValue(value);
    }

    function formatNumber(value: number) {
        // Check if the input is a valid number
        if (isNaN(value)) {
            return 'Invalid number';
        }

        // Convert to a number (in case it's a string)
        const numberValue = Number(value);

        // Format with commas and return as a string
        return numberValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    const SaleValueWithToggleButton = () => {
        return (
            <InlineStack align="space-between" blockAlign="center">
                {isDirectSaleValue == 'DIRECT' && (
                    <Text variant="heading2xl" as="h3">
                        {formatNumber(directSaleValue)} USD
                    </Text>
                )}
                {isDirectSaleValue == 'INDIRECT' && (
                    <Text variant="heading2xl" as="h3">
                        {formatNumber(indirectSaleValue)} USD
                    </Text>
                )}
                {isDirectSaleValue == 'INFLUENCED' && (
                    <Text variant="heading2xl" as="h3">
                        {formatNumber(influenced)} USD
                    </Text>
                )}
                <ButtonGroup variant="segmented">
                    <Button
                        pressed={isDirectSaleValue == 'DIRECT'}
                        onClick={(event) => handleToggleButtonClick(event, 'DIRECT')}
                    >
                        Direct
                    </Button>
                    <Button
                        pressed={isDirectSaleValue == 'INDIRECT'}
                        onClick={(event) => handleToggleButtonClick(event,'INDIRECT')}
                    >
                        Indirect
                    </Button>
                    <Button
                        pressed={isDirectSaleValue == 'INFLUENCED'}
                        onClick={(event) => handleToggleButtonClick(event,'INFLUENCED')}
                    >
                        Influenced
                    </Button>
                </ButtonGroup>
            </InlineStack>
        );
    };

    const DrawSalesChart = () => {
        return (
            <Plot
                data={[
                    // Shadow area
                    lineShadowChartData,
                    // Line
                    lineChartData,
                ]}
                layout={{
                    yaxis: {
                        visible: false, // Hides the y-axis
                        showgrid: false, // Hides gridlines
                    },
                    showlegend: false,
                    autosize: isExpand,
                    height: 200,
                    width: isExpand ? '' : 460,
                    margin: { t: 50, l: 50, r: 50, b: 50 },
                    plot_bgcolor: 'rgba(0,0,0,0)', // Transparent background for gradient
                }}
                config={{
                    responsive: true, // Makes the chart responsive
                    displayModeBar: false, // Hides the mode bar for a cleaner UI
                    '               displaylogo': false,
                }}
            />
        );
    };

    const setChartData = () => {
        if (isDirectSaleValue == 'DIRECT') {
            setLineChartData({
                ...lineChartData,
                x: labels,
                y: salesDirectAmount,
            });
            setLineShadowChartData({
                ...lineShadowChartData,
                x: labels,
                y: salesDirectAmount,
            });
        }
        if (isDirectSaleValue == 'INDIRECT') {
            setLineChartData({
                ...lineChartData,
                x: labels,
                y: salesIndirectAmount,
            });
            setLineShadowChartData({
                ...lineShadowChartData,
                x: labels,
                y: salesIndirectAmount,
            });
        }
        if (isDirectSaleValue == 'INFLUENCED') {
            setLineChartData({
                ...lineChartData,
                x: labels,
                y: salesInfluencedAmount,
            });
            setLineShadowChartData({
                ...lineShadowChartData,
                x: labels,
                y: salesInfluencedAmount,
            });
        }
    };
    useEffect(() => {
        setChartData();
    }, [isLoading, isDirectSaleValue]);
    return (
        <div
            style={{
                display: 'grid',
            }}
        >
            <Box
                borderWidth="025"
                borderColor="border"
                borderRadius="500"
                padding="600"
                background="bg-fill"
                shadow="200"
            >
                <DashboardHeaderSection
                    title={title}
                    showResizeButton={true}
                    isExpand={isExpand}
                    handleResizeChart={handleResizeChart}
                />
                <SaleValueWithToggleButton />
                <DashboardIndicatorTag
                    growthInValue={growthInValue}
                    isMonth={true}
                />
                <Box paddingInline="025">
                    {isLoading ? <Spinner /> : <DrawSalesChart />}
                </Box>
            </Box>
            {isExpand && (
                <VfsModal
                    onCloseModal={handleResizeChart}
                    title={title}
                    modalWidth="90%"
                >
                    <Box width='100%'>
                    <BlockStack gap="400" align='start'>
                    <SaleValueWithToggleButton />
                    <DashboardIndicatorTag
                        growthInValue={growthInValue}
                        isMonth={true}
                    />
                    {isLoading ? (
                        <InlineStack align="center" blockAlign="center">
                            <Spinner />
                        </InlineStack>
                    ) : (
                        <DrawSalesChart />
                    )}
                    </BlockStack>
                    </Box>
                </VfsModal>
            )}
        </div>
    );
};

export default DashboardTotalSalesChart;
