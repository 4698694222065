import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    BlockStack,
    Spinner,
    Banner,
    Box,
    Link,
    InlineGrid,
    Text,
    InlineStack,
    Button,
    Icon,
} from '@shopify/polaris';
import { shopStore, videoLiveSellingStore } from '@/stores';
import PopClipsCard from '@/components/common/PopClipsCard';
import PopClipsGridEmptyState from './PopClipsGridEmptyState';
import ClipListingCard from './ClipListingCard';
import { popclipsRoutes, vfsRoutes } from '@/constants/routes.constants';
import type { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';
import { usePopclipsPagination } from '@/hooks/usePopclipsPagination';
import { useSnapshot } from 'valtio';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    PlusIcon,
    RefreshIcon,
    SettingsIcon,
} from '@shopify/polaris-icons';

const POLLING_INTERVAL = 1000 * 45;

type PopclipsGridCardHeaderProps = {
    onHandleRefreshButton: () => void;
    onHandleButton: () => void;
};

type PopclipsGridCardFooterProps = {
    showPagination: boolean;
    showPrevPageBtn: boolean;
    prevPageClicked: () => void;
    showNextPageBtn: boolean;
    nextPageClicked: () => void;
};

const PopClipsGridListing = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getPaginatedData } = usePopclipsPagination();
    const videoLiveSellingSnap = useSnapshot(videoLiveSellingStore);
    let timeoutId: number;

    const [clipDataArray, setClipDataArray] = useState<ListMediaByIdResponse[]>(
        [],
    );
    const [isApiLoading, setIsApiLoading] = useState(true);
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const [shouldShowInfoBanner, setShouldShowInfoBanner] = useState(false);

    useEffect(() => {
        setIsApiLoading(true);
        const params = location.state;
        const defaultEvalKeyObj = { 1: undefined };
        const { gridCurrentPage, gridLastEvalKey } = videoLiveSellingStore;
        const loadFirstPage =
            gridCurrentPage == 1 || (params && params.goToFirstPage);

        const paginationPromise = loadFirstPage
            ? getPaginatedData()
            : getPaginatedData(false, gridLastEvalKey[gridCurrentPage]);

        paginationPromise.then((response) => {
            if (response) {
                setIsDataAvailable(response.isDataAvailable);
                setClipDataArray(response.clips);

                if (response.lastEvaluatedKey && loadFirstPage) {
                    Object.assign(defaultEvalKeyObj, {
                        [gridCurrentPage + 1]: response.lastEvaluatedKey,
                    });
                    videoLiveSellingStore.gridLastEvalKey = defaultEvalKeyObj;
                }
                setIsApiLoading(false);
            } else if (response === null) {
                setIsApiLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        const hasPendingStatus = clipDataArray.some(
            (item) => item.status !== 'processed',
        );

        if (hasPendingStatus) {
            setShouldShowInfoBanner(true);
            timeoutId = window.setTimeout(() => {
                getPaginatedData(true).then((response) => {
                    if (response) setClipDataArray(response.clips);
                });
            }, POLLING_INTERVAL);
        } else {
            setShouldShowInfoBanner(false);
            window.clearTimeout(timeoutId);
        }

        return () => {
            if (timeoutId) {
                window.clearTimeout(timeoutId);
            }
        };
    }, [clipDataArray]);

    const nextPageClicked = () => {
        window.clearTimeout(timeoutId);
        setIsApiLoading(true);
        const { gridCurrentPage, gridLastEvalKey } = videoLiveSellingStore;
        let cursor = gridLastEvalKey
            ? gridLastEvalKey[gridCurrentPage + 1]
            : undefined;

        getPaginatedData(false, cursor).then((response) => {
            if (response) {
                setIsDataAvailable(response.isDataAvailable);
                setClipDataArray(response.clips);
                videoLiveSellingStore.gridCurrentPage =
                    videoLiveSellingStore.gridCurrentPage + 1;

                if (response.lastEvaluatedKey) {
                    let tempVar = gridLastEvalKey;
                    if (tempVar)
                        tempVar[gridCurrentPage + 2] =
                            response.lastEvaluatedKey;

                    videoLiveSellingStore.gridLastEvalKey = tempVar;
                }
            }
            setIsApiLoading(false);
        });
    };

    const prevPageClicked = () => {
        window.clearTimeout(timeoutId);
        setIsApiLoading(true);

        const { gridCurrentPage, gridLastEvalKey } = videoLiveSellingStore;
        let cursor = gridLastEvalKey
            ? gridLastEvalKey[gridCurrentPage - 1]
            : undefined;
        videoLiveSellingStore.gridCurrentPage = gridCurrentPage - 1;

        getPaginatedData(false, cursor).then((response) => {
            if (response) {
                setIsDataAvailable(response.isDataAvailable);
                setClipDataArray(response.clips);
            }
            setIsApiLoading(false);
        });
    };

    const handleRefreshData = (allClipsDeleted = false) => {
        if (allClipsDeleted) {
            if (shouldShowPrevPageBtn) {
                delete videoLiveSellingStore.gridLastEvalKey[
                    videoLiveSellingStore.gridCurrentPage
                ];
                prevPageClicked();
            } else {
                setIsDataAvailable(false);
            }
        } else {
            setIsApiLoading(true);
            getPaginatedData(true).then((response) => {
                if (response) {
                    setIsDataAvailable(response.isDataAvailable);
                    setClipDataArray(response.clips);
                }
                setIsApiLoading(false);
            });
        }
    };

    const shouldShowPrevPageBtn = useMemo(() => {
        const pageToGo = videoLiveSellingSnap.gridCurrentPage - 1;
        if (pageToGo == 1) return true;

        return videoLiveSellingSnap.gridLastEvalKey[pageToGo] ? true : false;
    }, [
        videoLiveSellingSnap.gridCurrentPage,
        videoLiveSellingSnap.gridLastEvalKey,
    ]);

    const showShowNextPageBtn = useMemo(() => {
        const pageToGo = videoLiveSellingSnap.gridCurrentPage + 1;
        return videoLiveSellingSnap.gridLastEvalKey[pageToGo] ? true : false;
    }, [
        videoLiveSellingSnap.gridCurrentPage,
        videoLiveSellingSnap.gridLastEvalKey,
    ]);

    return (
        <PopClipsCard
            cardHeader={
                <PopclipsGridCardHeader
                    onHandleRefreshButton={() => handleRefreshData(false)}
                    onHandleButton={() => {
                        videoLiveSellingStore.resetCreatePopClip();
                        navigate(popclipsRoutes.create);
                    }}
                />
            }
            cardFooter={
                <PopclipsGridCardFooter
                    showPagination={isDataAvailable}
                    showPrevPageBtn={shouldShowPrevPageBtn}
                    prevPageClicked={prevPageClicked}
                    showNextPageBtn={showShowNextPageBtn}
                    nextPageClicked={nextPageClicked}
                />
            }
        >
            {isApiLoading ? (
                <BlockStack align="center" inlineAlign="center">
                    <Spinner size="large" />
                    <Text as="p">
                        Hang tight while we are loading your Popclips
                    </Text>
                </BlockStack>
            ) : isDataAvailable ? (
                <Box paddingInline="300">
                    <BlockStack gap="400">
                        {shouldShowInfoBanner && (
                            <Banner
                                title="You can continue adding popclips while we process current uploads."
                                tone="info"
                                onDismiss={() => {
                                    setShouldShowInfoBanner(false);
                                }}
                            />
                        )}
                        <Box paddingInlineStart="200">
                            <ClipListingCard
                                clipDataArray={clipDataArray}
                                refreshData={handleRefreshData}
                            />
                        </Box>
                    </BlockStack>
                </Box>
            ) : (
                <PopClipsGridEmptyState />
            )}
        </PopClipsCard>
    );
};

const PopclipsGridCardHeader = ({
    onHandleRefreshButton,
    onHandleButton,
}: PopclipsGridCardHeaderProps) => {
    const gatingData = shopStore.featureGatingData?.popclips;

    return (
        <Box padding="500">
            <InlineGrid gap="400" columns={['twoThirds', 'oneThird']}>
                {gatingData?.limitAccess ? (
                    <Banner tone="warning">
                        <strong>Important:</strong> You've reached your
                        current plan's view limits of{' '}
                        {gatingData?.maxViews || ''}. In order to create new
                        Popclips please{' '}
                        <Link url={vfsRoutes.managePlans}>upgrade</Link>.
                    </Banner>
                ) : <div></div>}
                <InlineStack gap="200" align="end" blockAlign="center">
                    <Button
                        icon={RefreshIcon}
                        accessibilityLabel="Refresh button"
                        onClick={onHandleRefreshButton}
                    />
                    <Button
                        variant="primary"
                        submit
                        onClick={onHandleButton}
                        icon={PlusIcon}
                        disabled={gatingData?.limitAccess}
                    >
                        Create Popclip
                    </Button>
                    <Button
                        variant="primary"
                        url={popclipsRoutes.settings}
                        icon={SettingsIcon}
                    />
                </InlineStack>
            </InlineGrid>
        </Box>
    );
};

const PopclipsGridCardFooter = ({
    showPagination,
    showPrevPageBtn,
    prevPageClicked,
    showNextPageBtn,
    nextPageClicked,
}: PopclipsGridCardFooterProps) => {
    return (
        <Box background="bg-surface-active" padding="300" minHeight="44px">
            {showPagination ? (
                <InlineStack gap="300" align="end" blockAlign="center">
                    {showPrevPageBtn ? (
                        <Button
                            icon={ChevronLeftIcon}
                            accessibilityLabel="Previous Page"
                            onClick={prevPageClicked}
                        />
                    ) : (
                        <Box>
                            <Icon source={ChevronLeftIcon} tone="subdued" />
                        </Box>
                    )}

                    {showNextPageBtn ? (
                        <Button
                            icon={ChevronRightIcon}
                            accessibilityLabel="Next Page"
                            onClick={nextPageClicked}
                        />
                    ) : (
                        <Box>
                            <Icon source={ChevronRightIcon} tone="subdued" />
                        </Box>
                    )}
                </InlineStack>
            ) : null}
        </Box>
    );
};

export default PopClipsGridListing;
