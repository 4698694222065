import { useState, useEffect, useMemo, useCallback } from 'react';
import {
    InlineStack,
    BlockStack,
    Box,
    Text,
    SkeletonDisplayText,
    InlineGrid,
    Button,
    Bleed,
    Icon,
    Link,
    ButtonGroup,
} from '@shopify/polaris';
import { AutocompleteInput } from '@/components/common';
import { useGetShopifyProducts } from '@/hooks/useGetShopifyProducts';
import { videoLiveSellingStore } from '@/stores';
import { useSnapshot } from 'valtio';
import ShopifyProductCard from './ShopifyProductCard';
import { shopifyGidFromProductId } from '@/utils/GidHelper';
import {
    AlertCircleIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    TextInColumnsIcon,
    TextInRowsIcon,
} from '@shopify/polaris-icons';
import type { ShopifyProductsFormated } from '@/stores/videoLiveSellingStore';

type RenderProductListProps = {
    productsToRender: ShopifyProductsFormated[];
    selectedProdIds: string[];
    onSelectProduct: (prod: ShopifyProductsFormated) => void;
    isColumnLayout: boolean;
};

const ProductListCard = () => {
    const videoLiveSellingSnap = useSnapshot(videoLiveSellingStore);
    const [searchParam, setSearchParam] = useState<string>();
    const [isColumnLayout, setIsColumnLayout] = useState(true);
    const [shopifyProducts, setShopifyProducts] = useState<ShopifyProductsFormated[] | []>([]);
    const { getProducts, getProductByIds, getQuerySuggestions } = useGetShopifyProducts();

    useEffect(() => {
        if (videoLiveSellingSnap.areProductsLoading) return;
        getShopifyProducts();
    }, []);

    const handleShopifyPaginationNext = async () => {
        videoLiveSellingStore.areProductsLoading = true;
        const { nextCursor } = videoLiveSellingStore.shopifyProductPagination;
        const products = await getProducts(searchParam, nextCursor);
        setShopifyProducts(products);
        videoLiveSellingStore.areProductsLoading = false;
    };

    const handleShopifyPaginationPrevious = async () => {
        videoLiveSellingStore.areProductsLoading = true;
        const { prevCursor } = videoLiveSellingStore.shopifyProductPagination;
        const products = await getProducts(searchParam, undefined, prevCursor);
        setShopifyProducts(products);
        videoLiveSellingStore.areProductsLoading = false;
    };

    const getShopifyProducts = async () => {
        videoLiveSellingStore.areProductsLoading = true;
        const { editClipData } = videoLiveSellingSnap;

        if (editClipData) {
            const products = await getProducts();
            const productsIds = editClipData.metaData.products.map(
                ({ shopifyProductId }) =>
                    shopifyGidFromProductId(shopifyProductId),
            );
            const selectedProducts = await getProductByIds(productsIds);
            const searchedProdIds = products.map(({id}) => id);
            const prodsToAppend = selectedProducts.filter(({id}) => !searchedProdIds.includes(id));

            setShopifyProducts([...products, ...prodsToAppend]);
            videoLiveSellingStore.shopifySelectedProducts = selectedProducts;
        } else {
            const products = await getProducts();
            setShopifyProducts(products);
        }

        videoLiveSellingStore.areProductsLoading = false;
    };

    const onChangeAutocompleteValue = async (value: string) => {
        setSearchParam(value);

        const suggestions = await getQuerySuggestions(value);
        const products = await getProducts(value);
        setShopifyProducts(products);

        return suggestions.map(({ text }) => ({ value: text, label: text }));
    };

    const shouldShowSkeleton = useMemo(() => {
        return (
            videoLiveSellingSnap.areProductsLoading &&
            shopifyProducts.length === 0
        );
    }, [shopifyProducts, videoLiveSellingSnap.areProductsLoading]);

    const shouldShowEmptyState = useMemo(() => {
        return (
            !videoLiveSellingSnap.areProductsLoading &&
            shopifyProducts.length === 0 &&
            !(videoLiveSellingSnap.shopifySelectedProducts.length > 0)
        );
    }, [
        shopifyProducts,
        videoLiveSellingSnap.shopifySelectedProducts,
        videoLiveSellingSnap.areProductsLoading,
    ]);

    const selectedProdIds = useMemo(() => {
        return videoLiveSellingSnap.shopifySelectedProducts.map(
            (prod) => prod.id,
        );
    }, [videoLiveSellingSnap.shopifySelectedProducts]);

    const handleToggleSelectProduct = useCallback(
        (prod: ShopifyProductsFormated) => {
            const { shopifySelectedProducts } = videoLiveSellingStore;

            if (selectedProdIds.includes(prod.id)) {
                videoLiveSellingStore.shopifySelectedProducts =
                    shopifySelectedProducts.filter(({ id }) => id !== prod.id);
            } else {
                if (shopifySelectedProducts.length >= 5) {
                    shopify.toast.show('You can only tag upto 5 products!', {
                        isError: true,
                        duration: 5000,
                    });
                    return;
                }

                videoLiveSellingStore.shopifySelectedProducts = [
                    ...shopifySelectedProducts,
                    prod,
                ];
            }
        },
        [videoLiveSellingSnap.shopifySelectedProducts],
    );

    const productsToRender = useMemo(() => {
        return [
            ...videoLiveSellingSnap.shopifySelectedProducts,
            ...shopifyProducts.filter(
                (searchedProduct) =>
                    !selectedProdIds.includes(searchedProduct.id),
            ),
        ];
    }, [videoLiveSellingSnap.shopifySelectedProducts, shopifyProducts]);

    return (
        <BlockStack gap="400">
            <BlockStack gap="200">
                <InlineStack align="space-between">
                    <Text as="p">
                        Select upto 5 products to tag on your shoppable video.
                    </Text>
                    <Text as="p">
                        ({' '}
                        <Text as="span" tone="magic" fontWeight="bold">
                            {selectedProdIds.length}
                        </Text>{' '}
                        out of 5 products added )
                    </Text>
                </InlineStack>
                <InlineStack blockAlign="center" align="space-between">
                    <InlineStack blockAlign="center" gap="100" wrap={false}>
                        <Icon source={AlertCircleIcon} />
                        <Text as="p">
                            Please make sure all the products you want to tag
                            are published to the Videeo and Online sales
                            channels.
                        </Text>
                    </InlineStack>
                    <Link url="https://help.videeo.live/hc/en-us/articles/23185463459092-Publish-Products-and-Collections-to-Videeo-Sales-Channel">
                        Learn More
                    </Link>
                </InlineStack>
                <InlineStack
                    align="space-between"
                    blockAlign="center"
                    gap="100"
                >
                    <Box width="90%">
                        <AutocompleteInput
                            placeholderStr="Search by Product Name"
                            onChangeValue={onChangeAutocompleteValue}
                            onSelectOption={onChangeAutocompleteValue}
                            debounceTimeout={1500}
                            hideEmptyState={true}
                            shouldDisabled={
                                videoLiveSellingSnap.areProductsLoading ||
                                videoLiveSellingSnap.savingPopclip
                            }
                        />
                    </Box>
                    <ButtonGroup variant="segmented">
                        <Button
                            pressed={isColumnLayout}
                            icon={TextInColumnsIcon}
                            onClick={() => setIsColumnLayout(true)}
                        />
                        <Button
                            pressed={!isColumnLayout}
                            icon={TextInRowsIcon}
                            onClick={() => setIsColumnLayout(false)}
                        />
                    </ButtonGroup>
                </InlineStack>
            </BlockStack>
            {shouldShowSkeleton && <ProductSkeleton />}
            {shouldShowEmptyState && <ProductEmptyState />}
            {!shouldShowEmptyState && (
                <>
                    {isColumnLayout ? (
                        <InlineStack
                            align={
                                productsToRender.length < 5
                                    ? 'start'
                                    : 'space-evenly'
                            }
                            wrap
                            gap="400"
                        >
                            <RenderProductList
                                productsToRender={productsToRender}
                                selectedProdIds={selectedProdIds}
                                onSelectProduct={(prod) =>
                                    handleToggleSelectProduct(prod)
                                }
                                isColumnLayout={isColumnLayout}
                            />
                        </InlineStack>
                    ) : (
                        <BlockStack gap="400">
                            <RenderProductList
                                productsToRender={productsToRender}
                                selectedProdIds={selectedProdIds}
                                onSelectProduct={(prod) =>
                                    handleToggleSelectProduct(prod)
                                }
                                isColumnLayout={isColumnLayout}
                            />
                        </BlockStack>
                    )}
                </>
            )}
            <Bleed marginInline="400" marginBlockEnd="400">
                <Box
                    background="bg-surface-active"
                    padding="300"
                    minHeight="44px"
                    borderEndEndRadius="300"
                    borderEndStartRadius="300"
                >
                    <InlineStack gap="300" align="end" blockAlign="center">
                        {videoLiveSellingStore.shopifyProductPagination
                            .hasPreviousPage ? (
                            <Button
                                icon={ChevronLeftIcon}
                                accessibilityLabel="Previous Page"
                                onClick={handleShopifyPaginationPrevious}
                            />
                        ) : (
                            <Box>
                                <Icon source={ChevronLeftIcon} tone="subdued" />
                            </Box>
                        )}
                        {videoLiveSellingStore.shopifyProductPagination
                            .hasNextPage ? (
                            <Button
                                icon={ChevronRightIcon}
                                accessibilityLabel="Next Page"
                                onClick={handleShopifyPaginationNext}
                            />
                        ) : (
                            <Box>
                                <Icon
                                    source={ChevronRightIcon}
                                    tone="subdued"
                                />
                            </Box>
                        )}
                    </InlineStack>
                </Box>
            </Bleed>
        </BlockStack>
    );
};

const ProductSkeleton = () => {
    return (
        <>
            <InlineGrid gap="600" columns={5}>
                <SkeletonDisplayText size="extraLarge" />
                <SkeletonDisplayText size="extraLarge" />
                <SkeletonDisplayText size="extraLarge" />
                <SkeletonDisplayText size="extraLarge" />
                <SkeletonDisplayText size="extraLarge" />
            </InlineGrid>
            <InlineGrid gap="600" columns={5}>
                <SkeletonDisplayText size="extraLarge" />
                <SkeletonDisplayText size="extraLarge" />
                <SkeletonDisplayText size="extraLarge" />
                <SkeletonDisplayText size="extraLarge" />
                <SkeletonDisplayText size="extraLarge" />
            </InlineGrid>
        </>
    );
};

const ProductEmptyState = () => {
    return (
        <InlineStack align="center">
            <Box padding="400">
                <Text as="p" alignment="center">
                    No Result Found!
                </Text>
                <Text as="p">
                    Try searching with different keyword. Or clear the input to
                    see first 10 products.
                </Text>
            </Box>
        </InlineStack>
    );
};

const RenderProductList = ({
    productsToRender,
    selectedProdIds,
    onSelectProduct,
    isColumnLayout,
}: RenderProductListProps) => (
    <>
        {productsToRender.map((prod, i) => (
            <ShopifyProductCard
                key={prod.id + '-' + i}
                id={prod.id}
                title={prod.title}
                description={prod.description}
                amount={prod.amount}
                currencyCode={prod.currencyCode}
                imgSrc={prod.imgSrc}
                imgAlt={prod.imgAlt}
                selectedProducts={selectedProdIds}
                isColumnLayout={isColumnLayout}
                handleProductSelect={() => onSelectProduct(prod)}
            />
        ))}
    </>
);

export default ProductListCard;
