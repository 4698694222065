import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { shopStore } from "@/stores";
import { vfsRoutes } from "@/constants/routes.constants";

type ComponentProps = { children: React.ReactNode };

const RequireSubscription = ({ children }: ComponentProps) => {
    const shopStoreSnap = useSnapshot(shopStore);
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const redirectTo = vfsRoutes.selectPlans;

    useEffect(() => {
        if (!shopStoreSnap.subscriptionActive && pathname !== redirectTo) {
            navigate(redirectTo);
        }
    }, [shopStoreSnap.subscriptionActive]);

    return <>{children}</>;
};

export default RequireSubscription;
