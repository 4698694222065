import { Box, FormLayout, InlineStack, SkeletonBodyText, TextField } from '@shopify/polaris';
import { CardWithTitleSection } from '../common';
import { useSnapshot } from 'valtio';
import { mobileAppConfigStore } from '@/stores';
import { AppColorPicker } from '../common';
import MobileHomePage from './mobile-preview-screens/MobileHomePage';
import { Modal, TitleBar } from '@shopify/app-bridge-react';
import { useState } from 'react';

const LiveBannerCard = () => {
    const [openModal, setOpenModal] = useState(false);
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    return (
        <CardWithTitleSection
            title="Live Banner"
            subtitle="This banner message will display on your mobile app every time you go live.  This banner will override any previous banner setup you may have while going live. "
        >
            {mobileAppConfigSnap.isLoading ? (
                <SkeletonBodyText />
            ) : (
                <Box>
                    <FormLayout>
                        <TextField
                            autoComplete="off"
                            label="Main Title"
                            placeholder="Enter headline here"
                            maxLength={50}
                            value={mobileAppConfigSnap.liveBannerTitle}
                            onChange={(value) => {
                                mobileAppConfigStore.liveBannerTitle = value;
                            }}
                        />
                        <TextField
                            autoComplete="off"
                            label="Sub-Title"
                            placeholder="Enter subtitle here"
                            maxLength={50}
                            value={mobileAppConfigSnap.liveBannerText}
                            onChange={(value) => {
                                mobileAppConfigStore.liveBannerText = value;
                            }}
                        />
                    </FormLayout>
                    <AppColorPicker
                        required={true}
                        label={'Banner Background Color'}
                        hexColor={mobileAppConfigSnap.liveBannerColor}
                        onHexColorChange={(val: string) => {
                            mobileAppConfigStore.liveBannerColor = val;
                        }}
                        validationError={
                            !mobileAppConfigSnap.isLiveBannerColor &&
                            mobileAppConfigSnap.showFormErrorLabel
                                ? 'Required field'
                                : ''
                        }
                        previewButton
                        onPreviewButtonClick={() => setOpenModal(true)}
                    />
                    <Modal open={openModal} variant="base" onHide={() => setOpenModal(false)}>
                        <TitleBar title="Live Banner Preview">
                            <button
                                variant="primary"
                                onClick={() => setOpenModal(false)}
                            >
                                Close
                            </button>
                        </TitleBar>
                        <Box padding="400">
                            <InlineStack align='center'>
                                <MobileHomePage isLiveBanner />
                            </InlineStack>
                        </Box>
                    </Modal>
                </Box>
            )}
        </CardWithTitleSection>
    );
};

export default LiveBannerCard;
