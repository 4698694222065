import { billingStore } from '@/stores';
import { shopifyCurrencyFormatter } from '@/utils/MoneyFormatter';
import { BlockStack, Divider, InlineStack, Text } from '@shopify/polaris';
import { useMemo } from 'react';
import '../../assets/css/PlanCartComponent.css';

type PlanCartComponentProps = {
    shopSubscriptionDraft: any;
};

const PlanCartComponent = ({ shopSubscriptionDraft }: PlanCartComponentProps) => {
    const removeIncludedAddonWhenHasExtra = (includedAddons, extraAddons) => {
        return includedAddons.filter((includedAddon) => {
            return !extraAddons.some((extraAddon) => extraAddon.type === includedAddon.type);
        });
    }

    const subscriptionData = () => {
        const subData: any = shopSubscriptionDraft;
        if (!subData) return;

        const plan = subData.lineItems.filter((item: any) => item.type === 'plan')[0].item;
        const includedAddons = plan.addons && plan.addons.length > 0 ? billingStore.getFormattedAddonsForCart(plan.addons) : [];
        const extraAddons = subData.lineItems.filter((item: any) => item.type === 'addon').map((row: any) => row.item);
        const formattedExtraAddons = extraAddons.length > 0 ? billingStore.getFormattedAddonsForCart(extraAddons) : [];
        const planPrice = shopifyCurrencyFormatter(plan.price[0].amount, plan.price[0].currencyCode);
        const subtotalAmt = shopifyCurrencyFormatter(subData.subscriptionSubtotal.amount, subData.subscriptionSubtotal.currencyCode);
        const totalAmt = shopifyCurrencyFormatter(subData.subscriptionTotal.amount, subData.subscriptionTotal.currencyCode);
        const discountAmt = subData.subscriptionDiscount
            ? shopifyCurrencyFormatter(
                  subData.subscriptionDiscount.amount,
                  subData.subscriptionDiscount.currencyCode,
              )
            : 'N/A';

        return {
            planName: plan.name,
            planPrice: `${planPrice}`,
            includedAddons:
                formattedExtraAddons.length > 0
                    ? removeIncludedAddonWhenHasExtra(includedAddons, formattedExtraAddons)
                    : includedAddons,
            extraAddons: formattedExtraAddons,
            subTotalAmt: `${subtotalAmt}`,
            discountAmt: discountAmt,
            discountPercentage: subData.subscriptionDiscountPercentage,
            totalAmt: `${totalAmt}`,
        };
    };

    const renderCart = useMemo(subscriptionData, [shopSubscriptionDraft]);

    return (
        <div style={{ width: '100%', paddingBlock: '20px' }}>
            {renderCart ? (
                <BlockStack gap="600">
                    <BlockStack gap="500">
                        {[
                            ...renderCart.includedAddons,
                            ...renderCart.extraAddons,
                        ].map((addon, idx) => (
                            <RenderAddonsRow key={idx} addon={addon} />
                        ))}
                    </BlockStack>
                    <Divider />
                    <BlockStack gap="500">
                        <InlineStack align="space-between">
                            <p className="plan-subtotal-label">Subtotal</p>
                            <p className="plan-subtotal-price">
                                {renderCart.subTotalAmt}
                            </p>
                        </InlineStack>
                        <InlineStack align="space-between">
                            <p className="plan-subtotal-label">Discount {renderCart.discountPercentage && `(${renderCart.discountPercentage}%)`}</p>
                            <p className="plan-subtotal-price">
                                {renderCart.discountAmt}
                            </p>
                        </InlineStack>
                    </BlockStack>
                    <Divider />
                    <InlineStack align="space-between">
                        <p className="plan-subtotal-label">Total</p>
                        <p className="plan-subtotal-price">{renderCart.totalAmt}</p>
                    </InlineStack>
                </BlockStack>
            ) : (
                <BlockStack align="center" inlineAlign="center">
                    <Text as="h4" variant="headingLg" alignment="center">
                        No items in cart
                    </Text>
                </BlockStack>
            )}
        </div>
    );
};

const RenderAddonsRow = ({ addon }) => (
    <InlineStack align="space-between" blockAlign="center">
        <BlockStack gap="100">
            <p className="plan-row-name">{addon.name}</p>
            <p className="plans-row-attribute">{addon.attrString}</p>
            {addon.isLiveAddon && (
                <p className="plans-row-attribute" style={{ width: '18rem' }}>
                    A commission charge up to 5% will be applied only on Live &
                    Replay sales.
                </p>
            )}
        </BlockStack>
        <p className="plan-row-price">{addon.price}</p>
    </InlineStack>
);

export default PlanCartComponent;
