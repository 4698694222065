import { useState, useEffect } from 'react';
import { BlockStack, Box, InlineStack, Button, Spinner } from '@shopify/polaris';
import {
    ProductListCard,
    ClipsFormCard,
    VideoPreview,
} from '@/components/popclips';
import { useNavigate, useParams } from 'react-router-dom';
import { popclipsRoutes } from '@/constants/routes.constants';
import { analyticsEventStore, shopStore, videoLiveSellingStore } from '@/stores';
import { useUploadClip } from '@/hooks/useUploadClip';
import { useUpdateClip } from '@/hooks/useUpdateClip';
import { useSnapshot } from 'valtio';
import PopClipsCard from '@/components/common/PopClipsCard';
import { BaseLayout } from '../../v2/pages/Layout/BaseLayout';
import { useMediaGalleryAuthFetch } from '@/hooks/useAuthenticatedFetch';

type CreatePopClipPageProps = {
    editMode?: boolean;
};

const CreatePopClipPage = ({ editMode }: CreatePopClipPageProps) => {
    const param = useParams();
    const navigate = useNavigate();
    const { uploadClip } = useUploadClip();
    const { updateClip } = useUpdateClip();
    const mgFetch = useMediaGalleryAuthFetch();
    const { getStartedStatus } = useSnapshot(shopStore);

    const {
        editClipData,
        savingPopclip,
        clipTitle,
        uploadedPopClipData,
        clipInstaUrl,
    } = useSnapshot(videoLiveSellingStore);
    const [isApiLoading, setIsApiLoading] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        (async () => {
            if (editMode) {
                setIsApiLoading(true);

                const result = await mgFetch('/listMediabyTypeAndMetaId', {
                    method: 'POST',
                    body: JSON.stringify({
                        fileCategory: 'shopifyClips',
                        metaIds: [param.id],
                        sellerId: shopStore.popshopliveSellerId
                    }),
                });

                if (!result.data || result.data.length === 0) {
                    shopify.toast.show('Clip not found.', {
                        isError: true,
                        duration: 5000,
                    });
                    navigate(popclipsRoutes.list);
                    return;
                }

                const clipFound = result.data[0];

                videoLiveSellingStore.editClipData = clipFound;
                videoLiveSellingStore.clipTitle = clipFound.metaData.videoTitle;
                videoLiveSellingStore.videoUrl = `${clipFound.cdnUrl}${clipFound.metaData.processedURL}`;
                videoLiveSellingStore.clipInstaUrl = '';
                videoLiveSellingStore.isClipExcluded =
                    clipFound.metaData.hasOwnProperty('display') &&
                    clipFound.metaData.display.length === 0;
                    
                setIsApiLoading(false);
            }
        })();
        
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleSaveClick = async () => {
        if (videoLiveSellingStore.clipTitle.trim() == '') {
            shopify.toast.show('Clip Title cannot be empty.', {
                isError: true,
                duration: 5000,
            });
            videoLiveSellingStore.hasClipTitleErr();
            return;
        }
        if (!videoLiveSellingStore.videoUrl) {
            shopify.toast.show('Please add a video to create a new popclip.', {
                isError: true,
                duration: 5000,
            });
            return;
        }
        if (videoLiveSellingStore.shopifySelectedProducts.length == 0) {
            shopify.toast.show(
                'Please select at least one product to create a new popclip.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
            return;
        }

        await savePopClip();
    };

    const savePopClip = async () => {
        try {
            videoLiveSellingStore.savingPopclip = true;
            setIsApiLoading(true);
            const goToFirstPage = Boolean(!editMode);
            const startTime = new Date().getTime();
            let popclipId = '';

            if (
                goToFirstPage &&
                videoLiveSellingStore.dropzoneFile &&
                videoLiveSellingStore.dropzoneFile.get('file')
            ) {
                const file = videoLiveSellingStore.dropzoneFile.get('file') as File;
                const uploadData = await uploadClip({ fileName: file.name });

                if (!uploadData.data) return;
                const { uploadFileUrl, clip } = uploadData.data;
                const clipPublicURL = `${clip.cdnUrl}${clip.metaData.videoURL}`;

                videoLiveSellingStore.uploadedPopClipData = {
                    uploadVideoUrl: uploadFileUrl,
                    clipId: clip.id,
                    clipMetaIdentifier: clip.metaIdentifier,
                    clipMetaData: clip.metaData,
                };

                popclipId = clip.metaIdentifier;

                const isVideoStored = await storeVideoInAWS(
                    uploadFileUrl,
                    file,
                );
                if (!isVideoStored) return;
                videoLiveSellingStore.setVideoUrl(clipPublicURL);
            }

            const response = await updateClip();

            const endTime = new Date().getTime();
            const timeTaken = endTime - startTime;
            if (
                response?.data?.item &&
                response?.data?.message &&
                response?.data?.message === 'success'
            ) {
                handleEventCalls(response?.data?.item, timeTaken, popclipId);
                if (!getStartedStatus.events.STEP_ADD_YOUR_FIRST_POPCLIP) {
                    await shopStore.updateGetStartedStatus(
                        'STEP_ADD_YOUR_FIRST_POPCLIP',
                    );
                }
            }

            if (goToFirstPage) {
                shopify.toast.show(
                    'Your video is processing, please check back after some time',
                    {
                        isError: false,
                        duration: 8000,
                    },
                );
            }

            videoLiveSellingStore.editClipData = null;
            videoLiveSellingStore.dropzoneFile = null;

            // TODO:: this is just a workaround for now
            setTimeout(() => {
                navigate(popclipsRoutes.list, {
                    state: { goToFirstPage },
                });
            }, 300);
        } catch (error) {
            console.log(error);
            shopify.toast.show(
                'Something went wrong while trying to upload clip. Please contact support.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
        } finally {
            videoLiveSellingStore.savingPopclip = false;
            setIsApiLoading(false);
        }
    };

    const handleEventCalls = (responseData, timeTaken, popclipId) => {
        analyticsEventStore.popclipsUploadedAppEvent(responseData);

        if (!editMode) {
            analyticsEventStore.popclipUploadTriggered(false, {
                duration: Math.floor(timeTaken / 1000),
                popclipsID: popclipId,
                title: clipTitle,
                source: clipInstaUrl ? 'instagram' : 'device',
                instaReelUrl: clipInstaUrl,
            });
        }
    };

    return (
        <BaseLayout
            title={
                editMode
                    ? 'Edit Popclip (Shoppable Video)'
                    : 'Create New Popclip (Shoppable Video)'
            }
            headerAction={
                <InlineStack gap="300" align="center" blockAlign="center">
                    {isApiLoading && <Spinner size="small" />}
                    <Button
                        variant="primary"
                        submit
                        onClick={handleSaveClick}
                        disabled={isApiLoading || savingPopclip}
                    >
                        Save
                    </Button>
                </InlineStack>
            }
            backToLink={popclipsRoutes.list}
        >
            <InlineStack gap="400" wrap={false}>
                <Box minWidth="792px">
                    <BlockStack gap="400">
                        <ClipsFormCard />
                        <PopClipsCard>
                            {editMode && !editClipData ? (
                                <InlineStack
                                    gap="200"
                                    align="center"
                                    blockAlign="center"
                                >
                                    <Spinner size="small" />
                                    <span>Loading...</span>
                                </InlineStack>
                            ) : (
                                <ProductListCard />
                            )}
                        </PopClipsCard>
                    </BlockStack>
                </Box>
                <PopClipsCard>
                    <VideoPreview />
                </PopClipsCard>
            </InlineStack>
        </BaseLayout>
    );
};

async function storeVideoInAWS(
    signedlink: string,
    videoFile: File,
): Promise<boolean> {
    try {
        const dataAfterUpload = await fetch(signedlink, {
            method: 'PUT',
            headers: { 'Content-Type': videoFile.type },
            body: videoFile,
        });

        return dataAfterUpload && dataAfterUpload.status == 200;
    } catch (error) {
        console.error('Error in uploadURL', error);
        return false;
    }
}

export default CreatePopClipPage;
