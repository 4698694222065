import { Button } from "@shopify/polaris";
import { zendeskSupportLogo } from "../../assets/images";
import { shopStore } from "@/stores";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";

const ZendeskSupportWidget = () => {
    const shopStoreSnap = useSnapshot(shopStore);
    const [shouldShowZendeskBtn, setShouldShowZendeskBtn] = useState(false);

    useEffect(() => {
        if (
            shopStoreSnap.csStoreCreated &&
            shopStoreSnap.subscriptionActive &&
            shopStoreSnap.config?.zendesk?.key
        ) {
            setShouldShowZendeskBtn(true);
        } else {
            console.warn('Zendesk widget is not enabled');
        }
    }, [
        shopStoreSnap.csStoreCreated,
        shopStoreSnap.subscriptionActive,
        shopStoreSnap.config
    ]);

    const activateZendeskWidget = () => window.zE?.activate({ hideOnClose: true });

    return (
        <>
            {shouldShowZendeskBtn ? (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '1rem',
                        right: '1rem',
                        cursor: 'pointer',
                    }}
                >
                    <Button
                        variant="tertiary"
                        icon={
                            <img
                                src={zendeskSupportLogo}
                                alt="Support logo"
                                style={{ height: '36px', objectFit: 'cover' }}
                            />
                        }
                        onClick={activateZendeskWidget}
                        accessibilityLabel="Support Button"
                    />
                </div>
            ) : null}
        </>
    );
};

export default ZendeskSupportWidget;