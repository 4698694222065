import { useEffect, useState } from 'react';
import {
    Box,
    InlineStack,
    DropZone,
    Button,
    InlineGrid,
} from '@shopify/polaris';
import { useVFSAuthFetch } from '@/hooks/useAuthenticatedFetch';
import { XIcon } from '@shopify/polaris-icons';
import shopStore from '@/stores/shopStore';

type ImagePickerProps = {
    readonly?: boolean;
    labelStr: string;
    imageUrl: string;
    hintText: string;
    validateDimension?: boolean;
    imageDimensions?: {
        width: number;
        height: number;
    };
    setImageInStore: (publicUrl: string) => void;
    removeImageInStore: (arg: string) => void;
    isAppRequestSubmitted?: boolean;
};

const AppColorPicker = (props: ImagePickerProps) => {
    const [openFileDialog, setOpenFileDialog] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const vfsAuthFetch = useVFSAuthFetch();
    const handleDropZone = async (
        _dropFiles: File[],
        acceptedFiles: File[],
    ) => {
        const shopifyDomain = shopStore.shopifyDomain;
        const contentType = acceptedFiles[0].type;
        if (props.validateDimension && props.imageDimensions) {
            const isValidImgSize = await validateSquare(
                acceptedFiles[0],
                props.imageDimensions,
            );

            if (!isValidImgSize) {
                shopify.toast.show(
                    `Selected image must be ${props.imageDimensions.width}x${props.imageDimensions.height}`,
                    {
                        isError: true,
                        duration: 5000,
                    },
                );
                return;
            }
        }

        const signedlink = await getSignedLink(
            vfsAuthFetch,
            shopifyDomain,
            contentType,
        );

        if (!signedlink) return;

        const isImageStored = await storeImageInAWS(
            signedlink.uploadURL,
            acceptedFiles[0],
        );

        if (!isImageStored) return;

        props.setImageInStore(signedlink.publicUrl);
        setIsDisabled(true);
    };
    const handleRemoveImage = () => {
        props.removeImageInStore('');
        setIsDisabled(false);
    };

    useEffect(() => {
        if(props.imageUrl === ''){
            setIsDisabled(false);
        }
    },[props.imageUrl])
    return (
        <div
            style={
                props.readonly ? { pointerEvents: 'none', opacity: 0.9 } : {}
            }
        >
            <Box>
                <InlineGrid
                    columns={!props.imageUrl ? 1 : ['twoThirds', 'oneThird']}
                    gap="600"
                    alignItems="end"
                >
                    <DropZone
                        accept="image/*"
                        type="image"
                        label={props.labelStr}
                        allowMultiple={false}
                        openFileDialog={openFileDialog}
                        onDrop={handleDropZone}
                        onFileDialogClose={() =>
                            setOpenFileDialog(
                                (openFileDialog) => !openFileDialog,
                            )
                        }
                        disabled={isDisabled || !props.isAppRequestSubmitted}
                    >
                        <DropZone.FileUpload
                            actionTitle="Add Image"
                            actionHint={props.hintText}
                        />
                    </DropZone>
                    {props.imageUrl && (
                        <InlineStack
                            gap="100"
                            blockAlign="start"
                            align="center"
                        >
                            <img
                                src={props.imageUrl}
                                style={{
                                    maxWidth: '90%',
                                    width: '128px',
                                    height: 'auto',
                                    border: '1px #ffff',
                                    borderRadius: '8px',
                                }}
                            />
                            <Box>
                                <Button
                                    variant="tertiary"
                                    onClick={handleRemoveImage}
                                    icon={XIcon}
                                ></Button>
                            </Box>
                        </InlineStack>
                    )}
                </InlineGrid>
            </Box>
        </div>
    );
};

const validateSquare = async (
    file: File,
    imageDimensions: { width: number; height: number },
): Promise<boolean> => {
    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            if (e.target && e.target.result) {
                const img = new Image();
                img.onload = () => {
                    const { width, height } = img;
                    resolve(
                        Boolean(
                            imageDimensions.height == height &&
                                imageDimensions.width == width,
                        ),
                    );
                };
                img.src = e.target.result as string;
            }
        };

        reader.readAsDataURL(file);
    });
};

async function getSignedLink(
    vfsAuthFetch: ReturnType<typeof useVFSAuthFetch>,
    shopifyDomain: string,
    contentType: string,
): Promise<Record<string, string> | null> {
    try {
        const { data } = await vfsAuthFetch<{
            success: boolean;
            uploadURL: string;
            publicUrl: string;
        }>(
            `files/signed-link?path=apps/${shopifyDomain}&contentType=${contentType}&public=true`,
            { method: 'GET' },
        );

        if (!data || !data.uploadURL) {
            console.error("Error: 'uploadURL' not found in the response data");
            return null;
        }

        return {
            uploadURL: data.uploadURL,
            publicUrl: data.publicUrl,
        };
    } catch (error) {
        console.error('Error while uploading', error);
        return null;
    }
}

async function storeImageInAWS(
    signedlink: string,
    imgFile: File,
): Promise<boolean> {
    try {
        const dataAfterUpload = await fetch(signedlink, {
            method: 'PUT',
            headers: { 'Content-Type': imgFile.type },
            body: imgFile,
        });

        return dataAfterUpload && dataAfterUpload.status == 200;
    } catch (error) {
        console.error('Error in uploadURL', error);
        return false;
    }
}

export default AppColorPicker;
