import { billingStore } from "@/stores";
import VfsModal from "../VfsModal";
import { ATTRIBUTE_IDS } from "@/constants/planFeatureCard.constants";
import MobilePlanAddonCard from "../PlanSelection/MobilePlanAddonCard";
import { BlockStack, Box, Button, Icon, InlineGrid, InlineStack, Text } from "@shopify/polaris";
import { CheckIcon } from "@shopify/polaris-icons";

type SelectPlanState = {
    planId: string;
    type: string;
    skuCode: string;
} | undefined;

type MobileAppPlanModalProps = {
    selectedPlan: SelectPlanState;
    onHidePlanModal: (planData: SelectPlanState) => void;
};

const featureType = 'mobile';

export default function MobileAppPlanModal({
    onHidePlanModal,
    selectedPlan,
}: MobileAppPlanModalProps) {
    const addons = billingStore.getAddonsByModule(featureType, ATTRIBUTE_IDS[featureType]);
    const addonsPlansValues = billingStore.getAddonButtonGroupValues(addons, ATTRIBUTE_IDS[featureType]);
    const filteredAddons = addons.filter((addon) => ['basic', 'pro', 'premium'].includes(addon.name.toLowerCase()));

    const selectAddon = (addon: any) => {
        if (selectedPlan?.type === addon.name.toLowerCase()) {
            onHidePlanModal(undefined);
        } else {
            onHidePlanModal({
                planId: addon.id,
                type: addon.name.toLowerCase(),
                skuCode: addon.skuCode,
            });
        }
    }
    
    return (
        <VfsModal
            onCloseModal={() => onHidePlanModal(selectedPlan)}
            title="Mobile App Plans"
            modalWidth="53rem"
        >
            <BlockStack gap="600">
                <Box
                    borderRadius="500"
                    background="bg-fill-active"
                    padding="500"
                >
                    <Box paddingBlockEnd="300">
                        <Text
                            as="p"
                            fontWeight="bold"
                            variant="bodyMd"
                            tone="subdued"
                        >
                            Highlights:
                        </Text>
                    </Box>
                    <InlineStack
                        gap="500"
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                    >
                        <BlockStack gap="200">
                            <InlineStack
                                gap="200"
                                wrap={false}
                                align="start"
                                blockAlign="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon source={CheckIcon} tone="emphasis" />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    Look & Feel Cusomization
                                </Text>
                            </InlineStack>
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon source={CheckIcon} tone="emphasis" />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    In App Favorites/Wishlist
                                </Text>
                            </InlineStack>
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon source={CheckIcon} tone="emphasis" />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    iOS Mobile App (Android in Pro Plan +)
                                </Text>
                            </InlineStack>
                        </BlockStack>
                        <BlockStack gap="200">
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon source={CheckIcon} tone="emphasis" />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    In App Shoppable Video Clips
                                </Text>
                            </InlineStack>
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon source={CheckIcon} tone="emphasis" />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    In App Social-like Shoppable Feed
                                </Text>
                            </InlineStack>
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon source={CheckIcon} tone="emphasis" />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    Constant security & feature updates + App
                                    maintenance
                                </Text>
                            </InlineStack>
                        </BlockStack>
                        <BlockStack gap="200">
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon source={CheckIcon} tone="emphasis" />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    In App Shoppable Live Stream
                                </Text>
                            </InlineStack>
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon source={CheckIcon} tone="emphasis" />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    Fully integrated with Shopify checkout
                                </Text>
                            </InlineStack>
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon source={CheckIcon} tone="emphasis" />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    Guest Checkout
                                </Text>
                            </InlineStack>
                        </BlockStack>
                    </InlineStack>
                </Box>
                <InlineGrid columns={2} gap="400">
                    {filteredAddons.map((addon, i) => (
                        <div className="flex-1 card-highlight" key={i}>
                            <MobilePlanAddonCard
                                addonData={addon}
                                addonValue={addonsPlansValues[i].value || ''}
                                isSelected={
                                    selectedPlan?.type ===
                                    addon.name.toLowerCase()
                                }
                                isDisabled={false}
                                onSelectPlan={() => selectAddon(addon)}
                            />
                        </div>
                    ))}
                </InlineGrid>
                <Box
                    padding="400"
                    borderWidth="025"
                    borderRadius="500"
                    shadow="200"
                    borderColor="border-brand"
                >
                    <InlineStack align="space-between" blockAlign="center">
                        <Text as="p" variant="headingMd" fontWeight="bold">
                            Need more app downloads? Reach out to us!
                        </Text>
                        <Button
                            variant="primary"
                            target="_blank"
                            url="https://calendly.com/videeo/initial-videeo-onboarding"
                        >
                            Contact Sales
                        </Button>
                    </InlineStack>
                </Box>
            </BlockStack>
        </VfsModal>
    );
}