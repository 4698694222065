import {
    BlockStack,
    Box,
    Card,
    InlineStack,
    Spinner,
    Text,
} from '@shopify/polaris';
import Plot from 'react-plotly.js';
import DashboardHeaderSection from './DashboardHeaderSection';
import DashboardIndicatorTag from './DashboardIndicatorTag';
import { CONFIG, LAYOUT, LINE_SMOOTHING_VALUE } from './chartConstantConfig';
import { useEffect, useState } from 'react';
import { shopStore } from '@/stores';
import { useSnapshot } from 'valtio';

interface DashboardTotalViewsChartPropsTypes {
    title: string;
    value: number;
    chartData: number[];
    labels: string[];
    isLoading: boolean;
    growthInValue: number;
}

const DashboardTotalViewsChart = ({
    title,
    value,
    chartData,
    labels,
    isLoading,
    growthInValue,
}: DashboardTotalViewsChartPropsTypes) => {
    const [isExpand, setIsExpand] = useState(false);
    const { subscription } = useSnapshot(shopStore);
    const [totalViews, setTotalViews] = useState(0);

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            'popclips',
            true,
        );
        const maxPrice = Math.max(...addonPrices);
        console.log('maxPrice :', maxPrice);
        if (maxPrice < 29.99) {
            setTotalViews(2000);
        } else if (maxPrice == 29.99) {
            setTotalViews(15000);
        } else if (maxPrice > 29.99) {
            setTotalViews(125000);
        }
    }, [subscription]);

    const DrawTotalViewsChart = () => {
        return (
            <Plot
                data={[
                    // Shadow area
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'none',
                        fill: 'tozeroy', // Fills the area to the x-axis
                        fillcolor: 'rgba(63, 200, 228, 0.1)', // Transparent shadow color
                        hoverinfo: 'skip', // Disables hover for shadow area
                    },
                    // Line
                    {
                        x: labels,
                        y: chartData,
                        type: 'scatter',
                        mode: 'lines',
                        line: {
                            color: '#3FC8E4',
                            width: 3, // Adjust line thickness
                            shape: 'spline', // Makes the line curved
                            smoothing: LINE_SMOOTHING_VALUE, // Controls the smoothness of the curve
                        },
                        name: 'Views',
                    },
                ]}
                layout={{
                    ...LAYOUT,
                    height: 200,
                    width: isExpand ? '' : 500,
                    autosize: isExpand,
                    margin: { t: 50, l: 50, r: 50, b: 50 },
                }}
                config={CONFIG}
            />
        );
    };

    return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'grid',
                }}
            >
                <Box
                    borderWidth="025"
                    borderColor="border"
                    borderRadius="500"
                    padding="600"
                    background="bg-fill"
                    shadow="200"
                    width='100%'
                >
                    <DashboardHeaderSection
                        title="Popclips Views"
                        showResizeButton={true}
                        isExpand={isExpand}
                        handleResizeChart={handleResizeChart}
                    />
                    <BlockStack gap="400">
                        <InlineStack blockAlign="center" wrap={false} gap="200">
                            <InlineStack
                                blockAlign="center"
                                gap="100"
                                wrap={false}
                            >
                                <Text variant="heading3xl" as="h2">
                                    {value}
                                </Text>
                                <Text
                                    variant="heading3xl"
                                    as="h2"
                                    tone="subdued"
                                >
                                    /
                                </Text>
                                <Text
                                    variant="heading3xl"
                                    as="h2"
                                    tone="subdued"
                                >
                                    {totalViews}
                                </Text>
                            </InlineStack>
                            <Text variant="heading3xl" as="h2">
                                Views
                            </Text>
                        </InlineStack>
                        <DashboardIndicatorTag
                            growthInValue={growthInValue}
                            isMonth={false}
                        />
                    </BlockStack>
                    {isLoading ? <Spinner /> : <DrawTotalViewsChart />}
                </Box>
                 {/* Expand view code */}
            {isExpand && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        bottom: 'auto',
                        right: 'auto',
                        background: 'rgba(0,0,0,0.8)',
                        width: '100%',
                        height: '100%',
                        zIndex: '2147483647',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Card>
                        <DashboardHeaderSection
                            title="Popclips Views"
                            showResizeButton={true}
                            isExpand={isExpand}
                            handleResizeChart={handleResizeChart}
                        />
                        <BlockStack gap="400">
                            <InlineStack
                                blockAlign="center"
                                wrap={false}
                                gap="200"
                            >
                                <InlineStack
                                    blockAlign="center"
                                    gap="100"
                                    wrap={false}
                                >
                                    <Text variant="heading3xl" as="h2">
                                        {value}
                                    </Text>
                                    <Text
                                        variant="heading3xl"
                                        as="h2"
                                        tone="subdued"
                                    >
                                        /
                                    </Text>
                                    <Text
                                        variant="heading3xl"
                                        as="h2"
                                        tone="subdued"
                                    >
                                        5000
                                    </Text>
                                </InlineStack>
                                <Text variant="heading3xl" as="h2">
                                    Views
                                </Text>
                            </InlineStack>
                            <DashboardIndicatorTag
                                growthInValue={growthInValue}
                                isMonth={false}
                            />
                        </BlockStack>
                        {isLoading ? (
                            <InlineStack align="center" blockAlign="center">
                                <Spinner />
                            </InlineStack>
                        ) : (
                            <DrawTotalViewsChart />
                        )}
                    </Card>
                </div>
            )}
            </div>

    );
};

export default DashboardTotalViewsChart;
