import shopStore from '@/stores/shopStore';

/**
 * A hook that returns an auth-aware fetch function.
 * @desc The returned fetch function that matches the browser's fetch API
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 * It will provide the following functionality:
 *
 * @returns {Function} fetch function
 */

export const useVFSAuthFetch = () => {
    const VFS_API_URL = shopStore.config?.legacyVfsService.apiUrl;
    let vfsApiURL = VFS_API_URL;
    if (vfsApiURL?.endsWith('/')) {
        vfsApiURL = vfsApiURL.slice(0, -1);
    }
    const fetchFunction = (uri, options) => {
        if (!uri.startsWith('/')) {
            uri = `/${uri}`;
        }
        return fetch(vfsApiURL + uri, {
            ...options,
            headers: {
                ...options?.headers,
                Authorization: `Bearer ${shopStore.csToken}`,
            },
        });
    };

    return async <T,>(uri: RequestInfo, options?: RequestInit | undefined) => {
        let headers: HeadersInit = {
            ...options?.headers,
            Accept: 'application/json',
        };
        if (options?.method !== 'GET') {
            headers = {
                ...headers,
                'Content-Type': 'application/json',
            };
        }
        const response = await fetchFunction(uri, {
            ...options,
            headers,
        });
        checkHeadersForReauthorization(response.headers);
        const isJson = response.headers
            .get('content-type')
            ?.includes('application/json');
        const data = isJson ? ((await response.json()) as T) : null;
        if (!response.ok) {
            const error = data || response.status;
            return Promise.reject(error);
        }
        return { data, ...response };
    };
};

export const useMediaGalleryAuthFetch = () => {
    const MEDIA_GALLERY_API_URL = shopStore.config?.mediaGallery.apiUrl;
    let mediaGalleryApiURL = MEDIA_GALLERY_API_URL;
    if (mediaGalleryApiURL?.endsWith('/')) {
        mediaGalleryApiURL = mediaGalleryApiURL.slice(0, -1);
    }
    const fetchFunction = (uri, options) => {
        if (!uri.startsWith('/')) {
            uri = `/${uri}`;
        }
        return fetch(mediaGalleryApiURL + uri, {
            ...options,
            headers: {
                ...options?.headers,
                Authorization: shopStore.csToken,
            },
        });
    };

    return async <T,>(uri: RequestInfo, options?: RequestInit | undefined) => {
        let headers: HeadersInit = {
            ...options?.headers,
            Accept: 'application/json',
        };
        if (options?.method !== 'GET') {
            headers = {
                ...headers,
                'Content-Type': 'application/json',
            };
        }
        const response = await fetchFunction(uri, {
            ...options,
            headers,
        });
        checkHeadersForReauthorization(response.headers);
        const isJson = response.headers
            .get('content-type')
            ?.includes('application/json');
        const data = isJson ? ((await response.json()) as T) : null;
        if (!response.ok) {
            const error = data || response.status;
            return Promise.reject(error);
        }
        return { data, ...response };
    };
};

function checkHeadersForReauthorization(headers) {
    if (headers.get('X-Shopify-API-Request-Failure-Reauthorize') === '1') {
        const authUrlHeader =
            headers.get('X-Shopify-API-Request-Failure-Reauthorize-Url') ||
            `/api/auth`;
            
        const redirectTo = authUrlHeader.startsWith('/')
            ? `https://${window.location.host}${authUrlHeader}`
            : authUrlHeader;

        open(redirectTo, '_top');
    }
}