import { vfsRoutes } from "@/constants/routes.constants";
import { Modal, TitleBar } from "@shopify/app-bridge-react";
import { BlockStack, Box, Button, InlineStack, Text } from "@shopify/polaris";
import { poclips2kCarousel } from "../../assets/images";

type PopclipsCarousel2kBannerProps = {
    modalOpen: boolean;
    toggleHideModal: () => void;
};

export default function PopclipsCarousel2kBanner({
    modalOpen,
    toggleHideModal,
}: PopclipsCarousel2kBannerProps) {
    return (
        <Modal open={modalOpen} variant="base">
            <TitleBar title="🎉 Exciting Update!" />
            <Box padding="800" minHeight="600px">
                <BlockStack gap="600" align="center">
                    <img src={poclips2kCarousel} alt="poclips2kCarousel" />
                    <Text as="p" alignment="center">
                        Your free plan now includes 2,000 views per month—
                        <b>more than 2X</b> the industry standard! Plus, explore
                        our new <b>Customizable Popclips Carousel</b>{' '}
                        and <b>On-Video Views Counter</b>, now available in the
                        Basic Plan for just $29.99/month. Capture attention and
                        convert viewers to buyers with unique Popclips on every
                        page.
                    </Text>
                    <InlineStack gap="200" align="center" blockAlign="center">
                        <Button
                            url={vfsRoutes.managePlans}
                            variant="primary"
                            tone="success"
                        >
                            Upgrade just for $29.99/month
                        </Button>
                        <Button onClick={toggleHideModal}>Continue with Free Plan</Button>
                    </InlineStack>
                </BlockStack>
            </Box>
        </Modal>
    );
}