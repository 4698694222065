import {
    Box,
    Autocomplete,
    BlockStack,
    InlineStack,
    Button,
    Text,
    Card,
    Icon,
    Thumbnail,
    InlineError,
} from '@shopify/polaris';
import { useSnapshot } from 'valtio';

import {
    buildMobileAppStore,
    shopCollectionStore,
} from '@/stores';

import { CollapsibleCardWithTitleSection } from '@/components/common';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useGetCollections } from '@/hooks/useGetCollections';
import { useGetCollectionProducts } from '@/hooks/useGetCollectionProducts';
import { CheckCircleIcon } from '@shopify/polaris-icons';
import shopStore from '@/stores/shopStore';

type FeaturedCollectionCardProps = {
    readonly?: boolean;
};
export const FeaturedCollectionCard: FC<FeaturedCollectionCardProps> = ({
    readonly,
}) => {
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });
    const shopCollectionSnapshot = useSnapshot(shopCollectionStore, {
        sync: true,
    });
    const { getCollectionProducts } = useGetCollectionProducts();
    const { getCollections } = useGetCollections();
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedCollectionErr, setSelectedCollectionErr] = useState('');
    const { shopifyDomain } = useSnapshot(shopStore);
    const { featuredShopifyCollectionGid } = buildMobileAppSnapshot;
    useEffect(() => {
        const getStoredCollection = async () => {
            if (featuredShopifyCollectionGid) {
                const { collection } = await getCollectionProducts(
                    featuredShopifyCollectionGid,
                );
                if (collection) {
                    shopCollectionStore.addCollection({
                        label: collection.title,
                        value: featuredShopifyCollectionGid,
                    });
                    shopCollectionStore.setSelectedCollection(
                        featuredShopifyCollectionGid,
                    );
                    setSelectedOption(featuredShopifyCollectionGid);
                    setInputValue(collection.title);
                }
            }
        };
        getStoredCollection();
        //this should only be set by GET /app-config, so it will only update on page load
    }, [featuredShopifyCollectionGid]);
    useEffect(() => {
        if (!shopifyDomain) {
            return;
        }
        getCollections('');
    }, [shopifyDomain]);

    const timeoutRef = useRef(0);
    const updateResults = useCallback((value: string) => {
        setInputValue(value);
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(async () => {
            await getCollections(value);
        }, 500);
    });

    const handleSelect = useCallback((value: string) => {
        setInputValue(
            shopCollectionStore.collections.find(
                (option) => option.value === value,
            )?.label,
        );
        shopCollectionStore.setSelectedCollection(value);
        setSelectedOption(value);
        getCollectionProducts(value);
    });

    const textField = (
        <Autocomplete.TextField
            onChange={updateResults}
            label={
                <Text as="p">
                    Collections
                    <Text as="span" tone="critical">
                        {' '}
                        *
                    </Text>
                </Text>
            }
            value={inputValue}
            placeholder="Search Collections"
            autoComplete="off"
            readOnly={readonly}
        />
    );
    const handleNext = () => {
        if (shopCollectionStore.selectedCollection) {
            setSelectedCollectionErr('')
            buildMobileAppStore.setIsAppSubmissionCardOpen(true);
            return;
        } else {
            setSelectedCollectionErr('Please select a collection');
            return;
        }
    };
    return (
        <CollapsibleCardWithTitleSection
            title="Home Collection"
            subtitle="Choose the collection you want to feature in your app’s home page"
            showCheck={false}
            open={buildMobileAppSnapshot.isFeaturedCollectionCardOpen}
            onToggleOpen={buildMobileAppStore.setIsFeaturedCollectionCardOpen}
            hideSubtitleWhenClosed={true}
        >
            <Box>
                <BlockStack gap="400">
                    {shopCollectionSnapshot.productCollectionCount && (
                        <Card background="bg-fill-success-secondary">
                            <Box>
                                <InlineStack gap="400" align="space-between">
                                    <InlineStack blockAlign="center" gap="400">
                                        <Icon
                                            source={CheckCircleIcon}
                                            tone="success"
                                        />
                                        <Text fontWeight="semibold">
                                            {
                                                shopCollectionSnapshot.productCollectionCount
                                            }{' '}
                                            products have been added from this
                                            collection
                                        </Text>
                                    </InlineStack>
                                    <InlineStack gap="200">
                                        {shopCollectionSnapshot.productCollectionUrls.map(
                                            (url) => (
                                                <Thumbnail
                                                    size="small"
                                                    source={url}
                                                    alt="Example product image from collection"
                                                    key={url}
                                                />
                                            ),
                                        )}
                                    </InlineStack>
                                </InlineStack>
                            </Box>
                        </Card>
                    )}
                    <InlineError message={selectedCollectionErr} fieldID="" />
                    <div style={readonly ? { pointerEvents: 'none' } : {}}>
                        <Autocomplete
                            options={shopCollectionStore.collections}
                            selected={selectedOption}
                            emptyState={
                                <Box padding="300">
                                    <Text>No Results</Text>
                                </Box>
                            }
                            loading={
                                shopCollectionSnapshot.areCollectionsLoading
                            }
                            onSelect={([value]: [string]) => {
                                handleSelect(value);
                            }}
                            textField={textField}
                        />
                    </div>
                    <InlineStack align="end">
                        <Button
                            variant="primary"
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    </InlineStack>
                </BlockStack>
            </Box>
        </CollapsibleCardWithTitleSection>
    );
};
