import { BlockStack, Box } from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import videoLiveSellingStore from '@/stores/videoLiveSellingStore';
import UploadVideoLogo from '/assets/images/uploadVideoLogo.png';
import ClipExcludeCheckBox from './ClipExcludeCheckBox';

const VideoPreview = () => {
    const { videoUrl } = useSnapshot(videoLiveSellingStore);

    return (
        <>
            {videoUrl && videoUrl.trim() != '' ? (
                <BlockStack gap="400">
                    <video
                        muted
                        autoPlay
                        loop
                        width="368"
                        height="716"
                        controls
                        style={{
                            borderRadius: '8px',
                            objectFit: 'cover',
                        }}
                    >
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <ClipExcludeCheckBox />
                </BlockStack>
            ) : (
                <Box
                    background="bg-surface-active"
                    borderRadius="150"
                    outlineColor="input-border"
                    outlineStyle="dashed"
                    outlineWidth="025"
                >
                    <div
                        style={{
                            display: 'flex',
                            width: '368px',
                            height: '716px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            alt="UploadVideoLogo"
                            width="180px"
                            height="180px"
                            src={UploadVideoLogo}
                        />
                    </div>
                </Box>
            )}
        </>
    );
};

export default VideoPreview;
