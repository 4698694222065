import { BlockStack, InlineStack } from "@shopify/polaris";

type PlanCardProps = {
    isSelected: boolean;
    isCustomPlan: boolean;
    isDisabled: boolean;
    planData: any;
    handleClick: () => void;
};

export default function LiveSellingPlanCard({
    isSelected,
    isCustomPlan,
    planData,
    isDisabled,
    handleClick,
}: PlanCardProps) {
    return (
        <div className="subscription-page__feature-card">
            <div>
                <p className="feature-card__title">Live Selling</p>
                <p className="feature-card__description">
                    Give your Shopify store the power of live selling!
                </p>
                {isCustomPlan ? (
                    <>
                        <p className="feature-card__plan-selected">
                            Active Plan
                        </p>
                        <p className="plan-name">Custom Plan</p>
                    </>
                ) : (
                    <>
                        {planData ? (
                            <>
                                <p className="feature-card__plan-selected">
                                    Active Plan
                                </p>
                                {planData.map((plan, i) => (
                                    <InlineStack
                                        key={i}
                                        align="space-between"
                                        blockAlign="center"
                                    >
                                        <BlockStack>
                                            <p className="plan-name">
                                                {plan.addonName}
                                            </p>
                                            <p className="plans-combined-name">
                                                {plan.attrString}
                                            </p>
                                        </BlockStack>
                                        <p className="plan-total-price">
                                            {plan.price}
                                        </p>
                                    </InlineStack>
                                ))}
                            </>
                        ) : (
                            <div>
                                <p className="dont-have-plan__title">
                                    You don't currently have an active plan.
                                </p>
                                <p className="dont-have-plan__body">
                                    Please click on{' '}
                                    <strong>"Select Plan"</strong> below to view
                                    our offerings and get started to make more
                                    sales with live selling.
                                </p>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div>
                {!isCustomPlan && planData && (
                    <button
                        className={`${
                            isSelected
                                ? 'feature-card__upgrade-button-selected'
                                : 'feature-card__upgrade-button'
                        }`}
                        disabled={isDisabled}
                        onClick={handleClick}
                    >
                        {isSelected ? 'Selected' : 'Upgrade'}
                    </button>
                )}
                {!isCustomPlan && !planData && (
                    <button
                        className={`${
                            isSelected
                                ? 'feature-card__upgrade-button-selected'
                                : 'feature-card__select-plan-button'
                        }`}
                        disabled={isDisabled}
                        onClick={handleClick}
                    >
                        {isSelected ? 'Selected' : 'Select Plan'}
                    </button>
                )}
            </div>
        </div>
    );
}