import { Text } from "@shopify/polaris";
import PopclipsPlanAddonCard from './PopclipsPlanAddonCard';

type AddonStyledComponentProps = {
    type: 'free' | 'basic' | 'premium';
    addonData: any;
    addonValue: string;
    selectedPlan: string;
    isDisabled: boolean;
    onSelectPlan: () => void;
};

export default function AddonStyledComponent({
    type,
    addonData,
    addonValue,
    selectedPlan,
    onSelectPlan,
    isDisabled,
}: AddonStyledComponentProps) {
    if (type == 'free') {
        return (
            <div className="flex-1 card-highlight">
                <PopclipsPlanAddonCard
                    cardType="free"
                    addonData={addonData}
                    addonValue={addonValue}
                    isSelected={selectedPlan === 'free'}
                    onSelectPlan={onSelectPlan}
                    isDisabled={isDisabled}
                />
            </div>
        );
    } else if (type == 'basic') {
        return (
            <div className="flex-1 position-relative card-highlight">
                <div className="most-popular-badge">
                    <Text
                        as="p"
                        fontWeight="bold"
                        variant="bodyMd"
                        alignment="center"
                    >
                        Most Popular
                    </Text>
                </div>
                <div className="most-popular-badge-plan-card most-popular-animated-border">
                    <PopclipsPlanAddonCard
                        cardType="basic"
                        addonData={addonData}
                        addonValue={addonValue}
                        isSelected={selectedPlan === 'basic'}
                        onSelectPlan={onSelectPlan}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>
        );
    } else if (type == 'premium') {
        return (
            <div className="flex-1 position-relative card-highlight">
                <div className="best-value-badge">
                    <Text
                        as="p"
                        fontWeight="bold"
                        variant="bodyMd"
                        alignment="center"
                    >
                        Best Value
                    </Text>
                </div>
                <div className="best-value-badge-plan-card animated-border">
                    <PopclipsPlanAddonCard
                        cardType="premium"
                        addonData={addonData}
                        addonValue={addonValue}
                        isSelected={selectedPlan === 'premium'}
                        onSelectPlan={onSelectPlan}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>
        );
    }
};
