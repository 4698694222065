import { PopClipsGridListing } from '@/components/popclips';
import { BlockStack } from '@shopify/polaris';
import FeatureReleaseCalloutCard from '@/components/common/FeatureReleaseCalloutCard';
import PopClipsCard from '@/components/common/PopClipsCard';
import { popclipsStore, shopStore } from '@/stores';
import { useEffect, useRef, useState } from 'react';
import { useSnapshot } from 'valtio';
import { openEmbedWindow } from '@/utils/helpers';
import CustomCarouselIntroCard from './carouselComponents/CustomCarouselIntroCard';
import PopclipsCarousel2kBanner from '@/components/common/PopclipsCarousel2kBanner';
import { vfsRoutes } from '@/constants/routes.constants';
import { BaseLayout } from '../../v2/pages/Layout/BaseLayout';

const PopClipsGridPage = () => {
    const themeWindowRef = useRef<Window | null>();
    const [showPopclipsCarousel2kBanner, setShowPopclipsCarousel2kBanner] = useState(false);
    const [showPopclipsLockButton, setShowPopclipsLockButton] = useState(false);
    const [showCustomCarousel, setShowCustomCarousel] = useState(false);

    const { subscription, popclipCarouselEmbedEnableUrl, getStartedStatus } = useSnapshot(shopStore);
    const { popclipsCarousels } = useSnapshot(popclipsStore);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices('popclips', true);
        const maxPrice = Math.max(...addonPrices);

        setShowCustomCarousel(maxPrice >= 29.99);
        setShowPopclipsCarousel2kBanner(maxPrice < 29.99);
        setShowPopclipsLockButton(maxPrice < 29.99)
    }, [subscription]);

    const handleOpenEmbedWindow = async () => {
        openEmbedWindow(themeWindowRef, popclipCarouselEmbedEnableUrl);
        if (!getStartedStatus.events.STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL) {
            await shopStore.updateGetStartedStatus(
                'STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL',
            );
        }
    };

    return (
        <BaseLayout
            title="Your Popclips"
            subtitle="All Popclips - Shoppable Videos linked to your account"
            backToLink={vfsRoutes.landing}
        >
            <BlockStack gap="400">
                {!showCustomCarousel && popclipCarouselEmbedEnableUrl && (
                    <FeatureReleaseCalloutCard
                        openEmbedWindow={handleOpenEmbedWindow}
                    />
                )}
                {showCustomCarousel && (
                    <PopClipsCard
                        cardHeader={
                            popclipsCarousels.length == 0 ? (
                                <CustomCarouselIntroCard
                                    hasCustomCarousel={false}
                                    showPopclipsLockButton={
                                        showPopclipsLockButton
                                    }
                                    openEmbedWindow={handleOpenEmbedWindow}
                                />
                            ) : (
                                <CustomCarouselIntroCard
                                    hasCustomCarousel={true}
                                    showPopclipsLockButton={
                                        showPopclipsLockButton
                                    }
                                    openEmbedWindow={handleOpenEmbedWindow}
                                />
                            )
                        }
                    />
                )}
                <PopClipsGridListing />
                <PopclipsCarousel2kBanner
                    modalOpen={showPopclipsCarousel2kBanner}
                    toggleHideModal={() =>
                        setShowPopclipsCarousel2kBanner(false)
                    }
                />
            </BlockStack>
        </BaseLayout>
    );
};

export default PopClipsGridPage;
