import { PropsWithChildren } from 'react';
import { Modal } from '@shopify/polaris';
import type { ModalSize } from '@shopify/polaris/build/ts/src/components/Modal';

type AlertModalProps = {
    title?: string;
    positiveBtnLabel: string;
    positiveAction?: () => void;
    negativeBtnLabel: string;
    negativeAction?: () => void;
    shouldShowModal: boolean;
    closeModal: () => void;
    hasDeletebutton?: boolean;
    size?: ModalSize;
    isLoading?: boolean;
};

export const AlertModal = ({
    title,
    positiveBtnLabel,
    positiveAction,
    negativeBtnLabel,
    negativeAction,
    shouldShowModal = false,
    closeModal,
    hasDeletebutton = false,
    isLoading = false,
    size,
    children,
}: PropsWithChildren<AlertModalProps>) => {
    const handleActionClicked = (isPositive: boolean) => {
        if (isPositive) {
            if (typeof positiveAction == 'function') {
                positiveAction();
                return;
            }
        } else {
            if (typeof negativeAction == 'function') {
                negativeAction();
                return;
            }
        }

        closeModal();
    };

    return (
        <Modal
            loading={isLoading}
            size={size}
            open={shouldShowModal}
            onClose={closeModal}
            title={title}
            titleHidden={!title || title.length == 0}
            primaryAction={{
                disabled: isLoading,
                destructive: hasDeletebutton,
                content: positiveBtnLabel,
                onAction: () => handleActionClicked(true),
            }}
            secondaryActions={[
                {
                    disabled: isLoading,
                    content: negativeBtnLabel,
                    onAction: () => handleActionClicked(false),
                },
            ]}
        >
            <Modal.Section>{children}</Modal.Section>
        </Modal>
    );
};
