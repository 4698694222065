import { BlockStack, SkeletonBodyText } from '@shopify/polaris';
import { CardWithTitleSection } from '../common';
import { useSnapshot } from 'valtio';
import { mobileAppConfigStore } from '@/stores';
import AppImagePicker from '../common/AppImagePicker';

type HeaderLogoCardProps = {
    isAppRequestSubmitted?: boolean;
}
const HeaderLogoCard = (props:  HeaderLogoCardProps) => {
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    return (
        <CardWithTitleSection
            title="Header Logo"
            subtitle="Make sure your brand is correct by uploading or editing your logo below."
        >
            {mobileAppConfigSnap.isLoading ? (
                <SkeletonBodyText />
            ) : (
                <BlockStack>
                    <AppImagePicker
                        labelStr=""
                        imageUrl={mobileAppConfigSnap.headerLogoUrl}
                        setImageInStore={(url: string) => {
                            mobileAppConfigStore.headerLogoUrl = url;
                        }}
                        removeImageInStore={(url: string) => {
                            mobileAppConfigStore.headerLogoUrl = url;
                        }}
                        hintText="Accepts .gif, .jpg, and .png. For best results use width of 500px."
                        isAppRequestSubmitted={props.isAppRequestSubmitted}
                    />
                </BlockStack>
            )}
        </CardWithTitleSection>
    );
};

export default HeaderLogoCard;
