import PlanCartComponent from "@/components/plans/PlanCartComponent";
import VfsModal from "../VfsModal";
import { useSnapshot } from "valtio";
import { billingStore } from "@/stores";

type PlanConfirmationModalProps = {
    onConfirmCheckout: () => void;
    hideModal: () => void;
};

export default function PlanConfirmationModal({
    onConfirmCheckout,
    hideModal,
}: PlanConfirmationModalProps) {
    const { billingSubscription } = useSnapshot(billingStore);

    return (
        <VfsModal
            primaryAction={onConfirmCheckout}
            primaryActionText="Checkout"
            title="Plan Summary"
            modalWidth="30rem"
            btnFullWidth
            onCloseModal={hideModal}
        >
            <PlanCartComponent shopSubscriptionDraft={billingSubscription} />
        </VfsModal>
    );
}