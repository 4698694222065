import { BlockStack, Divider, InlineStack, Spinner } from "@shopify/polaris";
import { useEffect, useState } from "react";
import '../../assets/css/AppLoader.scss'
import { vfsAppLoader } from "../../assets/images";

const LoaderSlides = [
    {
        highlight: 'New',
        title: 'Meet the New Custom Carousel',
        subtitle:
            'Select Popclips to create custom video carousels that can be effortlessly embedded anywhere on your website.',
        image: undefined,
        video: vfsAppLoader,
    },
];

export default function AppLoaderPage() {
    const [currentSlide, setCurrentSlide] = useState(0);
    
    useEffect(() => {
        if (LoaderSlides.length > 1) {
            const interval = setInterval(() => {
                setCurrentSlide((currentSlide) => {
                    return currentSlide === LoaderSlides.length - 1
                        ? 0
                        : currentSlide + 1;
                });
            }, 3000);

            return () => clearInterval(interval);
        }
    }, []);
    
    return (
        <div className="app-loading-container">
            <div className="app-loading__card">
                <BlockStack gap="600">
                    <div className="app-loading__card-head">
                        <InlineStack
                            align="center"
                            blockAlign="center"
                            gap="400"
                        >
                            <p className="card-loader-text">
                                Hold tight! Your Videeo experience is loading...
                            </p>
                        </InlineStack>
                    </div>
                    <Divider />
                    <div className="app-loading__card-body">
                        <BlockStack
                            gap="400"
                            align="center"
                            inlineAlign="center"
                        >
                            <Spinner size="large" />
                            {LoaderSlides[currentSlide].image && (
                                <img
                                    src={LoaderSlides[currentSlide].image}
                                    alt="illustration"
                                    style={{
                                        width: '180px',
                                        height: '141px',
                                        marginInline: 'auto',
                                    }}
                                />
                            )}
                            {LoaderSlides[currentSlide].video && (
                                <video autoPlay muted loop width="370">
                                    <source src={LoaderSlides[currentSlide].video} type="video/webm" />
                                </video>
                            )}
                            <p className="card-title">
                                {LoaderSlides[currentSlide].title}
                            </p>
                            <p className="card-subtitle">
                                {LoaderSlides[currentSlide].subtitle}
                            </p>
                        </BlockStack>
                    </div>
                    {LoaderSlides.length > 1 && (
                        <div className="app-loading__card-footer">
                            {LoaderSlides.map((_, index) => (
                                <div
                                    key={index}
                                    className={`app-loader-card__dot ${
                                        currentSlide === index ? 'active' : ''
                                    }`}
                                />
                            ))}
                        </div>
                    )}
                </BlockStack>
                <p className="app-loader-card__watermark">Videeo</p>
            </div>
        </div>
    );
};