import CryptoJS from 'crypto-js';
// Ref: https://www.npmjs.com/package/instagram-url-direct

function encryptUrl(input: string) {
    const encrypted = CryptoJS.AES.encrypt(input, CryptoJS.enc.Utf8.parse('qwertyuioplkjhgf'), {
        iv: CryptoJS.lib.WordArray.random(16),
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
}

export const importInstagramReel = async (url_media: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch('https://backend.instavideosave.com/allinone', {
                method: 'GET',
                headers: { url: encryptUrl(url_media) }
            });

            const data = await response.json();
            if (!data) reject({ results_number: 0, url_list: [] });

            let url_list: string[] = [];

            if (data.video) {
                data.video.forEach((infovideo) => {
                    infovideo.video
                        ? url_list.push(infovideo.video)
                        : url_list.push(infovideo.thumbnail);
                });
            }

            if (data.image) {
                data.image.forEach((image) => {
                    url_list.push(image);
                });
            }

            resolve({ results_number: url_list.length, url_list });
        } catch (err) {
            reject(err);
        }
    });
};

export const generateFileFromInstagramUrl = async (url: string) => {
    const videoUrl = new URL(url);
    const videoFileNameArr = videoUrl.pathname.split('/');

    const videoRes = await fetch(url);
    const videoBlobFile = await videoRes.blob();
    
    let videoFileName = `${Math.random().toString(36).substring(2,8+2)}_${Date.now()}.${videoBlobFile.type.split('/')[1]}`;
    if (videoFileNameArr.length > 0) {
        videoFileName = videoFileNameArr.pop() || videoFileName;
    }

    return new File([videoBlobFile], videoFileName, {
        type: videoBlobFile.type,
    });
}
