import { Text, Banner, Box } from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import { buildMobileAppStore } from '@/stores';

export const AppProgressCard = () => {
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, { sync: true });
    const { isAppRequestSubmitted } = buildMobileAppSnapshot;

    return (
        isAppRequestSubmitted && (
            <Banner title="App Approval Pending">
                <Box paddingInline="500">
                    <Text as="p">
                        Your app will be ready within 2-3 weeks. Don't worry, while your app status is being updated you can still browse other tabs and manage your settings to configure your app without the wait!
                    </Text>
                </Box>
            </Banner>
        )
    );
};
