import { useCallback } from 'react';
import { useVFSAuthFetch } from '@/hooks/useAuthenticatedFetch';
import {
    buildMobileAppStore,
    mobileAppConfigStore,
    defaultMobileAppConfigStore,
    shopCollectionStore,
} from '@/stores';

export const useGetAppConfig = () => {
    const fetch = useVFSAuthFetch();
    const getAppConfig = useCallback(async () => {
        try {
            const response = await fetch<{
                shopifyDomain: string;
                csShopId: string;
                storefrontAccessToken: string;
                primaryColor: string;
                bannerColor: string;
                featuredShopifyCollectionGid: string;
                secondaryColor: string;
                font: string;
                bannerTitle: string;
                bannerText: string;
                headerLogoUrl: string;
                liveBannerTitle: string;
                liveBannerText: string;
                liveBannerColor: string;
                menuHandle: string;
                shopMenuHandle: string;
                customLinksHandle: string;
                showInventoryLimit: number;
            }>('app-config');
            buildMobileAppStore.featuredShopifyCollectionGid =
                response.data?.featuredShopifyCollectionGid || '';
            mobileAppConfigStore.primaryColor =
                response.data?.primaryColor || '#000000';
            mobileAppConfigStore.secondaryColor =
                response.data?.secondaryColor || '#000000';
            mobileAppConfigStore.bannerColor =
                response.data?.bannerColor || '#000000';
            mobileAppConfigStore.shopifyCollectionGid =
                response.data?.featuredShopifyCollectionGid || '';
            mobileAppConfigStore.customFont = response.data?.font || '';
            mobileAppConfigStore.bannerTitle = response.data?.bannerTitle || '';
            mobileAppConfigStore.bannerText = response.data?.bannerText || '';
            mobileAppConfigStore.headerLogoUrl =
                response.data?.headerLogoUrl || '';
            mobileAppConfigStore.liveBannerTitle =
                response.data?.liveBannerTitle || '';
            mobileAppConfigStore.liveBannerText =
                response.data?.liveBannerText || '';
            mobileAppConfigStore.liveBannerColor =
                response.data?.liveBannerColor || '#000000';
            mobileAppConfigStore.menuHandle = response.data?.menuHandle || '',
            mobileAppConfigStore.shopMenuHandle = response.data?.shopMenuHandle || '',
            mobileAppConfigStore.customLinksHandle = response.data?.customLinksHandle || '',
            mobileAppConfigStore.showInventoryLimit =
                response.data?.showInventoryLimit ||
                response.data?.showInventoryLimit == 0
                    ? response.data?.showInventoryLimit
                    : 10;
            defaultMobileAppConfigStore.primaryColor =
                response.data?.primaryColor || '#000000';
            defaultMobileAppConfigStore.secondaryColor =
                response.data?.secondaryColor || '#000000';
            defaultMobileAppConfigStore.bannerColor =
                response.data?.bannerColor || '#000000';
            defaultMobileAppConfigStore.shopifyCollectionGid =
                response.data?.featuredShopifyCollectionGid || '';
            defaultMobileAppConfigStore.customFont = response.data?.font || '';
            defaultMobileAppConfigStore.bannerTitle =
                response.data?.bannerTitle || '';
            defaultMobileAppConfigStore.bannerText =
                response.data?.bannerText || '';
            defaultMobileAppConfigStore.headerLogoUrl =
                response.data?.headerLogoUrl || '';
            defaultMobileAppConfigStore.liveBannerTitle =
                response.data?.liveBannerTitle || '';
            defaultMobileAppConfigStore.liveBannerText =
                response.data?.liveBannerText || '';
            defaultMobileAppConfigStore.liveBannerColor =
                response.data?.liveBannerColor || '#000000';
            defaultMobileAppConfigStore.menuHandle =
                response.data?.menuHandle || '';
            defaultMobileAppConfigStore.shopMenuHandle =
                response.data?.shopMenuHandle || '';
            defaultMobileAppConfigStore.customLinksHandle =
                response.data?.customLinksHandle || '';
            shopCollectionStore.selectedCollection =
                response.data?.featuredShopifyCollectionGid || '';
            defaultMobileAppConfigStore.showInventoryLimit =
                response.data?.showInventoryLimit ||
                response.data?.showInventoryLimit == 0
                    ? response.data?.showInventoryLimit
                    : 10;
        } catch (e) {
            console.error(e);
        } finally {
            buildMobileAppStore.isAppConfigLoading = false;
            mobileAppConfigStore.isLoading = false;
        }
    }, [fetch]);

    return { getAppConfig };
};
