import { PropsWithChildren } from 'react';
import {
    BlockStack,
    Box,
    Text,
    InlineStack,
    ColorPicker,
    Bleed,
    TextField,
    hsbToHex,
    hexToRgb,
    rgbToHsb,
    Button,
} from '@shopify/polaris';

type AppColorPickerProps = {
    label: string;
    hexColor: string;
    onHexColorChange: (value: string) => void;
    validationError: string | boolean;
    required?: boolean;
    previewButton?: boolean;
    onPreviewButtonClick?: () => void;
};

const AppColorPicker = ({
    label,
    hexColor,
    onHexColorChange,
    validationError,
    required,
    previewButton,
    onPreviewButtonClick,
}: PropsWithChildren<AppColorPickerProps>) => (
    <BlockStack gap="400">
        <Box paddingBlockStart="400">
            <Text variant="bodyMd" as="p">
                {label}
                {required && (
                    <Text as="span" tone="critical">
                        *
                    </Text>
                )}
            </Text>
        </Box>
        <Bleed marginBlockStart="200">
            <Box
                borderColor="border"
                borderWidth="025"
                padding="400"
                borderRadius="300"
            >
                <InlineStack align="space-between">
                    <InlineStack gap="600" wrap={false}>
                        <ColorPicker
                            onChange={(value) =>
                                onHexColorChange(hsbToHex(value))
                            }
                            color={rgbToHsb(hexToRgb(hexColor))}
                        />

                        <BlockStack align="center">
                            <TextField
                                label="Color Hex"
                                value={hexColor}
                                onChange={(value) => onHexColorChange(value)}
                                autoComplete="off"
                                error={validationError}
                                prefix={
                                    <div
                                        style={{
                                            background: hexColor,
                                            width: '16px',
                                            height: '16px',
                                            borderRadius: '3px',
                                            border: '1px solid #ccc',
                                        }}
                                    />
                                }
                            />
                        </BlockStack>
                    </InlineStack>

                    {previewButton && (
                        <BlockStack align="center">
                            <Button variant="primary" onClick={onPreviewButtonClick}>Preview</Button>
                        </BlockStack>
                    )}
                </InlineStack>
            </Box>
        </Bleed>
    </BlockStack>
);

export default AppColorPicker;
