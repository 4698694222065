import {
    BlockStack,
    Box,
    Grid,
    InlineStack,
} from '@shopify/polaris';
import DashboardTotalSalesChart from './DashboardTotalSalesChart';
import DashboardSummaryChart from './DashboardSummaryChart';
import DashboardTotalCliksChart from './DashboardTotalCliksChart';
import DashboardTotalOrdersChart from './DashboardTotalOrdersChart';
import DashboardTotalViewsChart from './DashboardTotalViewsChart';
import { useEffect, useState } from 'react';
import DashboardLoader from './DashboardLoader';
import DashboardPopclipStatsTable from './DashboardPopclipStatsTable';
import DashboardCarouselStatsTable from './DashboardCarouselStatsTable';
import { useSnapshot } from 'valtio';
import { shopStore } from '@/stores';
import { PopclipsSummaryTypes } from './dashboardTypes';

const namingObject = {
    total_add_to_carts: 'Total Add to Carts',
    total_clicks: 'Total Clicks',
    total_orders: 'Total Orders',
    total_popclips_uploaded: 'Total Popclips Uploaded',
    total_sales_direct: 'Total Sales (Direct)',
    total_sales_indirect: 'Total Sales (Indirect)',
    total_sales: 'Total Sales',
    total_views: 'Total Views',
    popclip_id: 'Popclip ID',
    popclip_title: 'Popclips Title',
    created_at: 'Created At',
    sales_influenced_amount: 'Sales Influenced Amount',
    sales_indirect_amount: 'Sales Indirect Amount',
    shop_id: 'Shop ID',
    sales_direct_amount: 'Sales Direct Amount',
    carousel_id: "Carousel ID",
};

const DashboardHome = () => {
    const [dashboardData, setDashboardData] =
        useState<PopclipsSummaryTypes | null>(null);
    const [isDashboardLoading, setIsDashboardLoading] = useState(true);
    const [weeklyChartData, setWeeklyChartData] = useState(null);
    const [isWeeklyDataLoading, setIsWeeklyDataLoading] = useState(false);
    const [monthlyChartData, setMonthlyChartData] = useState(null);
    const [isMonthlyDataLoading, setIsMonthlyDataLoading] = useState(false);

    const { shopifyDomain, popclipsServiceApi } = useSnapshot(shopStore);

    const fetchChartWeeklyData = async () => {
        try {
            setIsWeeklyDataLoading(true);
            const resp = await fetch(
                `${popclipsServiceApi}/dashboard/popclips-weekly?shop_id=mainstream-applevalley.myshopify.com`,
                {
                    method: 'GET',
                },
            );
            const { data } = await resp.json();

            // Optimized processing
            const result = (() => {
                const labels: string[] = [];
                const totalClicks: number[] = [];
                const totalViews: number[] = [];
                const totalOrders: number[] = [];
                const totalSales: number[] = [];

                data?.items
                    .sort((a, b) => a.week_no - b.week_no)
                    .forEach((item) => {
                        labels.push(item.week_no);
                        totalClicks.push(item.total_clicks);
                        totalViews.push(item.total_views);
                        totalOrders.push(item.total_orders);
                        totalSales.push(item.total_sales);
                    });

                return {
                    labels,
                    totalClicks,
                    totalViews,
                    totalOrders,
                    totalSales,
                };
            })();
            setWeeklyChartData({ ...data, ...result });
            setIsWeeklyDataLoading(false);
        } catch (error) {
            console.log(error);
            setIsWeeklyDataLoading(false);
        }
    };

    const fetchChartMonthlyData = async () => {
        try {
            setIsMonthlyDataLoading(true);
            const resp = await fetch(
                `${popclipsServiceApi}/dashboard/popclips-monthly?shop_id=mainstream-applevalley.myshopify.com`,
                {
                    method: 'GET',
                },
            );
            const { data } = await resp.json();

            // Optimized processing
            const result = (() => {
                const labels: string[] = [];
                const totalClicks: number[] = [];
                const totalViews: number[] = [];
                const totalOrders: number[] = [];
                const totalSales: number[] = [];
                const salesDirectAmount: number[] = [];
                const salesIndirectAmount: number[] = [];
                const salesInfluencedAmount: number[] = [];

                // Sort by month_no in ascending order
                data?.items
                    .sort((a, b) => a.month_no - b.month_no)
                    .forEach(
                        ({
                            month_and_year,
                            total_clicks,
                            total_views,
                            total_orders,
                            total_sales,
                            sales_direct_amount,
                            sales_indirect_amount,
                            sales_influenced_amount,
                        }) => {
                            labels.push(month_and_year);
                            totalClicks.push(total_clicks);
                            totalViews.push(total_views);
                            totalOrders.push(total_orders);
                            totalSales.push(total_sales);
                            salesDirectAmount.push(sales_direct_amount);
                            salesIndirectAmount.push(sales_indirect_amount);
                            salesInfluencedAmount.push(sales_influenced_amount);
                        },
                    );

                return {
                    labels,
                    totalClicks,
                    totalViews,
                    totalOrders,
                    totalSales,
                    salesDirectAmount,
                    salesIndirectAmount,
                    salesInfluencedAmount,
                };
            })();

            setMonthlyChartData({ ...data, ...result });
            setIsMonthlyDataLoading(false);
        } catch (error) {
            console.log(error);
            setIsMonthlyDataLoading(false);
        }
    };

    const fetchDashboardData = async () => {
        try {
            setIsDashboardLoading(true);
            const resp = await fetch(
                `${popclipsServiceApi}/dashboard/popclips-summary?shop_id=mainstream-applevalley.myshopify.com`,
                {
                    method: 'GET',
                },
            );
            const { data } = await resp.json();

            setDashboardData(data);
            setIsDashboardLoading(false);
        } catch (error) {
            console.log(error);
            setIsDashboardLoading(false);
        }
    };

    useEffect(() => {
        if (popclipsServiceApi) {
            fetchChartMonthlyData();
            fetchChartWeeklyData();
            fetchDashboardData();
        }
    }, []);

    return (
        <div style={{ fontFamily: 'Plus Jakarta Sans' }}>
            <Box>
                {isDashboardLoading ? (
                    <DashboardLoader />
                ) : (
                    <BlockStack gap="400">
                        <InlineStack gap="400" wrap={false}>
                            <DashboardSummaryChart data={dashboardData} />
                            <DashboardTotalSalesChart
                                title={namingObject.total_sales}
                                directSaleValue={
                                    dashboardData?.sales_direct_amount || 0
                                }
                                indirectSaleValue={
                                    dashboardData?.sales_indirect_amount || 0
                                }
                                influenced={
                                    dashboardData?.sales_influenced_amount || 0
                                }
                                totalSales={monthlyChartData?.totalSales || []}
                                salesDirectAmount={
                                    monthlyChartData?.salesDirectAmount || []
                                }
                                salesIndirectAmount={
                                    monthlyChartData?.salesIndirectAmount || []
                                }
                                salesInfluencedAmount={
                                    monthlyChartData?.salesInfluencedAmount ||
                                    []
                                }
                                labels={monthlyChartData?.labels || []}
                                isLoading={isMonthlyDataLoading}
                                growthInValue={
                                    monthlyChartData?.salesPercentage || 0
                                }
                            />
                        </InlineStack>
                        <InlineStack gap="400" wrap={false}>
                            <Box width="50%">
                                <BlockStack gap="400">
                                    <DashboardTotalCliksChart
                                        title={namingObject.total_clicks}
                                        value={dashboardData?.total_clicks || 0}
                                        chartData={
                                            weeklyChartData?.totalClicks || []
                                        }
                                        labels={weeklyChartData?.labels || []}
                                        isLoading={isWeeklyDataLoading}
                                        growthInValue={
                                            weeklyChartData?.clicksPercentage ||
                                            0
                                        }
                                    />
                                    <DashboardTotalOrdersChart
                                        title={namingObject.total_orders}
                                        value={dashboardData?.total_orders || 0}
                                        chartData={
                                            weeklyChartData?.totalOrders || []
                                        }
                                        labels={weeklyChartData?.labels || []}
                                        isLoading={isWeeklyDataLoading}
                                        growthInValue={
                                            weeklyChartData?.ordersPercentage ||
                                            0
                                        }
                                    />
                                </BlockStack>
                            </Box>
                            <Box width="50%">
                                <DashboardTotalViewsChart
                                    title={namingObject.total_views}
                                    value={dashboardData?.total_views || 0}
                                    chartData={
                                        weeklyChartData?.totalViews || []
                                    }
                                    labels={weeklyChartData?.labels || []}
                                    isLoading={isWeeklyDataLoading}
                                    growthInValue={
                                        weeklyChartData?.viewsPercentage || 0
                                    }
                                />
                            </Box>
                        </InlineStack>
                        <Grid>
                            <Grid.Cell
                                columnSpan={{
                                    xs: 3,
                                    sm: 3,
                                    md: 3,
                                    lg: 6,
                                    xl: 6,
                                }}
                            >
                                <DashboardPopclipStatsTable
                                    namingObject={namingObject}
                                />
                            </Grid.Cell>

                            <Grid.Cell
                                columnSpan={{
                                    xs: 3,
                                    sm: 3,
                                    md: 3,
                                    lg: 6,
                                    xl: 6,
                                }}
                            >
                                
                                <DashboardCarouselStatsTable
                                    namingObject={namingObject}
                                />
                            </Grid.Cell>
                        </Grid>
                    </BlockStack>
                )}
            </Box>
        </div>
    );
};

export default DashboardHome;
