import { ATTRIBUTE_IDS } from "@/constants/planFeatureCard.constants";
import VfsModal from "../VfsModal";
import { billingStore } from "@/stores";
import LivePlanAddonCard from "../PlanSelection/LivePlanAddonCard";
import { BlockStack, Box, Icon, InlineStack, Text } from "@shopify/polaris";
import { CheckIcon } from "@shopify/polaris-icons";

type SelectPlanState = {
    planId: string;
    type: string;
    skuCode: string;
} | undefined;

type LiveSellingPlanModalProps = {
    selectedPlan: SelectPlanState;
    onHidePlanModal: (planData: SelectPlanState) => void;
};

const featureType = 'live';

export default function LiveSellingPlanModal({
    onHidePlanModal,
    selectedPlan,
}: LiveSellingPlanModalProps) {
    const addons = billingStore.getAddonsByModule(featureType, ATTRIBUTE_IDS[featureType]);
    const addonsPlansValues = billingStore.getAddonButtonGroupValues(addons, ATTRIBUTE_IDS[featureType]);
    
    const selectAddon = (addon: any) => {
        if (selectedPlan?.type === addon.name.toLowerCase()) {
            onHidePlanModal(undefined);
        } else {
            onHidePlanModal({
                planId: addon.id,
                type: addon.name.toLowerCase(),
                skuCode: addon.skuCode,
            });
        }
    };
    
    return (
        <VfsModal
            onCloseModal={() => onHidePlanModal(selectedPlan)}
            title="Shoppable Livestream Plans"
            modalWidth="53rem"
        >
            <BlockStack gap="600">
                <Box
                    borderRadius="500"
                    background="bg-fill-active"
                    padding="500"
                >
                    <Box paddingBlockEnd="300">
                        <Text
                            as="p"
                            fontWeight="bold"
                            variant="bodyMd"
                            tone="subdued"
                        >
                            Highlights:
                        </Text>
                    </Box>
                    <InlineStack
                        gap="500"
                        align="space-between"
                        blockAlign="center"
                        wrap={false}
                    >
                        <BlockStack gap="200">
                            <InlineStack
                                gap="200"
                                wrap={false}
                                align="start"
                                blockAlign="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon
                                        source={CheckIcon}
                                        tone="emphasis"
                                    />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    Invite Guest On-Camera Seamlessly
                                </Text>
                            </InlineStack>
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon
                                        source={CheckIcon}
                                        tone="emphasis"
                                    />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    Go live on your Shopify website, app
                                    and social media
                                </Text>
                            </InlineStack>
                        </BlockStack>
                        <BlockStack gap="200">
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon
                                        source={CheckIcon}
                                        tone="emphasis"
                                    />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    Recorded replays of your live shows
                                    (available for 3 months)
                                </Text>
                            </InlineStack>
                            <InlineStack
                                gap="200"
                                wrap={false}
                                blockAlign="start"
                                align="start"
                            >
                                <div style={{ width: '20px' }}>
                                    <Icon
                                        source={CheckIcon}
                                        tone="emphasis"
                                    />
                                </div>
                                <Text as="p" variant="bodyLg">
                                    Live scheduler & live calendar on
                                    website
                                </Text>
                            </InlineStack>
                        </BlockStack>
                    </InlineStack>
                </Box>
                <InlineStack gap="400">
                    {addons.map((addon, i) => (
                        <div className="flex-1 card-highlight" key={i}>
                            <LivePlanAddonCard
                                addonData={addon}
                                addonValue={addonsPlansValues[i].value}
                                isSelected={selectedPlan?.type === addon.name.toLowerCase()}
                                isDisabled={false}
                                onSelectPlan={() => selectAddon(addon)}
                            />
                        </div>
                    ))}
                </InlineStack>
            </BlockStack>
        </VfsModal>
    );
}