import { Box, InlineGrid, InlineStack, SkeletonBodyText } from '@shopify/polaris';
import { CardWithTitleSection } from '../common';
import { useSnapshot } from 'valtio';
import { mobileAppConfigStore } from '@/stores';
import { AppColorPicker } from '../common';
import { Modal, TitleBar } from '@shopify/app-bridge-react';
import MobileHomePage from './mobile-preview-screens/MobileHomePage';
import { useState } from 'react';
import MobilePDPPage from './mobile-preview-screens/MobilePDPPage';

const ColorsCard = () => {
    const [openModal, setOpenModal] = useState(false);
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    return (
        <CardWithTitleSection
            title="Colors"
            subtitle="Take control of your app with these customizable features."
            showBtn
            btnLabel="Preview"
            btnAction={() => setOpenModal(true)}
        >
            {mobileAppConfigSnap.isLoading ? (
                <SkeletonBodyText />
            ) : (
                <Box>
                    <InlineGrid columns={2} gap="400">
                        <Box>
                            <AppColorPicker
                                required={true}
                                label="Primary Color"
                                hexColor={mobileAppConfigSnap.primaryColor}
                                onHexColorChange={(val: string) => {
                                    mobileAppConfigStore.primaryColor = val;
                                }}
                                validationError={
                                    !mobileAppConfigSnap.isPrimaryColorHexValid &&
                                    mobileAppConfigSnap.showFormErrorLabel
                                        ? 'Required field'
                                        : ''
                                }
                            />
                        </Box>
                        <Box>
                            <AppColorPicker
                                required={true}
                                label="Secondary Color"
                                hexColor={mobileAppConfigSnap.secondaryColor}
                                onHexColorChange={(val: string) => {
                                    mobileAppConfigStore.secondaryColor = val;
                                }}
                                validationError={
                                    !mobileAppConfigSnap.isSeconadaryColorHexValid &&
                                    mobileAppConfigSnap.showFormErrorLabel
                                        ? 'Required field'
                                        : ''
                                }
                            />
                        </Box>
                    </InlineGrid>
                    <Modal
                        open={openModal}
                        variant="large"
                        onHide={() => setOpenModal(false)}
                    >
                        <TitleBar title="Mobile App Color Preview">
                            <button
                                variant="primary"
                                onClick={() => setOpenModal(false)}
                            >
                                Close
                            </button>
                        </TitleBar>
                        <Box padding="400">
                            <InlineStack align="center" blockAlign="center" gap="600">
                                <MobileHomePage isLiveBanner />
                                <MobilePDPPage />
                            </InlineStack>
                        </Box>
                    </Modal>
                </Box>
            )}
        </CardWithTitleSection>
    );
};

export default ColorsCard;
